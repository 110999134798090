.load-more-reports {
  text-align: center;
  margin-top: $grid-gutter-width-half;
}

.content-list + .text-center {
  margin-top: $grid-gutter-width;
}

#reports-map {
  @include clearfix;
  display: flex;
  flex-wrap: wrap;
  .reports, .map {
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: calc(100vh - #{$sticky-menu-height});
  }
  .reports {
    padding: 75px;
    order: 1;
    form {
      margin: $grid-gutter-width-half 0 ($grid-gutter-width + $grid-gutter-width-half) 0;

      @media only screen and (min-width: $screen-md-max + 1) and (max-width: 1650px), only screen and (min-width: 700px) and (max-width: 860px) {
        margin-bottom: $grid-gutter-width-half;
        .radio-group {
          &.inline {
            .radio-wrapper {
              label {
                @include font-size(13px);
                padding-left: 30px;
              }
              .check {
                width: 20px;
                height: 20px;
                border-width: 3px;
                top: 7px;
                &::before {
                  height: 10px;
                  width: 10px;
                  left: 2px;
                  top: 2px;
                }
              }
            }
          }
        }
      }

      @media only screen and (min-width: $screen-md-max + 1) and (max-width: 1280px), only screen and (max-width: 700px) {
        .radio-group {
          &.inline {
            .radio-wrapper {
              float: none;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1800px) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
    @media only screen and (max-width: 1600px) {
      padding-left: $grid-gutter-width-half;
      padding-right: $grid-gutter-width-half;
    }
  }
  .map {
    order: 2;
    position: relative;
    .google-map {
      height: calc(100vh - #{$sticky-menu-height});
      &.sticky {
        top: $sticky-menu-height;
        right: 0;
        width: 50%;
        position: fixed !important;
        &.bottom {
          position: absolute !important;
          right: 0;
          bottom: 0;
          top: auto;
          width: 100%;
        }
      }
    }
  }
  @include bp-max-md {
    .reports, .map {
      display: block;
      width: 100%;
      min-height: 0;
    }
    .reports {
      order: 2;
      padding: 0 $grid-gutter-width-half;
      margin: $grid-gutter-width 0;
    }
    .map {
      order: 1;
      .google-map {
        min-height: 75vh;
        max-height: 500px;
        &.sticky, &.sticky.bottom {
          position: relative !important;
          width: 100% !important;
          top: auto !important;
          bottom: auto !important;
          left: auto !important;
          right: auto !important;
        }
      }
    }
  }
}

.report-detail-modal {
  .social-share {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;
    @include font-size(24px);
    color: map-get($colors, 'button') !important;
    a {
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
    @include bp-max-xs {
      @include font-size(28px);
      right: auto;
      left: 0;
      width: 100%;
      text-align: center;
      a {
        margin-right: 5px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  > .body {
    padding-bottom: $grid-gutter-width;
    > div {
      margin-top: $grid-gutter-width-half;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      .btn {
        flex-grow: 1;
      }
    }
    .info {
      @include clearfix;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
      .prefix {
        font-weight: bold;
      }
      ul {
        float: left;
        @include clearfix;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          float: left;
          border-right: 1px solid darken(map-get($colors, 'odd-background'), 15);
          padding: 10px $grid-gutter-width-half 10px 0;
          margin-left: $grid-gutter-width-half;
          font-weight: 600;
          @include font-size(12px);
          i {
            @include font-size(18px);
            margin-right: 10px;
          }
          &:last-of-type {
            border-right: 0;
            padding-right: 0;
          }
        }
      }
    }
    .slick-slider {
      height: 400px;
      .slick-slide {
        a {
          display: block;
        }
        img {
          height: 400px;
          width: 100%;
          max-width: 100%;
          object-fit: cover;
        }
      }
    }

    .downloads {
      margin: $grid-gutter-width-half 0 $grid-gutter-width 0;
      h4 {
        @include font-size(20px);
      }
      ul {
        margin: 5px 0 0 0;
        li {
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .comments {
      padding-left: 50px;
      .message {
        padding-left: 50px + $grid-gutter-width-half;
      }
    }
    .message,
    .comments {
      .inner,
      .comment {
        margin-bottom: $grid-gutter-width;
        .header {
          display: flex;
          align-items: center;
          .image {
            img {
              width: 50px;
              height: 50px;
              max-height: 50px;
              max-width: 50px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
          .info {
            margin-left: $grid-gutter-width-half;
            font-weight: bold;
            time {
              color: map-get($colors, 'gray');
              margin-left: 5px;
            }
          }
        }
        .message {
          margin-top: 10px;
        }
      }
    }
  }
  @each $key, $value in $states {
    &.state-#{$key} {
      > .header {
        background-color: $value !important;
      }
    }
  }
  @include bp-max-xs {
    .slick-slider {
      height: 250px !important;
      .slick-slide {
        img {
          height: 250px !important;
        }
      }
    }
    > .body {
      .info {
        .left {
          float: none;
        }
        .right {
          float: none;
          margin-top: $grid-gutter-width-half;
          ul {
            li {
              &:first-of-type {
                margin-left: 0;
              }
            }
          }
        }
      }
      .buttons {
        display: block;
        .btn {
          display: block;
        }
      }
      .comments {
        padding-left: $grid-gutter-width;
        .message {
          padding-left: 0;
        }
      }
      .message,
      .comments {
        .inner,
        .comment {
          .header {
            .image {
              img {
                width: 40px;
                height: 40px;
                max-height: 40px;
                max-width: 40px;
              }
            }
            .info {
              time {
                margin-left: 0;
                display: block;
                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 360px) {
    > .body {
      .comments {
        padding-left: 0;
      }
    }
  }
}

html.no-flexbox,
html.no-flexwrap {
  #reports-map {
    @include clearfix;
    display: table;
    width: 100%;
    .reports, .map {
      display: table-cell;
      vertical-align: top;
    }
    .map {
      .google-map {
        &.sticky {
          left: 0;
          &.bottom {
            left: 0;
          }
        }
      }
    }
    @include bp-max-md {
      .reports, .map {
        display: block;
      }
      .map {
        .google-map {
          &.sticky, &.sticky.bottom {
            left: auto !important;
          }
        }
      }
    }
  }
}
