.google-map {
  width: 100%;
  height: 200px;
  min-height: 200px;
  max-height: 100vh;
  position: relative;
  &:not(.loaded) {
    &::before {
      content: 'Loading Google Maps';
      opacity: 0.35;
      color: map-get-deep($texts, 'font', 'default', 'color');
      font-size: 2rem;
      text-align: center;
      width: 100%;
      display: block;
      position: relative;
      top: $grid-gutter-width;
      right: 0;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid map-get-deep($texts, 'font', 'default', 'color');
      opacity: 0.1;
    }
  }
}
