@import "../components/select2";

.intro {
  .intro-text {
    font-weight: 600;
  }
}

.content-filters {
  margin: $grid-gutter-width-half 0 ($grid-gutter-width + $grid-gutter-width-half) 0;
  .radio-wrapper label {
    @include font-size(14px);
  }
  @media only screen and (min-width: $screen-md-max + 1) and (max-width: 1650px), only screen and (min-width: 700px) and (max-width: 860px) {
    margin-bottom: $grid-gutter-width-half;
    .radio-group {
      &.inline {
        .radio-wrapper {
          label {
            @include font-size(13px);
            padding-left: 30px;
          }
          .check {
            width: 20px;
            height: 20px;
            border-width: 3px;
            top: 7px;
            &::before {
              height: 10px;
              width: 10px;
              left: 2px;
              top: 2px;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: $screen-md-max + 1) and (max-width: 1280px), only screen and (max-width: 700px) {
    .radio-group {
      &.inline {
        .radio-wrapper {
          float: none;
        }
      }
    }
  }
}

.content-search {
  margin: $grid-gutter-width-half 0 ($grid-gutter-width + $grid-gutter-width-half) 0;
  @include clearfix;
  position: relative;
  .form-group {
    margin-bottom: 0;
  }
  .form-group.input {
    margin-right: 10px;
    input {
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0  !important;
      border-right: none !important;
      height: 54px;
    }
  }

  .form-group.button {
    position: absolute;
    top: 0;
    right: 0;
    button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      height: 54px;
      line-height: 54px;
      padding: 0;
      margin: 0;
      @include font-size(34px);
      width: 54px;
      text-align: center;
    }
  }
}

.content-filters + .content-search {
  margin-top: -($grid-gutter-width) !important;
}

.image-upload-container {
  .images-preview {
    .file-preview-thumbnails {
      @include clearfix;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
    }
    .file-preview-frame {
      margin-top: $grid-gutter-width-half;
      .kv-file-content {
        max-width: 150px;
        max-height: 100px;
        position: relative;
        border: 1px solid map-get($colors, 'gray');
        padding: 3px;
      }
    }
    .kv-file-content {
      img {
        max-width: 142px !important;
        max-height: 92px !important;
      }
    }
    .file-thumbnail-footer {
      display: none;
    }
    @include bp-max-xs {
      .file-preview-thumbnails {
        display: block;
      }
      .file-preview-frame {
        text-align: center;
        margin-bottom: $grid-gutter-width-half;
        .kv-file-content {
          display: inline-block;
        }
      }
    }
  }
  .kv-fileinput-error {
    button {
      display: none;
    }
    margin-top: $grid-gutter-width-half;
    text-align: center;
    color: map-get($colors, 'error');
  }
  .fake-file-upload {
    position: relative;
    @include clearfix();
    .fake-text {
      background: none;
      height: 54px;
      line-height: 54px;
      padding: 0 $grid-gutter-width-half;
      width: 100%;
      border: 2px solid map-get($colors, 'primary');
      border-radius: 10px;
    }
    .fake-button {
      margin: 0;
      z-index: 1;
      height: 54px;
      line-height: 54px;
      padding: 0 $grid-gutter-width-half 0 40px + $grid-gutter-width-half;
      position: absolute;
      top: 0;
      right: 0;
      border: 2px solid map-get($colors, 'primary');
      background-color: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      font-weight: 600;
      transition: all map-get-deep($animations, 'speed', 'normal') linear;
      i {
        z-index: 2;
        @include font-size(30px);
        position: absolute;
        top: 50%;
        left: $grid-gutter-width-half;
        margin-top: -15px;
      }
      &:hover {
        background-color: transparent;
        color: map-get($colors, 'primary') !important;
        cursor: pointer;
      }
    }
    input[type=file] {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      height: 54px;
      width: 100%;
      cursor: pointer;
      opacity: 0;
      &:hover {
        cursor: pointer;
      }
    }

    @media only screen and (max-width: 460px) {
      .fake-text {
        display: none;
      }
      .fake-button {
        position: static;
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

.upload-copyright-info {
  text-align: center;
  max-width: 400px;
  margin: $grid-gutter-width-half auto;
  @include font-size(14px);
}

.content-title {
  h2 {

  }
  .info,
  .sub {
    @include font-size(16px);
    font-weight: 600;
  }
  .links-states {
    @include clearfix;
    margin: 50px 0;
    .links {
      float: left;
    }
    .states {
      margin-top: 15px;
      float: right;
      ul {
        @include clearfix;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          float: left;
          margin-right: $grid-gutter-width-half + 5px;
          font-weight: bold;
          @include font-size(13px);
          display: flex;
          align-items: center;
          i {
            margin-right: 8px;
            @include font-size(20px);
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    @include bp-max-md {
      .links, .states {
        width: 100%;
      }
      .states {
        ul {
          li {
            float: none;
            width: 100%;
            margin-bottom: $grid-gutter-width-half;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    @include bp-max-xs {
      .links {
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.image-modal {
  width: auto;
  max-width: 90%;
  .close-modal {
    display: none;
  }
  > .header {
    background-color: map-get($colors, 'primary');
    min-height: 50px;
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      text-overflow: ellipsis;
    }
  }
  > .body {
    text-align: center;
    padding: 0;
    position: relative;
    min-height: 100px;
    min-width: 250px;
    &::before {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      display: block;
      content: 'Bild wird geladen';
      padding: $grid-gutter-width-half $grid-gutter-width-half 0 $grid-gutter-width-half;
      @include font-size(20px);
    }
    img {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      width: auto;
      height: auto;
      max-height: 100vh;
    }
  }
  @include bp-max-xs {
    max-width: 95%;
    > .body {
      padding: 0;
    }
  }
}

.comments-alternate {
  .inner,
  .comment {
    margin-bottom: $grid-gutter-width;
    .header {
      display: flex;
      align-items: center;
      .image {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .info {
        margin-left: $grid-gutter-width-half;
        font-weight: bold;
        time {
          color: map-get($colors, 'gray');
          margin-left: 5px;
        }
      }
    }
    .message {
      margin-top: 10px;
    }
  }
  @include bp-max-xs {
    .inner,
    .comment {
      .header {
        .image {
          img {
            width: 40px;
            height: 40px;
          }
        }
        .info {
          time {
            margin-left: 0;
            display: block;
            &::before {
              content: none;
            }
          }
        }
      }
    }
  }
}
