/* Icons Fallback */
@each $key, $data in $icons {
  /* Icon Fallback: #{$key} */
  #{map-get($data, 'selector')} {
    font-style: inherit;
    font-weight: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    color: inherit;
    visibility: hidden;
  }
}

/* Icons */
@each $key, $data in $icons {
  /* Icon: #{$key} */
  html {
    #{stringify-array(map-get($data, 'wf'), '&.', '-' + map-get($wf, 'active'), ', ')} {
      #{map-get($data, 'selector')} {
        visibility: inherit;
      }
    }
  }
}
