.mind-maker-detail-container {
  @extend .idea-scout-detail-container;
  .mind-maker-detail {
    @extend .idea-scout-detail;
  }
  .comments-container {
    margin-top: -($grid-gutter-width-half * 3);
  }
}

.mind-maker-result {
  @include bp-min-sm {
    margin-top: -$grid-gutter-width;
  }
  .result-title,
  .result-entry {
    margin-left: -($grid-gutter-width + $grid-gutter-width-half);
    margin-right: -($grid-gutter-width + $grid-gutter-width-half);
    padding: $grid-gutter-width ($grid-gutter-width + $grid-gutter-width-half);
    border-bottom: 2px solid map-get($colors, 'primary');
    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
    .result-entry-title {
      margin-bottom: $grid-gutter-width;
    }
    .result-option-title {
      font-weight: 600;
      margin-bottom: $grid-gutter-width-half;
      text-align: center;
    }
    .chart {
      margin-bottom: $grid-gutter-width + $grid-gutter-width-half;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .result-title {
    padding-top: 0;
    @include font-size(22px);
  }
  .chart {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: $grid-gutter-width-half;
    }
    .header {
      @include clearfix();
      margin-bottom: 5px;
      font-weight: bold;
      position: relative;
      .left {
        float: left;
      }
      .right {
        float: right;
      }
      @include bp-max-sm {
        .left, .right {
          float: none;
        }
        .right {
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 7px;
          color: map-get($colors, 'white');
          text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        }
      }
    }
    .progress-bar {
      background-color: darken(map-get($colors, 'odd-background'), 10);
      margin-bottom: $grid-gutter-width-half;
      height: 25px;
      .progress {
        min-width: 20px !important;
        height: inherit;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        background-color: map-get($colors, 'primary');
      }
    }
  }
}

.poll {
  .poll-title {
    border-bottom: 2px solid map-get($colors, 'white');
    margin: 0 (-($grid-gutter-width + $grid-gutter-width-half));
    padding: 0 ($grid-gutter-width + $grid-gutter-width-half) ($grid-gutter-width + $grid-gutter-width-half) ($grid-gutter-width + $grid-gutter-width-half);
    @include font-size(22px);
  }
  .poll-entry-list {
    margin-top: $grid-gutter-width;
    .poll-entry {
      margin: 0 (-($grid-gutter-width + $grid-gutter-width-half)) $grid-gutter-width (-($grid-gutter-width + $grid-gutter-width-half));
      padding: $grid-gutter-width-half ($grid-gutter-width + $grid-gutter-width-half);
      border-bottom: 2px solid map-get($colors, 'white');

      .poll-option {
        .checkbox-wrapper {
          margin-bottom: 0;
        }
      }

      &.poll-type-single_choice {
        .poll-option {
          margin-bottom: $grid-gutter-width-half;
          .poll-option-text {
            font-weight: 600;
            margin-bottom: $grid-gutter-width-half;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      &.poll-type-multiple_choice {
        .poll-option {
          margin-bottom: $grid-gutter-width-half;
        }
        .form-group {
          margin: $grid-gutter-width 0 $grid-gutter-width-half 0 !important;
        }
      }
      &.poll-type-contrastive_pairs {
        .radio-group {
          list-style: none;
          padding: 0;
          margin: 0;
          label {
            padding: 0;
            @extend .sr-only;
          }
        }
        .radio-group {
          .radio-wrapper {
            min-height: 40px;
          }
        }
        @include bp-max-sm {
          label.sr-only {
            position: relative;
            width: auto;
            height: auto;
            margin: 0;
            padding: 7px 5px 7px 50px;
            overflow: visible;
            clip: initial;
            border: 0;
          }
          li:first-of-type {
            @include clearfix;
            span {
              display: inline-block !important;
            }
          }
          .poll-option {
            margin-bottom: $grid-gutter-width;
            .radio-group {
              li {
                width: 100%;
                margin-bottom: $grid-gutter-width-half;
                &:first-of-type {
                  font-weight: 600;
                  padding-bottom: $grid-gutter-width-half;
                  border-bottom: 1px solid map-get($colors, 'white');
                }
                &:last-of-type {
                  display: none;
                }
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        @include bp-max-md {
          .radio-group {
            li {
              width: 75px;
            }
          }
        }
        @include bp-min-md {
          .radio-group {
            li {
              display: table-cell;
              vertical-align: middle;
              height: 40px + $grid-gutter-width;
              width: 80px;
              text-align: center;
              &:first-of-type {
                width: 250px;
                text-align: left;
              }
              &:last-of-type {
                width: 250px;
                text-align: right;
              }
            }
          }
          .poll-option {
            border-bottom: 1px solid map-get($colors, 'white');
            &:last-of-type {
              border-bottom: none;
            }
            ul li {
              &:first-of-type {
                padding: 10px 10px 10px 0;
                border-right: 1px solid map-get($colors, 'white');
              }
              &:last-child {
                padding: 10px 0 10px 10px;
                border-left: 1px solid map-get($colors, 'white');
              }
            }
            &:last-of-type {
              ul li:first-of-type {
                border-bottom: none;
              }
            }
          }
          .radio-group {
            .radio-wrapper {
              width: 40px;
              height: 40px;
              margin: 0 auto;
            }
          }
        }
      }
      &.poll-type-satisfaction_scale {
        .radio-group,
        .poll-option-headers {
          list-style: none;
          padding: 0;
          margin: 0;
          label {
            padding: 0;
          }
        }
        .radio-group {
          .radio-wrapper {
            min-height: 40px;
          }
        }
        @include bp-max-md {
          .poll-option-headers {
            @include font-size(15px);
          }
          .radio-group,
          .poll-option-headers {
            li {
              width: 125px;
              &:first-of-type {
                width: 225px;
              }
            }
          }
        }
        @include bp-max-sm {
          .poll-option-headers {
            display: none;
          }
          label.sr-only {
            position: relative;
            width: auto;
            height: auto;
            margin: 0;
            padding: 7px 5px 7px 50px;
            overflow: visible;
            clip: initial;
            border: 0;
          }
          .poll-option {
            margin-bottom: $grid-gutter-width;
            .radio-group {
              li {
                width: 100%;
                margin-bottom: $grid-gutter-width-half;
                &:first-of-type {
                  font-weight: 600;
                  padding-bottom: $grid-gutter-width-half;
                  border-bottom: 1px solid map-get($colors, 'white');
                }
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        @include bp-min-md {
          .radio-group,
          .poll-option-headers {
            li {
              display: table-cell;
              vertical-align: middle;
              height: 40px + $grid-gutter-width;
              width: 150px;
              text-align: center;
              &:first-of-type {
                width: 300px;
                text-align: left;
                border-right: 1px solid map-get($colors, 'white');
              }
            }
          }
          .poll-option {
            ul li:first-of-type {
              border-bottom: 1px solid map-get($colors, 'white');
              padding: 10px 10px 10px 0;
            }
            &:last-of-type {
              ul li:first-of-type {
                border-bottom: none;
              }
            }
          }
          .poll-option-headers li {
            border-bottom: 1px solid map-get($colors, 'white');
          }
          .radio-group {
            .radio-wrapper {
              width: 40px;
              height: 40px;
              margin: 0 auto;
            }
          }
        }
      }

      .poll-entry-title {
        margin-bottom: $grid-gutter-width;
      }
      .poll-option-list {
        .poll-option {

        }
      }
    }
  }
}




















