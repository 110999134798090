/* Text Fallbacks */
$tmpTextsFallbacks: map-get($texts-fallbacks, 'font');
@each $key, $data in $tmpTextsFallbacks {
  $tmpSelector: 'html, html body';
  @if (map-has-key($data, 'selector')) {
    $tmp: map-get($data, 'selector');
    @if ($tmp != '*') {
      $tmpSelector: $tmp;
    }
  }
  /* Text Fallback: #{$key} */
  .text-#{$key}, #{$tmpSelector} {
    font-family: map-get($data, 'family');
    font-style: map-get($data, 'style');
    font-weight: map-get($data, 'weight');
    font-size: map-get($data, 'size');
    text-transform: map-get($data, 'transform');
    letter-spacing: map-get($data, 'letter-spacing');
    line-height: map-get($data, 'line-height');
    color: map-get($data, 'color');
  }
}

/* Text */
$tmpTexts: map-get($texts, 'font');
@each $key, $data in $tmpTexts {
  $tmpSelector: 'body';
  @if (map-has-key($data, 'selector')) {
    $tmp: map-get($data, 'selector');
    @if ($tmp != '*') {
      $tmpSelector: $tmp;
    }
  }
  /* Text: #{$key} */
  html {
    #{stringify-array(map-get($data, 'wf'), '&.', '-' + map-get($wf, 'active'), ', ')} {
      .text-#{$key}, #{$tmpSelector} {
        font-family: map-get($data, 'family');
        font-style: map-get($data, 'style');
        font-weight: map-get($data, 'weight');
        font-size: map-get($data, 'size');
        text-transform: map-get($data, 'transform');
        letter-spacing: map-get($data, 'letter-spacing');
        line-height: map-get($data, 'line-height');
        color: map-get($data, 'color');
      }
    }
  }
}

$tmpHeadingLevels: ('h1', 'h2', 'h3', 'h4', 'h5', 'h6');
@for $h from 1 through 6 {
  $tmpHeadingLevels: append($tmpHeadingLevels, '.title-' + nth($tmpHeadingLevels, $h));
}

/* Headings Fallbacks */
$tmpHeadingsFallbacks: map-get($headings-fallbacks, 'font');
@each $key, $data in $tmpHeadingsFallbacks {
  $tmpSelector: 'html, html body';
  @if (map-has-key($data, 'selector')) {
    $tmp: map-get($data, 'selector');
    @if ($tmp != '*') {
      $tmpSelector: $tmp;
    }
  }
  /* Heading Fallback: #{$key} */
  #{stringify-array($tmpHeadingLevels, '', '', ', ')} {
    font-family: map-get($data, 'family');
    font-style: map-get($data, 'style');
    font-weight: map-get($data, 'weight');
    text-transform: map-get($data, 'transform');
    letter-spacing: map-get($data, 'letter-spacing');
    line-height: map-get($data, 'line-height');
    color: map-get($data, 'color');
    margin: 0;
    padding: 0;
    max-width: 100%;
    @include bp-max-xs {
      @include text-wrap();
      > br {
        @include replace-with-space();
      }
    }
  }
  @for $h from 1 through 6 {
    .title-h#{$h}, h#{$h} {
      $baseHeaderSize: map-get-deep($data, 'sizes', 'h#{$h}');
      $responsiveStepSize: map-get-deep($data, 'sizes', 'responsive-step');
      @include font-size($baseHeaderSize);
      @if strip-units($responsiveStepSize) > 0 {
        @include bp-max-md {
          @include font-size($baseHeaderSize - $responsiveStepSize * 1);
        }
        @include bp-max-sm {
          @include font-size($baseHeaderSize - $responsiveStepSize * 2);
        }
        @include bp-max-xs {
          @include font-size($baseHeaderSize - $responsiveStepSize * 3);
        }
      }
    }
  }
}

/* Headings */
$tmpHeadings: map-get($headings, 'font');
@each $key, $data in $tmpHeadings {
  $tmpSelector: 'body';
  @if (map-has-key($data, 'selector')) {
    $tmp: map-get($data, 'selector');
    @if ($tmp != '*') {
      $tmpSelector: $tmp;
    }
  }
  /* Heading: #{$key} */
  html {
    #{stringify-array(map-get($data, 'wf'), '&.', '-' + map-get($wf, 'active'), ', ')} {
      #{$tmpSelector} {
        #{stringify-array($tmpHeadingLevels, '', '', ', ')} {
          font-family: map-get($data, 'family');
          font-style: map-get($data, 'style');
          font-weight: map-get($data, 'weight');
          text-transform: map-get($data, 'transform');
          letter-spacing: map-get($data, 'letter-spacing');
          line-height: map-get($data, 'line-height');
          color: map-get($data, 'color');
          margin: 0;
          padding: 0;
          max-width: 100%;
          @include bp-max-xs {
            @include text-wrap();
            > br {
              @include replace-with-space();
            }
          }
        }
        @for $h from 1 through 6 {
          .title-h#{$h}, h#{$h} {
            $baseHeaderSize: map-get-deep($data, 'sizes', 'h#{$h}');
            $responsiveStepSize: map-get-deep($data, 'sizes', 'responsive-step');
            @include font-size($baseHeaderSize);
            @if strip-units($responsiveStepSize) > 0 {
              @include bp-max-md {
                @include font-size($baseHeaderSize - $responsiveStepSize * 1);
              }
              @include bp-max-sm {
                @include font-size($baseHeaderSize - $responsiveStepSize * 2);
              }
              @include bp-max-xs {
                @include font-size($baseHeaderSize - $responsiveStepSize * 3);
              }
            }
          }
        }
      }
    }
  }
}
