$hamburger-width: 50px !default;
$hamburger-height: 5px !default;
$hamburger-spacing: 5px !default;
$hamburger-color: get-color('white') !default;
$hamburger-speed: map-get-deep($animations, 'speed', 'slow') !default;

.hamburger-menu {
  width: $hamburger-width;
  height: $hamburger-height + $hamburger-spacing * 2 * 2;
  cursor: pointer;
  display: block;
  .bar {
    position: relative;
    transform: translateY($hamburger-spacing * 2);
    background: rgba($hamburger-color, 1);
    transition: all 0ms $hamburger-speed;
    width: $hamburger-width;
    height: $hamburger-height;
    &::before,
    &::after {
      width: $hamburger-width;
      height: $hamburger-height;
      content: '';
      position: absolute;
      left: 0;
      background: rgba($hamburger-color, 1);
    }
    &::before {
      bottom: $hamburger-spacing * 2;
      transition: bottom $hamburger-speed $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1),
      transform $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1);
    }
    &::after {
      top: $hamburger-spacing * 2;
      transition: top $hamburger-speed $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1),
      transform $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1);
      width: $hamburger-width * 0.75;
    }
  }
  &.active {
    .bar {
      background: rgba($hamburger-color, 0);
      &::before {
        bottom: 0;
        transform: rotate(-45deg);
        transition: bottom $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1),
        transform $hamburger-speed $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1);
      }
      &::after {
        top: 0;
        transform: rotate(45deg);
        transition: top $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1),
        transform $hamburger-speed $hamburger-speed cubic-bezier(0.23, 1, 0.32, 1);
        width: $hamburger-width;
      }
    }
  }
}
