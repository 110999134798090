@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: $value;
  }
  .color-#{$key} {
    color: $value;
  }
}

@each $key, $value in $states {
  .bg-state-#{$key} {
    background-color: $value;
  }
  .color-state-#{$key} {
    color: $value;
  }
}
