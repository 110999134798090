$ENV: 'development' !default;

$animations: (
  'speed': (
    'slow': 250ms,
    'normal': 120ms,
    'fast': 50ms,
  )
);

$colors: (
  /* Theme colors */
  'primary': #37474F,
  'secondary': #FBC02D,
  /* Form colors */
  'success': #558B2F,
  'error': #af0000,
  /* Other colors */
  'gray': #C6CAD2,
  'odd-background': #EDEEF1,
  'black': #000000,
  'white': #FFFFFF,
  'button': #37474F,
);

$states: (
  'placeholder': #DDDDDD,

  'new': #558B2F,
  'process': #FBC02D,
  'finished': #000,
  'deleted': #AF0000,
  'review': #989898,

  // Meinung
  'expired': darken(map-get($colors, 'odd-background'), 10),

  // Idee
  'failed': #AF0000,
  'success': #558B2F,
);

$wf: (
  'loading': 'loading',
  'active': 'active',
  'inactive': 'inactive',
);

$icons: (
  'materialdesigniconicfont': (
    'selector': '.zmdi',
    'wf': (
      'wf-materialdesigniconicfont-n4',
    ),
  )
);

$headings: (
  'font': (
    'default': (
      'selector': '*',
      'family': 'Khand',
      'style': normal,
      'weight': 600,
      'transform': none,
      'line-height': 1.4,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
      'wf': (
        'wf-khand-n6',
      ),
      'sizes': (
        'h1': 42px,
        'h2': 35px,
        'h3': 20px,
        'h4': 18px,
        'h5': 16px,
        'h6': 14px,
        'responsive-step': 1px,
      ),
    ),
    'content': (
      'selector': '#topic',
      'family': 'Khand',
      'style': normal,
      'weight': 600,
      'transform': none,
      'line-height': 1.4,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
      'wf': (
        'wf-khand-n6',
      ),
      'sizes': (
        'h1': 42px,
        'h2': 35px,
        'h3': 32px,
        'h4': 26px,
        'h5': 22px,
        'h6': 18px,
        'responsive-step': 1px,
      ),
    ),
  ),
);

$headings-fallbacks: (
  'font': (
    'default': (
      'selector': '*',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': 600,
      'transform': none,
      'line-height': 1.4,
      'letter-spacing': -2px,
      'color': map-get($colors, 'black'),
      'sizes': (
        'h1': 40px,
        'h2': 33px,
        'h3': 20px,
        'h4': 18px,
        'h5': 16px,
        'h6': 14px,
        'responsive-step': 1px,
      ),
    ),
    'content': (
      'selector': '#topic',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': 600,
      'transform': none,
      'line-height': 1.4,
      'letter-spacing': -2px,
      'color': map-get($colors, 'black'),
      'sizes': (
        'h1': 40px,
        'h2': 33px,
        'h3': 32px,
        'h4': 26px,
        'h5': 22px,
        'h6': 18px,
        'responsive-step': 1px,
      ),
    ),
  ),
);

$texts: (
  'font': (
    'default': (
      'selector': '*',
      'family': 'Open Sans',
      'style': normal,
      'weight': normal,
      'size': 16px,
      'transform': none,
      'line-height': 1.4,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
      'wf': (
        'wf-opensans-n4',
      )
    ),
    'button': (
      'selector': '.btn, #cookie-consent button',
      'family': 'Khand',
      'style': normal,
      'weight': 600,
      'size': 16px,
      'transform': none,
      'line-height': normal,
      'letter-spacing': 0,
      'color': map-get($colors, 'button'),
      'wf': (
        'wf-khand-n6',
      )
    ),
    'button-header': (
      'selector': '#header .btn',
      'family': 'Open Sans',
      'style': normal,
      'weight': bold,
      'size': 16px,
      'transform': uppercase,
      'line-height': normal,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
      'wf': (
        'wf-opensans-n4',
      )
    ),
    'main-menu': (
      'selector': '#main-nav, #main-menu-mobile',
      'family': 'Khand',
      'style': normal,
      'weight': 600,
      'size': 25px,
      'transform': none,
      'line-height': normal,
      'letter-spacing': 0,
      'color': map-get($colors, 'primary'),
      'wf': (
        'wf-khand-n6',
      )
    ),
  ),
);

$texts-fallbacks: (
  'font': (
    'default': (
      'selector': '*',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': normal,
      'size': 15px,
      'transform': none,
      'line-height': 1.3,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
    ),
    'button': (
      'selector': '.btn, #cookie-consent button',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': 600,
      'size': 15px,
      'transform': none,
      'line-height': 1.6,
      'letter-spacing': -1px,
      'color': map-get($colors, 'button')
    ),
    'button-header': (
      'selector': '#header .btn',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': bold,
      'size': 17px,
      'transform': uppercase,
      'line-height': 1.4,
      'letter-spacing': 0,
      'color': map-get($colors, 'black'),
    ),
    'main-menu': (
      'selector': '#main-nav, #main-menu-mobile',
      'family': #{Helvetica, '"Trebuchet MS"', Geneva, Arial, Verdana, Tahoma, sans-serif},
      'style': normal,
      'weight': 600,
      'size': 24px,
      'transform': none,
      'line-height': normal,
      'letter-spacing': -1px,
      'color': map-get($colors, 'primary')
    ),
  ),
);

$header-md-height: 400px;
$sticky-menu-height: 55px;

// For mixin font-size
$base-font-size: map-get-deep($texts, 'font', 'default', 'size');
