@charset "UTF-8";
/*
  Bootstrap Variables
*/
/*
  Slick Carousel
 */
@import-normalize;
/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: #000 !important;
  }
  .label {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #fff;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.428571429;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.sr-only, .screen-reader-text, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group label {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .screen-reader-text:active, .sr-only-focusable:focus, .screen-reader-text:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role="button"] {
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h1 small,
h1 .small, h2 small,
h2 .small, h3 small,
h3 .small, h4 small,
h4 .small, h5 small,
h5 .small, h6 small,
h6 .small,
.h1 small,
.h1 .small, .h2 small,
.h2 .small, .h3 small,
.h3 .small, .h4 small,
.h4 .small, .h5 small,
.h5 .small, .h6 small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #777777;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 small,
h1 .small, .h1 small,
.h1 .small,
h2 small,
h2 .small, .h2 small,
.h2 .small,
h3 small,
h3 .small, .h3 small,
.h3 .small {
  font-size: 65%;
}

h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 small,
h4 .small, .h4 small,
.h4 .small,
h5 small,
h5 .small, .h5 small,
.h5 .small,
h6 small,
h6 .small, .h6 small,
.h6 .small {
  font-size: 75%;
}

h1, .h1 {
  font-size: 36px;
}

h2, .h2 {
  font-size: 30px;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 18px;
}

h5, .h5 {
  font-size: 14px;
}

h6, .h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

small,
.small {
  font-size: 85%;
}

mark,
.mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase, .initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777777;
}

.text-primary {
  color: #337ab7;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #286090;
}

.text-success {
  color: #3c763d;
}

a.text-success:hover,
a.text-success:focus {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:hover,
a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #843534;
}

.bg-primary {
  color: #fff;
}

.bg-primary {
  background-color: #337ab7;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #286090;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dt,
dd {
  line-height: 1.428571429;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #777777;
}

.initialism {
  font-size: 90%;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee;
}

blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}

blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.428571429;
  color: #777777;
}

blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right;
}

.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
  content: '';
}

.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.428571429;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container:before, .container:after {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.3333333333%;
}

.col-xs-2 {
  width: 16.6666666667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.3333333333%;
}

.col-xs-5 {
  width: 41.6666666667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.3333333333%;
}

.col-xs-8 {
  width: 66.6666666667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.3333333333%;
}

.col-xs-11 {
  width: 91.6666666667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.3333333333%;
}

.col-xs-pull-2 {
  right: 16.6666666667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.3333333333%;
}

.col-xs-pull-5 {
  right: 41.6666666667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.3333333333%;
}

.col-xs-pull-8 {
  right: 66.6666666667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.3333333333%;
}

.col-xs-pull-11 {
  right: 91.6666666667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.3333333333%;
}

.col-xs-push-2 {
  left: 16.6666666667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.3333333333%;
}

.col-xs-push-5 {
  left: 41.6666666667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.3333333333%;
}

.col-xs-push-8 {
  left: 66.6666666667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.3333333333%;
}

.col-xs-push-11 {
  left: 91.6666666667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-2 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-5 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-8 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-11 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.3333333333%;
  }
  .col-sm-pull-2 {
    right: 16.6666666667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.3333333333%;
  }
  .col-sm-pull-5 {
    right: 41.6666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.3333333333%;
  }
  .col-sm-pull-8 {
    right: 66.6666666667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.3333333333%;
  }
  .col-sm-pull-11 {
    right: 91.6666666667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.3333333333%;
  }
  .col-sm-push-2 {
    left: 16.6666666667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.3333333333%;
  }
  .col-sm-push-5 {
    left: 41.6666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.3333333333%;
  }
  .col-sm-push-8 {
    left: 66.6666666667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.3333333333%;
  }
  .col-sm-push-11 {
    left: 91.6666666667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.3333333333%;
  }
  .col-md-pull-2 {
    right: 16.6666666667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.3333333333%;
  }
  .col-md-pull-5 {
    right: 41.6666666667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.3333333333%;
  }
  .col-md-pull-8 {
    right: 66.6666666667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.3333333333%;
  }
  .col-md-pull-11 {
    right: 91.6666666667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.3333333333%;
  }
  .col-md-push-2 {
    left: 16.6666666667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.3333333333%;
  }
  .col-md-push-5 {
    left: 41.6666666667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.3333333333%;
  }
  .col-md-push-8 {
    left: 66.6666666667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.3333333333%;
  }
  .col-md-push-11 {
    left: 91.6666666667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.3333333333%;
  }
  .col-lg-2 {
    width: 16.6666666667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.3333333333%;
  }
  .col-lg-5 {
    width: 41.6666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.3333333333%;
  }
  .col-lg-8 {
    width: 66.6666666667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.3333333333%;
  }
  .col-lg-11 {
    width: 91.6666666667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.3333333333%;
  }
  .col-lg-pull-2 {
    right: 16.6666666667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.3333333333%;
  }
  .col-lg-pull-5 {
    right: 41.6666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.3333333333%;
  }
  .col-lg-pull-8 {
    right: 66.6666666667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.3333333333%;
  }
  .col-lg-pull-11 {
    right: 91.6666666667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.3333333333%;
  }
  .col-lg-push-2 {
    left: 16.6666666667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.3333333333%;
  }
  .col-lg-push-5 {
    left: 41.6666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.3333333333%;
  }
  .col-lg-push-8 {
    left: 66.6666666667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.3333333333%;
  }
  .col-lg-push-11 {
    left: 91.6666666667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
}

.form-control {
  display: block;
  width: 100%;
  height: 55px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control[disabled], .form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 55px;
  }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm
  input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm
  input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm
  input[type="month"] {
    line-height: 30px;
  }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg
  input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg
  input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg
  input[type="month"] {
    line-height: 46px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radio label,
.checkbox label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled,
fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled,
fieldset[disabled]
input[type="checkbox"] {
  cursor: not-allowed;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled]
.checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled]
.checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 34px;
}

.form-control-static.input-lg, .form-control-static.input-sm {
  padding-left: 0;
  padding-right: 0;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 68.75px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback, .has-feedback label.screen-reader-text ~ .form-control-feedback, .has-feedback .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group label ~ .form-control-feedback, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group .has-feedback label ~ .form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modal {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 2;
  max-width: 500px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 15px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}

.modal a.close-modal {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==");
}

.modal-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modal-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modal-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modal-spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.modal-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.5);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.5);
    -webkit-transform: scaleY(0.5);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.container-fluid.no-padding,
.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.container-fluid.no-padding > .row,
.container.no-padding > .row {
  margin-left: 0;
  margin-right: 0;
}

.container-fluid.full-height,
.container.full-height {
  height: 100%;
}

.container-fluid.full-height div.row,
.container-fluid.full-height [class^="col-"],
.container-fluid.full-height [class*=" col-"],
.container.full-height div.row,
.container.full-height [class^="col-"],
.container.full-height [class*=" col-"] {
  height: 100%;
}

[class^="col-"].no-padding,
[class*=" col-"].no-padding {
  padding-left: 0;
  padding-right: 0;
}

.row.no-margin {
  margin-left: 0;
  margin-right: 0;
}

.no-spacing .row {
  margin-left: 0;
  margin-right: 0;
}

.no-spacing [class^="col-"],
.no-spacing [class*=" col-"] {
  padding-left: 0;
  padding-right: 0;
}

.flex {
  display: flex;
}

.vertical-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.center-align {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.center-align > [class^="col-"],
.center-align > [class*=" col-"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.row.equal-height > [class^="col-"],
.row.equal-height > [class*=" col-"] {
  display: flex;
  flex-direction: column;
}

.row.half-gutter {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.row.half-gutter > [class^="col-"],
.row.half-gutter > [class*=" col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.full-width {
  width: 100%;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.equal-height > [class^="col-"],
.equal-height > [class*=" col-"] {
  display: flex;
  flex-direction: column;
}

.no-break {
  white-space: nowrap;
}

::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

@-ms-viewport {
  width: auto;
}

img:not([src]) {
  visibility: hidden;
}

::selection {
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg {
  fill: currentColor;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  overflow: auto;
  resize: vertical;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[aria-disabled] {
  cursor: default;
}

[draggable] {
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.sr-only-important {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  border: 0 !important;
}

html, body {
  height: 100%;
  margin: 0;
  font-size: 16px;
}

/* Force vertical scrollbar to prevents jumps between pages */
html {
  margin-bottom: 1px;
}

html.lazyload-available .lazyload:not(.loaded):not(.google-map),
html.lazyload-available .lazyloading:not(.loaded):not(.google-map) {
  opacity: 0;
}

html.lazyload-available .lazyloaded {
  opacity: 1;
  transition: opacity 120ms;
}

.logo svg .logo-background {
  fill: #222c31;
}

.logo svg .logo-foreground {
  fill: #FBC02D;
}

#wrapper {
  background-color: #FFFFFF;
}

::selection {
  background: #cdd1d3;
}

a {
  cursor: pointer;
}

a:not(.no-style) {
  color: #37474F;
  transition: color 50ms linear, background-color 50ms linear, border-color 50ms linear;
}

a:not(.no-style) i {
  transition: none;
}

a:not(.no-style)::selection {
  background: #feefcb;
}

a:not(.no-style):active, a:not(.no-style):focus, a:not(.no-style):hover {
  color: #FBC02D;
}

a:not(.no-style):active::selection, a:not(.no-style):focus::selection, a:not(.no-style):hover::selection {
  background: #cdd1d3;
}

a.no-style {
  color: inherit;
  text-decoration: inherit;
}

.block {
  padding-top: 100px;
  padding-bottom: 100px;
}

.block.medium {
  padding-top: 75px;
  padding-bottom: 75px;
}

.block.smaller {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .block {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .block.medium {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .block.smaller {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .block.medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .block.smaller {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.old-browser,
.no-script {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000000;
  opacity: 0.75;
  z-index: 9999999;
  color: #FFFFFF;
  text-align: center;
  font-size: 1rem;
  padding: 15px;
}

.old-browser b, .old-browser strong,
.no-script b,
.no-script strong {
  font-weight: 600;
}

@media only screen and (max-width: 430px) {
  .old-browser br,
  .no-script br {
    display: none;
  }
}

.container-fluid {
  width: 100%;
}

/* Text Fallbacks */
/* Text Fallback: default */
.text-default, html, html body {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.3;
  color: #000000;
}

/* Text Fallback: button */
.text-button, .btn, #cookie-consent button {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  text-transform: none;
  letter-spacing: -1px;
  line-height: 1.6;
  color: #37474F;
}

/* Text Fallback: button-header */
.text-button-header, #header .btn {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0;
  line-height: 1.4;
  color: #000000;
}

/* Text Fallback: main-menu */
.text-main-menu, #main-nav, #main-menu-mobile {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  text-transform: none;
  letter-spacing: -1px;
  line-height: normal;
  color: #37474F;
}

/* Text */
/* Text: default */
html.wf-opensans-n4-active .text-default, html.wf-opensans-n4-active body {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4;
  color: #000000;
}

/* Text: button */
html.wf-khand-n6-active .text-button, html.wf-khand-n6-active .btn, html.wf-khand-n6-active #cookie-consent button {
  font-family: "Khand";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
  line-height: normal;
  color: #37474F;
}

/* Text: button-header */
html.wf-opensans-n4-active .text-button-header, html.wf-opensans-n4-active #header .btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0;
  line-height: normal;
  color: #000000;
}

/* Text: main-menu */
html.wf-khand-n6-active .text-main-menu, html.wf-khand-n6-active #main-nav, html.wf-khand-n6-active #main-menu-mobile {
  font-family: "Khand";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  text-transform: none;
  letter-spacing: 0;
  line-height: normal;
  color: #37474F;
}

/* Headings Fallbacks */
/* Heading Fallback: default */
h1, h2, h3, h4, h5, h6, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  letter-spacing: -2px;
  line-height: 1.4;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  h1, h2, h3, h4, h5, h6, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  h1 > br, h2 > br, h3 > br, h4 > br, h5 > br, h6 > br, .title-h1 > br, .title-h2 > br, .title-h3 > br, .title-h4 > br, .title-h5 > br, .title-h6 > br {
    content: ' ';
  }
  h1 > br::after, h2 > br::after, h3 > br::after, h4 > br::after, h5 > br::after, h6 > br::after, .title-h1 > br::after, .title-h2 > br::after, .title-h3 > br::after, .title-h4 > br::after, .title-h5 > br::after, .title-h6 > br::after {
    content: ' ';
  }
}

.title-h1, h1 {
  font-size: 40px;
  font-size: 2.5rem;
}

@media only screen and (max-width: 1199px) {
  .title-h1, h1 {
    font-size: 39px;
    font-size: 2.4375rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h1, h1 {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h1, h1 {
    font-size: 37px;
    font-size: 2.3125rem;
  }
}

.title-h2, h2 {
  font-size: 33px;
  font-size: 2.0625rem;
}

@media only screen and (max-width: 1199px) {
  .title-h2, h2 {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h2, h2 {
    font-size: 31px;
    font-size: 1.9375rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h2, h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.title-h3, h3 {
  font-size: 20px;
  font-size: 1.25rem;
}

@media only screen and (max-width: 1199px) {
  .title-h3, h3 {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h3, h3 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h3, h3 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

.title-h4, h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (max-width: 1199px) {
  .title-h4, h4 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h4, h4 {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h4, h4 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.title-h5, h5 {
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (max-width: 1199px) {
  .title-h5, h5 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h5, h5 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h5, h5 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

.title-h6, h6 {
  font-size: 14px;
  font-size: 0.875rem;
}

@media only screen and (max-width: 1199px) {
  .title-h6, h6 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h6, h6 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h6, h6 {
    font-size: 11px;
    font-size: 0.6875rem;
  }
}

/* Heading Fallback: content */
h1, h2, h3, h4, h5, h6, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
  font-family: Helvetica, "Trebuchet MS", Geneva, Arial, Verdana, Tahoma, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  letter-spacing: -2px;
  line-height: 1.4;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  h1, h2, h3, h4, h5, h6, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  h1 > br, h2 > br, h3 > br, h4 > br, h5 > br, h6 > br, .title-h1 > br, .title-h2 > br, .title-h3 > br, .title-h4 > br, .title-h5 > br, .title-h6 > br {
    content: ' ';
  }
  h1 > br::after, h2 > br::after, h3 > br::after, h4 > br::after, h5 > br::after, h6 > br::after, .title-h1 > br::after, .title-h2 > br::after, .title-h3 > br::after, .title-h4 > br::after, .title-h5 > br::after, .title-h6 > br::after {
    content: ' ';
  }
}

.title-h1, h1 {
  font-size: 40px;
  font-size: 2.5rem;
}

@media only screen and (max-width: 1199px) {
  .title-h1, h1 {
    font-size: 39px;
    font-size: 2.4375rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h1, h1 {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h1, h1 {
    font-size: 37px;
    font-size: 2.3125rem;
  }
}

.title-h2, h2 {
  font-size: 33px;
  font-size: 2.0625rem;
}

@media only screen and (max-width: 1199px) {
  .title-h2, h2 {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h2, h2 {
    font-size: 31px;
    font-size: 1.9375rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h2, h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

.title-h3, h3 {
  font-size: 32px;
  font-size: 2rem;
}

@media only screen and (max-width: 1199px) {
  .title-h3, h3 {
    font-size: 31px;
    font-size: 1.9375rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h3, h3 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h3, h3 {
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

.title-h4, h4 {
  font-size: 26px;
  font-size: 1.625rem;
}

@media only screen and (max-width: 1199px) {
  .title-h4, h4 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h4, h4 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h4, h4 {
    font-size: 23px;
    font-size: 1.4375rem;
  }
}

.title-h5, h5 {
  font-size: 22px;
  font-size: 1.375rem;
}

@media only screen and (max-width: 1199px) {
  .title-h5, h5 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h5, h5 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h5, h5 {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}

.title-h6, h6 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (max-width: 1199px) {
  .title-h6, h6 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

@media only screen and (max-width: 991px) {
  .title-h6, h6 {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .title-h6, h6 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

/* Headings */
/* Heading: default */
html.wf-khand-n6-active body h1, html.wf-khand-n6-active body h2, html.wf-khand-n6-active body h3, html.wf-khand-n6-active body h4, html.wf-khand-n6-active body h5, html.wf-khand-n6-active body h6, html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body .title-h6 {
  font-family: "Khand";
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body h1, html.wf-khand-n6-active body h2, html.wf-khand-n6-active body h3, html.wf-khand-n6-active body h4, html.wf-khand-n6-active body h5, html.wf-khand-n6-active body h6, html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body .title-h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  html.wf-khand-n6-active body h1 > br, html.wf-khand-n6-active body h2 > br, html.wf-khand-n6-active body h3 > br, html.wf-khand-n6-active body h4 > br, html.wf-khand-n6-active body h5 > br, html.wf-khand-n6-active body h6 > br, html.wf-khand-n6-active body .title-h1 > br, html.wf-khand-n6-active body .title-h2 > br, html.wf-khand-n6-active body .title-h3 > br, html.wf-khand-n6-active body .title-h4 > br, html.wf-khand-n6-active body .title-h5 > br, html.wf-khand-n6-active body .title-h6 > br {
    content: ' ';
  }
  html.wf-khand-n6-active body h1 > br::after, html.wf-khand-n6-active body h2 > br::after, html.wf-khand-n6-active body h3 > br::after, html.wf-khand-n6-active body h4 > br::after, html.wf-khand-n6-active body h5 > br::after, html.wf-khand-n6-active body h6 > br::after, html.wf-khand-n6-active body .title-h1 > br::after, html.wf-khand-n6-active body .title-h2 > br::after, html.wf-khand-n6-active body .title-h3 > br::after, html.wf-khand-n6-active body .title-h4 > br::after, html.wf-khand-n6-active body .title-h5 > br::after, html.wf-khand-n6-active body .title-h6 > br::after {
    content: ' ';
  }
}

html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body h1 {
  font-size: 42px;
  font-size: 2.625rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body h1 {
    font-size: 41px;
    font-size: 2.5625rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body h1 {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h1, html.wf-khand-n6-active body h1 {
    font-size: 39px;
    font-size: 2.4375rem;
  }
}

html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body h2 {
  font-size: 35px;
  font-size: 2.1875rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body h2 {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body h2 {
    font-size: 33px;
    font-size: 2.0625rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h2, html.wf-khand-n6-active body h2 {
    font-size: 32px;
    font-size: 2rem;
  }
}

html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body h3 {
  font-size: 20px;
  font-size: 1.25rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body h3 {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body h3 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h3, html.wf-khand-n6-active body h3 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body h4 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body h4 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body h4 {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h4, html.wf-khand-n6-active body h4 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body h5 {
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body h5 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body h5 {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h5, html.wf-khand-n6-active body h5 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

html.wf-khand-n6-active body .title-h6, html.wf-khand-n6-active body h6 {
  font-size: 14px;
  font-size: 0.875rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active body .title-h6, html.wf-khand-n6-active body h6 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active body .title-h6, html.wf-khand-n6-active body h6 {
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active body .title-h6, html.wf-khand-n6-active body h6 {
    font-size: 11px;
    font-size: 0.6875rem;
  }
}

/* Heading: content */
html.wf-khand-n6-active #topic h1, html.wf-khand-n6-active #topic h2, html.wf-khand-n6-active #topic h3, html.wf-khand-n6-active #topic h4, html.wf-khand-n6-active #topic h5, html.wf-khand-n6-active #topic h6, html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic .title-h6 {
  font-family: "Khand";
  font-style: normal;
  font-weight: 600;
  text-transform: none;
  letter-spacing: 0;
  line-height: 1.4;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic h1, html.wf-khand-n6-active #topic h2, html.wf-khand-n6-active #topic h3, html.wf-khand-n6-active #topic h4, html.wf-khand-n6-active #topic h5, html.wf-khand-n6-active #topic h6, html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic .title-h6 {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
  html.wf-khand-n6-active #topic h1 > br, html.wf-khand-n6-active #topic h2 > br, html.wf-khand-n6-active #topic h3 > br, html.wf-khand-n6-active #topic h4 > br, html.wf-khand-n6-active #topic h5 > br, html.wf-khand-n6-active #topic h6 > br, html.wf-khand-n6-active #topic .title-h1 > br, html.wf-khand-n6-active #topic .title-h2 > br, html.wf-khand-n6-active #topic .title-h3 > br, html.wf-khand-n6-active #topic .title-h4 > br, html.wf-khand-n6-active #topic .title-h5 > br, html.wf-khand-n6-active #topic .title-h6 > br {
    content: ' ';
  }
  html.wf-khand-n6-active #topic h1 > br::after, html.wf-khand-n6-active #topic h2 > br::after, html.wf-khand-n6-active #topic h3 > br::after, html.wf-khand-n6-active #topic h4 > br::after, html.wf-khand-n6-active #topic h5 > br::after, html.wf-khand-n6-active #topic h6 > br::after, html.wf-khand-n6-active #topic .title-h1 > br::after, html.wf-khand-n6-active #topic .title-h2 > br::after, html.wf-khand-n6-active #topic .title-h3 > br::after, html.wf-khand-n6-active #topic .title-h4 > br::after, html.wf-khand-n6-active #topic .title-h5 > br::after, html.wf-khand-n6-active #topic .title-h6 > br::after {
    content: ' ';
  }
}

html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic h1 {
  font-size: 42px;
  font-size: 2.625rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic h1 {
    font-size: 41px;
    font-size: 2.5625rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic h1 {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h1, html.wf-khand-n6-active #topic h1 {
    font-size: 39px;
    font-size: 2.4375rem;
  }
}

html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic h2 {
  font-size: 35px;
  font-size: 2.1875rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic h2 {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic h2 {
    font-size: 33px;
    font-size: 2.0625rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h2, html.wf-khand-n6-active #topic h2 {
    font-size: 32px;
    font-size: 2rem;
  }
}

html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic h3 {
  font-size: 32px;
  font-size: 2rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic h3 {
    font-size: 31px;
    font-size: 1.9375rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic h3 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h3, html.wf-khand-n6-active #topic h3 {
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic h4 {
  font-size: 26px;
  font-size: 1.625rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic h4 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic h4 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h4, html.wf-khand-n6-active #topic h4 {
    font-size: 23px;
    font-size: 1.4375rem;
  }
}

html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic h5 {
  font-size: 22px;
  font-size: 1.375rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic h5 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic h5 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h5, html.wf-khand-n6-active #topic h5 {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}

html.wf-khand-n6-active #topic .title-h6, html.wf-khand-n6-active #topic h6 {
  font-size: 18px;
  font-size: 1.125rem;
}

@media only screen and (max-width: 1199px) {
  html.wf-khand-n6-active #topic .title-h6, html.wf-khand-n6-active #topic h6 {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}

@media only screen and (max-width: 991px) {
  html.wf-khand-n6-active #topic .title-h6, html.wf-khand-n6-active #topic h6 {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  html.wf-khand-n6-active #topic .title-h6, html.wf-khand-n6-active #topic h6 {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

/* Icons Fallback */
/* Icon Fallback: materialdesigniconicfont */
.zmdi {
  font-style: inherit;
  font-weight: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  color: inherit;
  visibility: hidden;
}

/* Icons */
/* Icon: materialdesigniconicfont */
html.wf-materialdesigniconicfont-n4-active .zmdi {
  visibility: inherit;
}

.bg-primary {
  background-color: #37474F;
}

.color-primary {
  color: #37474F;
}

.bg-secondary {
  background-color: #FBC02D;
}

.color-secondary {
  color: #FBC02D;
}

.bg-success {
  background-color: #558B2F;
}

.color-success {
  color: #558B2F;
}

.bg-error {
  background-color: #af0000;
}

.color-error {
  color: #af0000;
}

.bg-gray {
  background-color: #C6CAD2;
}

.color-gray {
  color: #C6CAD2;
}

.bg-odd-background {
  background-color: #EDEEF1;
}

.color-odd-background {
  color: #EDEEF1;
}

.bg-black {
  background-color: #000000;
}

.color-black {
  color: #000000;
}

.bg-white {
  background-color: #FFFFFF;
}

.color-white {
  color: #FFFFFF;
}

.bg-button {
  background-color: #37474F;
}

.color-button {
  color: #37474F;
}

.bg-state-placeholder {
  background-color: #DDDDDD;
}

.color-state-placeholder {
  color: #DDDDDD;
}

.bg-state-new {
  background-color: #558B2F;
}

.color-state-new {
  color: #558B2F;
}

.bg-state-process {
  background-color: #FBC02D;
}

.color-state-process {
  color: #FBC02D;
}

.bg-state-finished {
  background-color: #000;
}

.color-state-finished {
  color: #000;
}

.bg-state-deleted {
  background-color: #AF0000;
}

.color-state-deleted {
  color: #AF0000;
}

.bg-state-review {
  background-color: #989898;
}

.color-state-review {
  color: #989898;
}

.bg-state-expired {
  background-color: #d0d3db;
}

.color-state-expired {
  color: #d0d3db;
}

.bg-state-failed {
  background-color: #AF0000;
}

.color-state-failed {
  color: #AF0000;
}

.bg-state-success {
  background-color: #558B2F;
}

.color-state-success {
  color: #558B2F;
}

/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: 15px auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: 15px;
  height: auto;
}

@media only screen and (max-width: 991px) {
  .alignleft {
    float: left;
    margin-right: 15px;
  }
  .alignright {
    float: right;
    margin-left: 15px;
  }
}

.wp-caption {
  display: inline-block;
}

.wp-caption img {
  margin-bottom: 15px;
  line-height: 1;
  max-width: 100%;
  height: auto;
}

.google-map {
  width: 100%;
  height: 200px;
  min-height: 200px;
  max-height: 100vh;
  position: relative;
}

.google-map:not(.loaded)::before {
  content: 'Loading Google Maps';
  opacity: 0.35;
  color: #000000;
  font-size: 2rem;
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
  top: 30px;
  right: 0;
}

.google-map:not(.loaded)::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #000000;
  opacity: 0.1;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/slick/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "Material-Design-Iconic-Font";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "Material-Design-Iconic-Font";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-slider.slick-dotted {
  margin: 0;
}

.slick-slider.slick-dotted .slick-dots {
  bottom: 15px;
}

.slick-slider.slick-dotted .slick-dots li, .slick-slider.slick-dotted .slick-dots li button, .slick-slider.slick-dotted .slick-dots li button::before {
  width: 20px;
  height: 20px;
}

.slick-slider.slick-dotted .slick-dots li {
  width: 20px;
  height: 20px;
}

.slick-slider.slick-dotted .slick-dots li button {
  width: inherit;
  height: inherit;
}

.slick-slider.slick-dotted .slick-dots li button::before {
  width: inherit;
  height: inherit;
}

@media only screen and (max-width: 767px) {
  .slick-slider.slick-dotted .slick-dots li:at-least(8) {
    width: 15px;
    height: 15px;
    font-size: 15px;
    line-height: 15px;
  }
}

.slick-slider.slick-dotted .slick-dots li {
  margin-left: 0;
  margin-right: 5px;
  font-size: 14px;
  line-height: 14px;
  transition: all 120ms ease-out;
}

.slick-slider.slick-dotted .slick-dots li:last-of-type {
  margin-right: 0;
}

.slick-slider.slick-dotted .slick-dots li button {
  padding: 0;
  font-size: inherit;
  line-height: inherit;
}

.slick-slider.slick-dotted .slick-dots li button::before {
  line-height: inherit;
  content: '\f26d';
  color: #FFFFFF;
  opacity: 0.5;
  font-size: inherit;
}

.slick-slider.slick-dotted .slick-dots li button:hover::before {
  opacity: 1;
}

.slick-slider.slick-dotted .slick-dots li.slick-active {
  font-size: 20px;
  line-height: 20px;
}

.slick-slider.slick-dotted .slick-dots li.slick-active button::before {
  color: #37474F;
  opacity: 1;
}

.slick-slider .slick-arrow {
  width: 75px;
  height: 75px;
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0);
  transition: all 50ms linear;
}

.slick-slider .slick-arrow::before {
  font-size: 40px;
  opacity: 0.6;
  transition: all 50ms linear;
}

.slick-slider .slick-arrow.slick-prev {
  left: 15px;
}

.slick-slider .slick-arrow.slick-prev::before {
  content: '\f2fa';
  margin-left: -2px;
}

.slick-slider .slick-arrow.slick-next {
  right: 15px;
}

.slick-slider .slick-arrow.slick-next::before {
  content: '\f2fb';
  margin-left: 2px;
}

.slick-slider .slick-arrow:hover {
  background: #FFFFFF;
  border-color: #FFFFFF;
}

.slick-slider .slick-arrow:hover::before {
  color: #000000;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .slick-slider .slick-arrow {
    display: none !important;
  }
}

.slick-slider .slick-slide img {
  user-select: none;
}

.slick-slider .slick-slide a, .slick-slider .slick-slide img {
  -webkit-user-drag: none;
}

html:not(.wf-materialdesigniconicfont-n4-active) .slick-slider.slick-dotted .slick-dots {
  visibility: hidden;
}

html:not(.wf-materialdesigniconicfont-n4-active) .slick-arrow {
  visibility: hidden;
}

.hamburger-menu {
  width: 50px;
  height: 25px;
  cursor: pointer;
  display: block;
}

.hamburger-menu .bar {
  position: relative;
  transform: translateY(10px);
  background: white;
  transition: all 0ms 250ms;
  width: 50px;
  height: 5px;
}

.hamburger-menu .bar::before, .hamburger-menu .bar::after {
  width: 50px;
  height: 5px;
  content: '';
  position: absolute;
  left: 0;
  background: white;
}

.hamburger-menu .bar::before {
  bottom: 10px;
  transition: bottom 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu .bar::after {
  top: 10px;
  transition: top 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms cubic-bezier(0.23, 1, 0.32, 1);
  width: 37.5px;
}

.hamburger-menu.active .bar {
  background: rgba(255, 255, 255, 0);
}

.hamburger-menu.active .bar::before {
  bottom: 0;
  transform: rotate(-45deg);
  transition: bottom 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1);
}

.hamburger-menu.active .bar::after {
  top: 0;
  transform: rotate(45deg);
  transition: top 250ms cubic-bezier(0.23, 1, 0.32, 1), transform 250ms 250ms cubic-bezier(0.23, 1, 0.32, 1);
  width: 50px;
}

.hamburger-menu {
  background-color: #37474F;
}

#main-nav {
  background-color: #C6CAD2;
  margin-top: 2px;
}

#main-nav .hamburger-menu {
  display: none;
}

#main-nav #main-menu {
  max-width: 1920px;
  margin: 0 auto;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

#main-nav #main-menu:before, #main-nav #main-menu:after {
  content: " ";
  display: table;
}

#main-nav #main-menu:after {
  clear: both;
}

#main-nav #main-menu li {
  width: 100%;
  border: 0 solid #FFFFFF;
  border-right-width: 2px;
}

#main-nav #main-menu li:first-of-type {
  border-left-width: 0;
}

#main-nav #main-menu li:last-of-type {
  border-right-width: 0;
}

#main-nav #main-menu li a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  transition: all 120ms linear;
  width: 100%;
  background-color: #C6CAD2;
  color: #37474F;
}

#main-nav #main-menu li a::before {
  content: 'DuBist ';
  white-space: pre;
  color: #FFFFFF;
}

#main-nav #main-menu li a.active, #main-nav #main-menu li a:focus, #main-nav #main-menu li a:active, #main-nav #main-menu li a:hover {
  text-decoration: none;
  background-color: #37474F;
  color: #FFFFFF;
}

#main-nav #main-menu li.home {
  font-size: 32px;
  font-size: 2rem;
  min-width: 82px;
  width: 82px;
  max-width: 82px;
  transition: all 120ms linear;
}

#main-nav #main-menu li.home a {
  transition: all 120ms linear;
}

#main-nav #main-menu li.home a::before {
  content: none;
}

#main-nav #main-menu li.home a.active, #main-nav #main-menu li.home a:focus, #main-nav #main-menu li.home a:active, #main-nav #main-menu li.home a:hover {
  text-decoration: none;
  background-color: #37474F;
  color: #FFFFFF;
}

#main-nav.sticky {
  box-shadow: -1px 4px 5px 0 rgba(0, 0, 0, 0.45);
  margin-top: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

#main-nav.sticky #main-menu li {
  padding-top: 0;
}

#main-nav.sticky #main-menu li a {
  height: 55px;
  font-size: 21px;
  font-size: 1.3125rem;
}

#main-nav.sticky #main-menu li.home {
  min-width: 57px;
  width: 57px;
  max-width: 57px;
}

#main-nav.sticky #main-menu li.home a {
  font-size: 28px;
  font-size: 1.75rem;
}

@media only screen and (max-width: 1199px) {
  #main-nav {
    display: none;
  }
}

#main-nav-mobile {
  display: none;
}

@media only screen and (max-width: 1199px) {
  #main-nav-mobile {
    visibility: hidden;
    height: 100%;
    min-height: 100%;
    padding: 15px;
    background-color: #C6CAD2;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 250ms linear;
    width: 550px;
  }
  #main-nav-mobile #main-menu-mobile-wrapper .header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
  }
  #main-nav-mobile #main-menu-mobile-wrapper .header a {
    display: block;
  }
  #main-nav-mobile #main-menu-mobile-wrapper .header .logo svg {
    object-fit: contain;
    width: 50px;
    height: 50px;
  }
  #main-nav-mobile #main-menu-mobile-wrapper .header .text {
    margin-left: 15px;
    font-size: 28px;
    font-size: 1.75rem;
    line-height: normal;
  }
  #main-nav-mobile #main-menu-mobile {
    display: block;
    transition: all 250ms linear;
    padding: 0;
    list-style: none;
  }
  #main-nav-mobile #main-menu-mobile li {
    position: relative;
  }
  #main-nav-mobile #main-menu-mobile li a {
    z-index: 21;
    padding: 10px 10px 10px 0;
    display: block;
    border-bottom: 3px solid #37474F;
  }
  #main-nav-mobile #main-menu-mobile li a::after {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-family: Material-Design-Iconic-Font, serif;
    content: "\f2fb";
    font-size: 26px;
    font-size: 1.625rem;
  }
  #main-nav-mobile #main-menu-mobile li a.active, #main-nav-mobile #main-menu-mobile li a:focus, #main-nav-mobile #main-menu-mobile li a:active, #main-nav-mobile #main-menu-mobile li a:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
  #main-nav-mobile #main-menu-mobile li:first-of-type {
    border-top: 3px solid #37474F;
  }
  #main-nav-mobile #top-menu-mobile {
    margin: 30px 0 0 0;
    padding: 0;
    list-style: none;
    text-align: center;
    font-weight: bold;
  }
  #main-nav-mobile #top-menu-mobile li a {
    display: block;
    padding: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-size: 0.875rem;
  }
  #main-nav-mobile #top-menu-mobile li a.active, #main-nav-mobile #top-menu-mobile li a:focus, #main-nav-mobile #top-menu-mobile li a:active, #main-nav-mobile #top-menu-mobile li a:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
  #main-nav-mobile .hotline {
    background-color: #FFFFFF;
    color: #37474F;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
    max-width: 400px;
    margin: 15px auto;
  }
  #wrapper {
    position: relative;
    margin-left: 0;
    width: 100%;
    transition: all 250ms linear;
  }
  body {
    overflow-x: hidden;
  }
  html.mobile-menu-active {
    overflow-y: hidden;
  }
  html.mobile-menu-active body {
    overflow-y: hidden;
    position: relative;
  }
  html.mobile-menu-active body #wrapper {
    margin-left: 550px;
  }
  html.mobile-menu-active body #main-nav-mobile {
    visibility: visible;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 767px) {
  #main-nav-mobile {
    width: calc(100% - 80px);
  }
  html.mobile-menu-active body #wrapper {
    margin-left: calc(100% - 80px);
  }
}

@media only screen and (max-width: 360px) {
  #main-nav-mobile {
    padding: 15px;
    width: calc(100% - 50px);
  }
  #main-nav-mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
  #main-nav-mobile #main-menu-mobile li a {
    font-size: 22px;
    font-size: 1.375rem;
  }
  #main-nav-mobile #main-menu-mobile li a::after {
    font-size: 23px;
    font-size: 1.4375rem;
  }
  #main-nav-mobile #top-menu-mobile li a {
    padding: 5px 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
  html.mobile-menu-active body #wrapper {
    margin-left: calc(100% - 50px);
  }
  html.mobile-menu-active body #header .hamburger-menu {
    margin-left: -15px;
  }
}

/*
@include bp-max-md {
  #main-nav-mobile {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 150ms linear;
    width: calc(100% - 80px);
    .hamburger-menu {
      position: absolute;
      top: $grid-gutter-width-half;
      left: $grid-gutter-width-half;
    }
    #main-menu-mobile {
      display: none;
      background-color: map-get($colors, 'gray');
    }
  }

  #wrapper {
    margin-left: 0;
    width: 100%;
    transition: all 150ms linear;
  }

  body {
    overflow-x: hidden;
  }

  html.mobile-menu-active {
    body {
      #wrapper {
        margin-left: calc(100% - 80px);
      }
      #main-nav-mobile {
        .hamburger-menu {
          left: 100%;
          margin-left: $grid-gutter-width-half;
        }
        #main-menu-mobile {
          display: block;
        }
      }
    }
  }
}
*/
html.no-flexbox #main-nav,
html.no-flexwrap #main-nav {
  background-color: #EDEEF1;
  padding-left: 40px;
}

html.no-flexbox #main-nav #main-menu,
html.no-flexwrap #main-nav #main-menu {
  display: block;
  text-align: center;
}

html.no-flexbox #main-nav #main-menu li,
html.no-flexwrap #main-nav #main-menu li {
  width: 31%;
  float: left;
  line-height: 80px;
  text-align: center;
}

html.no-flexbox #main-nav #main-menu li a,
html.no-flexwrap #main-nav #main-menu li a {
  display: block;
}

html.no-flexbox #main-nav #main-menu li.home,
html.no-flexwrap #main-nav #main-menu li.home {
  min-width: 82px;
  max-width: 82px;
  width: 82px;
}

html.no-flexbox #main-nav #main-menu li.home a,
html.no-flexwrap #main-nav #main-menu li.home a {
  background-color: #37474F;
  color: #FFFFFF;
}

html.no-flexbox #main-nav #main-menu li.home a::before,
html.no-flexwrap #main-nav #main-menu li.home a::before {
  content: none;
}

html.no-flexbox #main-nav.sticky,
html.no-flexwrap #main-nav.sticky {
  padding-left: 20px;
}

html.no-flexbox #main-nav.sticky #main-menu li,
html.no-flexwrap #main-nav.sticky #main-menu li {
  line-height: 55px;
  width: 32%;
}

html.no-flexbox #main-nav.sticky #main-menu li.home,
html.no-flexwrap #main-nav.sticky #main-menu li.home {
  min-width: 55px;
  max-width: 55px;
  width: 55px;
}

#top-nav {
  float: left;
  margin-right: 60px;
  margin-top: 5px;
}

#top-nav #top-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

#top-nav #top-menu:before, #top-nav #top-menu:after {
  content: " ";
  display: table;
}

#top-nav #top-menu:after {
  clear: both;
}

#top-nav #top-menu li {
  float: left;
  margin-right: 30px;
  margin-bottom: 5px;
}

#top-nav #top-menu li:last-of-type {
  margin-right: 0;
}

#top-nav #top-menu li a {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #FFFFFF;
}

#user-nav {
  display: none;
  min-width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  margin-top: 15px;
  top: 100%;
  right: 0;
  border-radius: 5px;
  padding: 10px;
}

#user-nav:after {
  content: '';
  width: 0;
  height: 0;
  -webkit-transform: rotate(360deg);
  border-style: solid;
  border-width: 0 10px 16.25px 10px;
  border-color: transparent transparent #FFFFFF transparent;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-10px);
}

#user-nav::after {
  margin-top: 1px;
  left: 100%;
  margin-left: -20px;
  border-width: 0 10px 12px;
}

#user-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#user-nav ul li {
  margin-bottom: 5px;
}

#user-nav ul li a {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-align: right;
}

@media only screen and (max-width: 1199px) {
  #user-nav {
    max-width: calc(100vw - 30px);
  }
  #user-nav a {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

#footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer-menu:before, #footer-menu:after {
  content: " ";
  display: table;
}

#footer-menu:after {
  clear: both;
}

#footer-menu li {
  float: left;
  margin-right: 15px;
}

#footer-menu li a {
  color: #C6CAD2;
}

#footer-menu li:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  #footer-menu li {
    float: none;
    margin-bottom: 15px;
    margin-right: 0;
  }
  #footer-menu li:last-of-type {
    margin-bottom: 0;
  }
}

#header {
  font-weight: 600;
  color: #FFFFFF;
  background-color: #37474F;
}

#header > .inner {
  padding: 15px 75px;
  min-height: 400px;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

#header.sticky-offset {
  margin-bottom: 55px;
}

#header .top {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

#header .top .left {
  font-size: 13px;
  font-size: 0.8125rem;
}

#header .top .left a {
  color: #FFFFFF;
  font-size: 13px;
  font-size: 0.8125rem;
}

#header .top .right {
  margin-left: auto;
  display: flex;
  align-items: center;
}

#header .top .right .btn {
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: 15px;
  font-size: 14px;
  font-size: 0.875rem;
}

#header .top .right .btn:last-of-type {
  margin-right: 0;
}

#header .top .right .user {
  position: relative;
}

#header .top .right .user .name {
  text-transform: none;
  display: block;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: clip;
  text-overflow: ellipsis;
  overflow: hidden;
}

#header .top .right .user.active, #header .top .right .user:hover {
  border-radius: 5px;
}

#header .top .right .user.active .name, #header .top .right .user:hover .name {
  border-radius: 5px;
  background-color: #FBC02D;
  border-color: #FBC02D;
  color: #37474F;
}

#header .top .right .user.active #user-nav {
  display: block;
}

@media only screen and (max-width: 1199px) {
  #header .top {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
  }
  #header .top .left {
    display: none;
  }
  #header .top .right .btn {
    font-size: 16px;
    font-size: 1rem;
    padding: 5px 15px;
  }
  #header .top .right .user .name {
    max-width: 400px;
  }
  #header .top .right #top-nav {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  #header .top .right .user .name {
    max-width: 275px;
  }
}

@media only screen and (max-width: 767px) {
  #header .top .right .register {
    display: none !important;
  }
  #header .top .right .user .name {
    max-width: 150px;
  }
}

#header .bottom {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 75px;
  margin-top: -60px;
  display: flex;
  align-items: center;
}

#header .bottom .logo {
  float: left;
  margin-right: 30px;
}

#header .bottom .logo svg {
  width: 120px;
  height: 120px;
  object-fit: contain;
}

#header .bottom .text {
  float: left;
}

#header .bottom .text h1 {
  color: #FFFFFF;
}

#header .bottom .text .sub {
  font-size: 16px;
  font-size: 1rem;
}

@media only screen and (max-width: 1199px) {
  #header .bottom {
    display: block;
    position: relative;
    top: auto;
    left: auto;
    margin-top: 0;
    text-align: center;
  }
  #header .bottom .logo {
    float: none;
    margin: 0;
  }
  #header .bottom .text {
    float: none;
  }
}

#header #header-bg {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -585px;
  background: url("../images/hero_home.png") no-repeat right bottom;
  overflow: hidden;
  /*
    @include bp-max-md {
      margin-left: -($container-md / 2);
    }
    @include bp-max-sm {
      margin-left: -($container-sm / 2);
    }
    @include bp-max-xs {
      margin-left: 0;
      left: auto;
      right: $grid-gutter-width-half;
      width: 100%;
    }
    */
}

#header #header-bg.report {
  background-image: url("../images/hero_report.png");
}

#header #header-bg.mind-maker {
  background-image: url("../images/hero_mind_maker.png");
}

#header #header-bg.idea-scout {
  background-image: url("../images/hero_idea_scout.png");
}

@media only screen and (max-width: 1199px) {
  #header #header-bg {
    margin: 0;
    left: 0;
    width: 100%;
    background-position: center bottom;
    opacity: 0.1;
  }
}

@media only screen and (max-width: 1199px) {
  #header > .inner {
    height: 400px;
    max-height: 100vh;
    padding: 15px;
  }
  #header .bottom .logo {
    margin-bottom: 15px;
  }
  #header .bottom .logo svg {
    width: 80px;
    height: 80px;
  }
  #header .bottom .text h1 {
    font-size: 33px;
    font-size: 2.0625rem;
  }
  #header .bottom .text .sub {
    font-size: 15px;
    font-size: 0.9375rem;
    max-width: 235px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 350px) {
  #header .bottom .text h1 {
    font-size: 28px;
    font-size: 1.75rem;
  }
  #header .bottom .text .sub {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#header .scroll-down {
  display: none;
}

@media only screen and (max-width: 1199px) {
  #header .scroll-down {
    display: block;
    background-color: #FFFFFF;
    color: #37474F;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: 50%;
    text-align: center;
    line-height: 40px;
    margin-left: -20px;
    font-size: 36px;
    font-size: 2.25rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transform: translateY(0px);
  }
  #header .scroll-down i {
    margin-left: 1px;
    animation-duration: 1250ms;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: bounce;
  }
  @keyframes bounce {
    0%, 100% {
      transform: translateY(4px);
    }
    50% {
      transform: translateY(-2px);
    }
  }
}

#header .hamburger-menu {
  position: relative;
  z-index: 10;
  display: none;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  #header .hamburger-menu {
    display: block;
  }
}

html.no-flexwrap #header .top {
  display: block;
}

html.no-flexwrap #header .top .left {
  float: left;
  margin-top: 10px;
}

html.no-flexwrap #header .top .right {
  float: right;
  display: block;
}

html.no-flexwrap #header .top .right #top-nav {
  margin-top: 10px;
}

#footer {
  color: #C6CAD2;
}

#footer .footer-top {
  background-color: #37474F;
}

#footer .footer-header {
  display: flex;
  align-items: center;
}

#footer .footer-header:before, #footer .footer-header:after {
  content: " ";
  display: table;
}

#footer .footer-header:after {
  clear: both;
}

#footer .footer-header .logo {
  float: left;
}

#footer .footer-header .logo svg {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

#footer .footer-header .title {
  float: left;
  margin-left: 15px;
  color: #FFFFFF;
}

#footer .footer-header .title h2, #footer .footer-header .title h3 {
  color: #FFFFFF;
  font-size: 40px;
  font-size: 2.5rem;
}

@media only screen and (max-width: 580px) {
  #footer .footer-header {
    text-align: center;
    display: block;
  }
  #footer .footer-header .logo {
    float: none;
    margin-bottom: 15px;
  }
  #footer .footer-header .title {
    margin: 0;
    float: none;
  }
  #footer .footer-header .title h2, #footer .footer-header .title h3 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

#footer .footer-bottom {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 15px 0;
  background-color: #222c31;
}

#footer .footer-bottom .inner {
  max-width: 1920px;
  position: relative;
}

#footer .footer-bottom a {
  color: #FBC02D;
  font-weight: 600;
}

#footer .footer-bottom .content {
  display: flex;
  align-items: center;
}

#footer .footer-bottom .content .logo {
  display: inline-block;
  margin-right: 15px;
}

#footer .footer-bottom .content .logo svg {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

#footer .footer-bottom .scroll-up {
  display: block;
  background-color: #37474F;
  color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  bottom: -6px;
  right: 15px;
  text-align: center;
  line-height: 32px;
  font-size: 30px;
  font-size: 1.875rem;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transform: translateY(0px);
}

#footer .footer-bottom .scroll-up i {
  margin-left: 1px;
  animation-duration: 1500ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: bounce;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-1px);
  }
}

@media only screen and (max-width: 991px) {
  #footer .footer-bottom .content {
    padding-right: 47px;
  }
  #footer .footer-bottom .scroll-up {
    right: 0;
    bottom: 50%;
    margin-bottom: -16px;
  }
}

#footer .footer-widgets {
  margin-bottom: -30px;
}

#footer .footer-widgets .footer-widget {
  margin-bottom: 30px;
}

#footer .footer-widgets .widget-title {
  color: #FBC02D;
  border-bottom: 5px solid #222c31;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

#footer .footer-widgets .widget-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#footer .footer-widgets .widget-content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#footer .footer-widgets .widget-content ul li .count {
  color: #FBC02D;
  font-weight: bold;
  margin: 0 3px 0 5px;
}

#footer .footer-widgets .widget-content ul li i {
  font-size: 22px;
  font-size: 1.375rem;
}

#footer .footer-widgets .widget-content ul li:last-of-type {
  margin-bottom: 0;
}

#footer .footer-widgets .widget-content .images {
  margin-top: 15px;
}

#footer .footer-widgets .widget-content .images a {
  display: block;
}

#cookie-consent {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  left: 10px;
  width: 400px;
  padding: 15px;
  background-color: #222c31;
  border: 3px solid #181e22;
  color: #FFFFFF;
}

#cookie-consent > .message {
  margin-bottom: 15px;
}

#cookie-consent > .buttons {
  text-align: center;
}

#cookie-consent > .buttons > .more {
  color: #FFFFFF;
  display: block;
  text-decoration: underline;
  margin-bottom: 15px;
}

#cookie-consent > .buttons > .more:hover {
  color: #FBC02D !important;
}

#cookie-consent > .buttons > .accept {
  color: #37474F;
  background-color: #FFFFFF;
  border-color: transparent;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  text-align: center;
  padding: 5px 30px;
  min-width: 200px;
  max-width: 300px;
  margin: 0;
  cursor: pointer;
}

#cookie-consent > .buttons > .accept:focus, #cookie-consent > .buttons > .accept.focus {
  color: #37474F;
  background-color: #e6e5e5;
  border-color: rgba(0, 0, 0, 0);
}

#cookie-consent > .buttons > .accept:hover {
  color: #37474F;
  background-color: #e6e5e5;
  border-color: rgba(0, 0, 0, 0);
}

#cookie-consent > .buttons > .accept:active, #cookie-consent > .buttons > .accept.active,
.open > #cookie-consent > .buttons > .accept.dropdown-toggle {
  color: #37474F;
  background-color: #e6e5e5;
  border-color: rgba(0, 0, 0, 0);
}

#cookie-consent > .buttons > .accept:active:hover, #cookie-consent > .buttons > .accept:active:focus, #cookie-consent > .buttons > .accept:active.focus, #cookie-consent > .buttons > .accept.active:hover, #cookie-consent > .buttons > .accept.active:focus, #cookie-consent > .buttons > .accept.active.focus,
.open > #cookie-consent > .buttons > .accept.dropdown-toggle:hover,
.open > #cookie-consent > .buttons > .accept.dropdown-toggle:focus,
.open > #cookie-consent > .buttons > .accept.dropdown-toggle.focus {
  color: #37474F;
  background-color: #d4d4d4;
  border-color: rgba(0, 0, 0, 0);
}

#cookie-consent > .buttons > .accept:active, #cookie-consent > .buttons > .accept.active,
.open > #cookie-consent > .buttons > .accept.dropdown-toggle {
  background-image: none;
}

#cookie-consent > .buttons > .accept.disabled:hover, #cookie-consent > .buttons > .accept.disabled:focus, #cookie-consent > .buttons > .accept.disabled.focus, #cookie-consent > .buttons > .accept[disabled]:hover, #cookie-consent > .buttons > .accept[disabled]:focus, #cookie-consent > .buttons > .accept[disabled].focus,
fieldset[disabled] #cookie-consent > .buttons > .accept:hover,
fieldset[disabled] #cookie-consent > .buttons > .accept:focus,
fieldset[disabled] #cookie-consent > .buttons > .accept.focus {
  background-color: #FFFFFF;
  border-color: transparent;
}

#cookie-consent > .buttons > .accept .badge {
  color: #FFFFFF;
  background-color: #37474F;
}

#cookie-consent > .buttons > .accept:hover {
  background-color: #FBC02D !important;
  color: #37474F !important;
}

@media only screen and (max-width: 767px) {
  #cookie-consent {
    background-color: rgba(34, 44, 49, 0.95);
    border-color: rgba(24, 30, 34, 0.9);
    position: absolute;
    border-left-width: 0;
    border-right-width: 0;
    width: 100%;
    left: 0;
    margin-top: 3px;
    top: 400px;
    bottom: auto;
    transform: translateY(-100%);
  }
  #cookie-consent > .message {
    text-align: center;
  }
  #cookie-consent > .buttons > .accept {
    width: 100%;
    min-width: 0;
  }
}

@media only screen and (max-width: 767px) and (max-height: 399px) {
  #cookie-consent {
    top: 100vh;
  }
}

@media only screen and (max-width: 767px) and (max-height: 530px) {
  #cookie-consent {
    top: 530px !important;
  }
}

@media only screen and (min-width: 768px) {
  .box-grid {
    display: flex;
    flex-wrap: wrap;
  }
  .box-grid > [class^="col-"],
  .box-grid > [class*=" col-"] {
    display: flex;
    flex-direction: column;
  }
  .box-grid .box {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .box-grid .box .footer {
    margin-top: auto;
  }
  .box-grid .box .mind-maker-list .content, .box-grid .box .mind-maker-list .header,
  .box-grid .box .mind-maker-detail .content,
  .box-grid .box .mind-maker-detail .header,
  .box-grid .box .idea-scout-list .content,
  .box-grid .box .idea-scout-list .header,
  .box-grid .box .idea-scout-detail .content,
  .box-grid .box .mind-maker-detail-container .mind-maker-detail .content,
  .mind-maker-detail-container .box-grid .box .mind-maker-detail .content,
  .box-grid .box .idea-scout-detail .header,
  .box-grid .box .mind-maker-detail-container .mind-maker-detail .header,
  .mind-maker-detail-container .box-grid .box .mind-maker-detail .header {
    margin-bottom: auto;
  }
}

.box, .box-alternate {
  display: block;
  margin-bottom: 15px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  padding: 15px;
  background: #FFFFFF;
}

.box:before, .box:after, .box-alternate:before, .box-alternate:after {
  content: " ";
  display: table;
}

.box:after, .box-alternate:after {
  clear: both;
}

@media only screen and (max-width: 767px) {
  .box, .box-alternate {
    margin-bottom: 30px;
  }
}

.box .header .image, .box .content .image, .box .footer .image, .box-alternate .header .image, .box-alternate .content .image, .box-alternate .footer .image {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}

.box .header .image img, .box .content .image img, .box .footer .image img, .box-alternate .header .image img, .box-alternate .content .image img, .box-alternate .footer .image img {
  width: 100%;
  max-width: 100%;
  height: 100px;
  object-fit: cover;
}

@media only screen and (max-width: 767px) {
  .box .header .image img, .box .content .image img, .box .footer .image img, .box-alternate .header .image img, .box-alternate .content .image img, .box-alternate .footer .image img {
    height: 165px;
  }
}

.box .header h3, .box .content h3, .box .footer h3, .box-alternate .header h3, .box-alternate .content h3, .box-alternate .footer h3 {
  color: #000000;
  margin: 15px 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bold;
}

.box > .progress-bar, .box-alternate > .progress-bar {
  background-color: #d0d3db;
  margin: -15px;
  margin-bottom: 15px;
  height: 15px;
}

.box > .progress-bar .progress, .box-alternate > .progress-bar .progress {
  min-width: 20px;
  height: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #37474F;
}

.box:not(.no-hover):hover, .box-alternate:not(.no-hover):hover {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
}

.box {
  border-radius: 10px;
}

.box.no-radius {
  border-radius: 0;
}

.box .header .top:before, .box .header .top:after {
  content: " ";
  display: table;
}

.box .header .top:after {
  clear: both;
}

.box .header .top .icon {
  float: left;
  margin-right: 5px;
}

.box .header .top .icon img {
  width: 30px;
  height: 30px;
  max-width: 30px;
  object-fit: cover;
  border-radius: 50%;
}

.box .header .top .icon i {
  font-size: 30px;
  font-size: 1.875rem;
}

.box .header .top .text {
  float: left;
  font-weight: bold;
  font-size: 12px;
  font-size: 0.75rem;
}

.box .content {
  font-size: 14px;
  font-size: 0.875rem;
}

.box .content .btn {
  margin: 30px 0;
}

.box .footer .top {
  margin-top: 15px;
}

.box .footer .bottom {
  margin: -15px;
  margin-top: 15px;
  padding: 30px 15px;
  text-align: center;
  background-color: #f6f6f8;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.box.report {
  min-height: 110px;
  border-left: 15px solid transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.box.report:before, .box.report:after {
  content: " ";
  display: table;
}

.box.report:after {
  clear: both;
}

.box.report .header {
  min-width: 80px;
  margin-right: 30px;
}

.box.report .header img {
  width: 80px;
  height: 80px;
  max-width: 80px;
  object-fit: cover;
}

.box.report .content {
  font-size: 14px;
  font-size: 0.875rem;
}

.box.report .content .category, .box.report .content .prefix {
  font-weight: bold;
}

.box.report .content .category {
  margin-bottom: 5px;
}

.box.report .footer {
  margin-left: auto;
  min-width: 285px;
}

.box.report .footer:before, .box.report .footer:after {
  content: " ";
  display: table;
}

.box.report .footer:after {
  clear: both;
}

.box.report .footer ul {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
}

.box.report .footer ul:before, .box.report .footer ul:after {
  content: " ";
  display: table;
}

.box.report .footer ul:after {
  clear: both;
}

.box.report .footer ul li {
  display: flex;
  align-items: center;
  float: left;
  border-right: 1px solid #c2c5d0;
  padding: 10px 15px 10px 0;
  margin-left: 15px;
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
}

.box.report .footer ul li i {
  font-size: 18px;
  font-size: 1.125rem;
  margin-right: 10px;
}

.box.report .footer ul li:first-of-type {
  margin-left: 0;
}

.box.report .footer ul li:last-of-type {
  border-right: 0;
  padding-right: 0;
}

.box.report .arrow {
  margin-left: 50px;
  float: right;
  font-size: 45px;
  font-size: 2.8125rem;
  color: #c2c5d0;
}

@media only screen and (min-width: 1281px) and (max-width: 1600px) {
  .box.report.half-break .footer {
    min-width: 70px;
  }
  .box.report.half-break .footer ul li {
    display: block;
    float: none;
    border-right: none;
    padding: 0;
    margin-left: 0;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
  .box.report.half-break {
    border-top: 15px solid transparent;
    border-left: none;
    display: block;
  }
  .box.report.half-break .header {
    margin: -15px;
    margin-bottom: 15px;
  }
  .box.report.half-break .header img {
    width: 100%;
    max-width: 100%;
    height: 165px;
  }
  .box.report.half-break .content {
    margin: 0;
  }
  .box.report.half-break .content .category,
  .box.report.half-break .content .date,
  .box.report.half-break .content .location {
    margin-bottom: 5px;
  }
  .box.report.half-break .footer {
    margin: -15px;
    margin-top: 15px;
    padding: 15px;
    background-color: #f6f6f8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .box.report.half-break .footer ul {
    float: left;
  }
  .box.report.half-break .footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    font-size: 0.625rem;
  }
  .box.report.half-break .footer ul li i {
    font-size: 16px;
    font-size: 1rem;
  }
  .box.report.half-break .arrow {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box.report .footer {
    min-width: 70px;
  }
  .box.report .footer ul li {
    float: none;
    border: none;
    margin: 0 0 10px 0;
    padding: 0;
  }
  .box.report .footer ul li:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .box.report {
    border-top: 15px solid transparent;
    border-left: none;
    display: block;
  }
  .box.report .header {
    margin: -15px;
    margin-bottom: 15px;
  }
  .box.report .header img {
    width: 100%;
    max-width: 100%;
    height: 165px;
  }
  .box.report .content {
    margin: 0;
  }
  .box.report .content .category,
  .box.report .content .date,
  .box.report .content .location {
    margin-bottom: 5px;
  }
  .box.report .footer {
    margin: -15px;
    margin-top: 15px;
    padding: 15px;
    background-color: #f6f6f8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .box.report .footer ul {
    float: left;
  }
  .box.report .footer ul li {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 10px;
    font-size: 0.625rem;
  }
  .box.report .footer ul li i {
    font-size: 16px;
    font-size: 1rem;
  }
  .box.report .arrow {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .box.report .footer {
    min-width: 0;
  }
  .box.report .footer ul {
    float: none;
  }
  .box.report .footer ul li {
    width: 100%;
    display: block;
    float: none;
    border: none;
    margin: 0;
  }
}

.box.mind-maker .header .date {
  margin-top: 7px;
}

.box.mind-maker .progress-bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.box.mind-maker .progress-bar .progress {
  min-width: 20px;
}

.box.mind-maker .footer .bottom {
  text-align: center;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bold;
}

.box.mind-maker .footer .bottom:before, .box.mind-maker .footer .bottom:after {
  content: " ";
  display: table;
}

.box.mind-maker .footer .bottom:after {
  clear: both;
}

.box.mind-maker .footer .bottom .type {
  padding: 5px 10px 5px 0;
  border-right: 1px solid #c2c5d0;
}

.box.mind-maker .footer .bottom .participants {
  padding: 5px 0 5px 5px;
}

@media only screen and (max-width: 300px) {
  .box.mind-maker .footer .bottom .type {
    display: block;
    border: none;
  }
  .box.mind-maker .footer .bottom .type, .box.mind-maker .footer .bottom .participants {
    padding: 5px 10px;
  }
}

.box.idea-scout .header .top .text .date {
  font-weight: normal;
  font-size: 10px;
  font-size: 0.625rem;
  color: #9ba2b0;
}

.box.idea-scout .footer .top:before, .box.idea-scout .footer .top:after {
  content: " ";
  display: table;
}

.box.idea-scout .footer .top:after {
  clear: both;
}

.box.idea-scout .footer .top ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box.idea-scout .footer .top ul li {
  float: left;
  background: #f6f6f8;
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 1px 3px;
  font-size: 10px;
  font-size: 0.625rem;
}

.box.idea-scout .footer .top ul li:last-of-type {
  margin-right: 0;
}

.box.idea-scout .progress-bar {
  width: 85%;
  margin: 15px auto 30px auto;
  background-color: #666666;
  border-radius: 10px;
  height: 10px;
}

.box.idea-scout .progress-bar .progress {
  min-width: 20px;
  height: inherit;
  border-radius: inherit;
  background-color: #37474F;
}

.box.idea-scout .remaining-time {
  font-size: 10px;
  font-size: 0.625rem;
}

.box.idea-scout .participants {
  color: #37474F;
}

.box.idea-scout .participants i {
  font-size: 35px;
  font-size: 2.1875rem;
  margin-right: 5px;
}

.box.idea-scout .participants .count {
  font-size: 35px;
  font-size: 2.1875rem;
  font-weight: bold;
}

.box.idea-scout .required-participants {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bold;
  margin-top: 10px;
}

.box.state-placeholder {
  border-color: #DDDDDD !important;
}

.box.state-placeholder .progress-bar .progress {
  background-color: #DDDDDD !important;
}

.box.state-placeholder .icon i {
  color: #DDDDDD !important;
}

.box.state-placeholder.idea-scout .participants i {
  color: #DDDDDD !important;
}

.box.state-new {
  border-color: #558B2F !important;
}

.box.state-new .progress-bar .progress {
  background-color: #558B2F !important;
}

.box.state-new .icon i {
  color: #558B2F !important;
}

.box.state-new.idea-scout .participants i {
  color: #558B2F !important;
}

.box.state-process {
  border-color: #FBC02D !important;
}

.box.state-process .progress-bar .progress {
  background-color: #FBC02D !important;
}

.box.state-process .icon i {
  color: #FBC02D !important;
}

.box.state-process.idea-scout .participants i {
  color: #FBC02D !important;
}

.box.state-finished {
  border-color: #000 !important;
}

.box.state-finished .progress-bar .progress {
  background-color: #000 !important;
}

.box.state-finished .icon i {
  color: #000 !important;
}

.box.state-finished.idea-scout .participants i {
  color: #000 !important;
}

.box.state-deleted {
  border-color: #AF0000 !important;
}

.box.state-deleted .progress-bar .progress {
  background-color: #AF0000 !important;
}

.box.state-deleted .icon i {
  color: #AF0000 !important;
}

.box.state-deleted.idea-scout .participants i {
  color: #AF0000 !important;
}

.box.state-review {
  border-color: #989898 !important;
}

.box.state-review .progress-bar .progress {
  background-color: #989898 !important;
}

.box.state-review .icon i {
  color: #989898 !important;
}

.box.state-review.idea-scout .participants i {
  color: #989898 !important;
}

.box.state-expired {
  border-color: #d0d3db !important;
}

.box.state-expired .progress-bar .progress {
  background-color: #d0d3db !important;
}

.box.state-expired .icon i {
  color: #d0d3db !important;
}

.box.state-expired.idea-scout .participants i {
  color: #d0d3db !important;
}

.box.state-failed {
  border-color: #AF0000 !important;
}

.box.state-failed .progress-bar .progress {
  background-color: #AF0000 !important;
}

.box.state-failed .icon i {
  color: #AF0000 !important;
}

.box.state-failed.idea-scout .participants i {
  color: #AF0000 !important;
}

.box.state-success {
  border-color: #558B2F !important;
}

.box.state-success .progress-bar .progress {
  background-color: #558B2F !important;
}

.box.state-success .icon i {
  color: #558B2F !important;
}

.box.state-success.idea-scout .participants i {
  color: #558B2F !important;
}

.box-alternate {
  margin-bottom: 60px;
}

.box-alternate .progress-bar {
  height: 8px;
}

.box-alternate .header {
  font-size: 13x;
  font-size: 0.8125rem;
  margin-bottom: 30px;
  font-weight: 700;
  padding-top: 5px;
}

.box-alternate .header:before, .box-alternate .header:after {
  content: " ";
  display: table;
}

.box-alternate .header:after {
  clear: both;
}

.box-alternate .header .created {
  font-weight: normal;
  font-size: 12px;
  font-size: 0.75rem;
  color: #9ba2b0;
}

.box-alternate .header .top:before, .box-alternate .header .top:after {
  content: " ";
  display: table;
}

.box-alternate .header .top:after {
  clear: both;
}

.box-alternate .header .top ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box-alternate .header .top ul li {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 30px;
}

.box-alternate .header .top ul li i {
  position: absolute;
  top: -4px;
  left: 0;
}

.box-alternate .header .top .left {
  float: left;
}

.box-alternate .header .top .right {
  float: right;
}

.box-alternate .header .top .right i {
  display: none;
}

.box-alternate .header .bottom {
  margin-top: 15px;
}

.box-alternate .header .bottom .categories ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box-alternate .header .bottom .categories ul li {
  float: left;
  background: #f6f6f8;
  margin-right: 3px;
  margin-bottom: 3px;
  padding: 1px 3px;
  font-weight: 600;
}

.box-alternate .header .bottom .categories ul li:last-of-type {
  margin-right: 0;
}

.box-alternate .header i {
  font-size: 26px;
  font-size: 1.625rem;
}

.box-alternate .header .prefix, .box-alternate .header i, .box-alternate .header .highlight {
  color: #37474F;
}

@media only screen and (max-width: 1199px) {
  .box-alternate .header .top ul li {
    display: block;
    margin-bottom: 15px;
  }
  .box-alternate .header .top ul li:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .box-alternate .header .top .left {
    float: none;
  }
  .box-alternate .header .top .right {
    float: none;
    margin-bottom: 15px;
  }
  .box-alternate .header .top .right i {
    display: block;
  }
}

.box-alternate .content h3 {
  margin-bottom: 30px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  text-transform: uppercase;
}

.box-alternate .footer {
  margin: 45px 0 30px 0;
}

.box-alternate.state-placeholder .progress-bar .progress {
  background-color: #DDDDDD;
}

.box-alternate.state-placeholder .header .prefix, .box-alternate.state-placeholder .header i, .box-alternate.state-placeholder .header .highlight {
  color: #DDDDDD;
}

.box-alternate.state-new .progress-bar .progress {
  background-color: #558B2F;
}

.box-alternate.state-new .header .prefix, .box-alternate.state-new .header i, .box-alternate.state-new .header .highlight {
  color: #558B2F;
}

.box-alternate.state-process .progress-bar .progress {
  background-color: #FBC02D;
}

.box-alternate.state-process .header .prefix, .box-alternate.state-process .header i, .box-alternate.state-process .header .highlight {
  color: #FBC02D;
}

.box-alternate.state-finished .progress-bar .progress {
  background-color: #000;
}

.box-alternate.state-finished .header .prefix, .box-alternate.state-finished .header i, .box-alternate.state-finished .header .highlight {
  color: #000;
}

.box-alternate.state-deleted .progress-bar .progress {
  background-color: #AF0000;
}

.box-alternate.state-deleted .header .prefix, .box-alternate.state-deleted .header i, .box-alternate.state-deleted .header .highlight {
  color: #AF0000;
}

.box-alternate.state-review .progress-bar .progress {
  background-color: #989898;
}

.box-alternate.state-review .header .prefix, .box-alternate.state-review .header i, .box-alternate.state-review .header .highlight {
  color: #989898;
}

.box-alternate.state-expired .progress-bar .progress {
  background-color: #d0d3db;
}

.box-alternate.state-expired .header .prefix, .box-alternate.state-expired .header i, .box-alternate.state-expired .header .highlight {
  color: #d0d3db;
}

.box-alternate.state-failed .progress-bar .progress {
  background-color: #AF0000;
}

.box-alternate.state-failed .header .prefix, .box-alternate.state-failed .header i, .box-alternate.state-failed .header .highlight {
  color: #AF0000;
}

.box-alternate.state-success .progress-bar .progress {
  background-color: #558B2F;
}

.box-alternate.state-success .header .prefix, .box-alternate.state-success .header i, .box-alternate.state-success .header .highlight {
  color: #558B2F;
}

.box-alternate.mind-maker-list .gallery, .box-alternate.mind-maker-detail .gallery, .box-alternate.idea-scout-list .gallery, .box-alternate.idea-scout-detail .gallery, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery {
  margin-top: 30px;
}

.box-alternate.mind-maker-list .gallery:before, .box-alternate.mind-maker-list .gallery:after, .box-alternate.mind-maker-detail .gallery:before, .box-alternate.mind-maker-detail .gallery:after, .box-alternate.idea-scout-list .gallery:before, .box-alternate.idea-scout-list .gallery:after, .box-alternate.idea-scout-detail .gallery:before, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery:before, .box-alternate.idea-scout-detail .gallery:after, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery:after {
  content: " ";
  display: table;
}

.box-alternate.mind-maker-list .gallery:after, .box-alternate.mind-maker-detail .gallery:after, .box-alternate.idea-scout-list .gallery:after, .box-alternate.idea-scout-detail .gallery:after, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery:after {
  clear: both;
}

.box-alternate.mind-maker-list .gallery a, .box-alternate.mind-maker-detail .gallery a, .box-alternate.idea-scout-list .gallery a, .box-alternate.idea-scout-detail .gallery a, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery a {
  display: block;
  float: left;
  max-width: 165px;
  max-height: 200px;
  margin-right: 15px;
}

.box-alternate.mind-maker-list .gallery a:last-of-type, .box-alternate.mind-maker-detail .gallery a:last-of-type, .box-alternate.idea-scout-list .gallery a:last-of-type, .box-alternate.idea-scout-detail .gallery a:last-of-type, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery a:last-of-type {
  margin-right: 0;
}

.box-alternate.mind-maker-list .gallery img, .box-alternate.mind-maker-detail .gallery img, .box-alternate.idea-scout-list .gallery img, .box-alternate.idea-scout-detail .gallery img, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery img {
  width: auto;
  max-width: 100%;
  max-height: 200px;
}

@media only screen and (max-width: 767px) {
  .box-alternate.mind-maker-list .gallery a, .box-alternate.mind-maker-detail .gallery a, .box-alternate.idea-scout-list .gallery a, .box-alternate.idea-scout-detail .gallery a, .mind-maker-detail-container .box-alternate.mind-maker-detail .gallery a {
    max-width: none;
    float: none;
    margin-bottom: 15px;
    text-align: center;
    margin-right: 0;
  }
}

.box-alternate.mind-maker-list .downloads, .box-alternate.mind-maker-detail .downloads, .box-alternate.idea-scout-list .downloads, .box-alternate.idea-scout-detail .downloads, .mind-maker-detail-container .box-alternate.mind-maker-detail .downloads {
  margin-top: 30px;
}

.box-alternate.mind-maker-list .downloads h4, .box-alternate.mind-maker-detail .downloads h4, .box-alternate.idea-scout-list .downloads h4, .box-alternate.idea-scout-detail .downloads h4, .mind-maker-detail-container .box-alternate.mind-maker-detail .downloads h4 {
  font-size: 20px;
  font-size: 1.25rem;
}

.box-alternate.mind-maker-list .downloads ul, .box-alternate.mind-maker-detail .downloads ul, .box-alternate.idea-scout-list .downloads ul, .box-alternate.idea-scout-detail .downloads ul, .mind-maker-detail-container .box-alternate.mind-maker-detail .downloads ul {
  margin: 5px 0 0 0;
  padding: 0 0 0 20px;
}

.box-alternate.mind-maker-list .downloads ul li, .box-alternate.mind-maker-detail .downloads ul li, .box-alternate.idea-scout-list .downloads ul li, .box-alternate.idea-scout-detail .downloads ul li, .mind-maker-detail-container .box-alternate.mind-maker-detail .downloads ul li {
  margin-bottom: 5px;
}

.box-alternate.mind-maker-list .downloads ul li:last-of-type, .box-alternate.mind-maker-detail .downloads ul li:last-of-type, .box-alternate.idea-scout-list .downloads ul li:last-of-type, .box-alternate.idea-scout-detail .downloads ul li:last-of-type, .mind-maker-detail-container .box-alternate.mind-maker-detail .downloads ul li:last-of-type {
  margin-bottom: 0;
}

.box-alternate.mind-maker-list .content, .box-alternate.mind-maker-detail .content, .box-alternate.idea-scout-list .content, .box-alternate.idea-scout-detail .content, .mind-maker-detail-container .box-alternate.mind-maker-detail .content {
  position: relative;
}

.box-alternate.mind-maker-list .content .social-share, .box-alternate.mind-maker-detail .content .social-share, .box-alternate.idea-scout-list .content .social-share, .box-alternate.idea-scout-detail .content .social-share, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share {
  font-size: 24px;
  font-size: 1.5rem;
  position: absolute;
  bottom: -45px;
  right: 0;
  color: #37474F !important;
}

.box-alternate.mind-maker-list .content .social-share a, .box-alternate.mind-maker-detail .content .social-share a, .box-alternate.idea-scout-list .content .social-share a, .box-alternate.idea-scout-detail .content .social-share a, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share a {
  margin-right: 10px;
}

.box-alternate.mind-maker-list .content .social-share a:last-of-type, .box-alternate.mind-maker-detail .content .social-share a:last-of-type, .box-alternate.idea-scout-list .content .social-share a:last-of-type, .box-alternate.idea-scout-detail .content .social-share a:last-of-type, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share a:last-of-type {
  margin-right: 0;
}

.box-alternate.mind-maker-list .content .social-share a:hover, .box-alternate.mind-maker-detail .content .social-share a:hover, .box-alternate.idea-scout-list .content .social-share a:hover, .box-alternate.idea-scout-detail .content .social-share a:hover, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .box-alternate.mind-maker-list .content .social-share, .box-alternate.mind-maker-detail .content .social-share, .box-alternate.idea-scout-list .content .social-share, .box-alternate.idea-scout-detail .content .social-share, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share {
    font-size: 28px;
    font-size: 1.75rem;
    right: auto;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .box-alternate.mind-maker-list .content .social-share a, .box-alternate.mind-maker-detail .content .social-share a, .box-alternate.idea-scout-list .content .social-share a, .box-alternate.idea-scout-detail .content .social-share a, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share a {
    margin-right: 5px;
  }
  .box-alternate.mind-maker-list .content .social-share a:last-of-type, .box-alternate.mind-maker-detail .content .social-share a:last-of-type, .box-alternate.idea-scout-list .content .social-share a:last-of-type, .box-alternate.idea-scout-detail .content .social-share a:last-of-type, .mind-maker-detail-container .box-alternate.mind-maker-detail .content .social-share a:last-of-type {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .box-alternate.idea-scout-list .content:before, .box-alternate.idea-scout-list .content:after, .box-alternate.mind-maker-list .content:before, .box-alternate.mind-maker-list .content:after {
    content: " ";
    display: table;
  }
  .box-alternate.idea-scout-list .content:after, .box-alternate.mind-maker-list .content:after {
    clear: both;
  }
  .box-alternate.idea-scout-list .content .image, .box-alternate.mind-maker-list .content .image {
    margin: 0 30px 0 0;
    float: left;
  }
  .box-alternate.idea-scout-list .content .image img, .box-alternate.mind-maker-list .content .image img {
    width: 200px;
    height: 200px;
    max-width: 200px;
    object-fit: cover;
  }
  .box-alternate.idea-scout-list .content .text, .box-alternate.mind-maker-list .content .text {
    float: left;
    width: 100%;
    margin-left: -230px;
    padding-left: 230px;
  }
  .box-alternate.idea-scout-list .content .text h3, .box-alternate.mind-maker-list .content .text h3 {
    margin-top: 0;
  }
}

.content-list [class^="col-"]:last-of-type .box,
.content-list [class^="col-"]:last-of-type .box-alternate,
.content-list [class*=" col-"]:last-of-type .box,
.content-list [class*=" col-"]:last-of-type .box-alternate {
  margin-bottom: 15px;
}

html.no-flexwrap .box.report {
  display: block;
}

html.no-flexwrap .box.report .header {
  float: left;
}

html.no-flexwrap .box.report .content {
  float: left;
}

html.no-flexwrap .box.report .footer {
  float: left;
  margin-top: 18px;
  margin-left: 30px;
}

.pac-container {
  z-index: 999999 !important;
}

.form-error {
  color: #af0000;
  font-weight: 600;
  padding: 15px 0 30px 0;
}

.form-error ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-error ul li {
  margin-bottom: 10px;
}

.form-error ul li:last-of-type {
  margin-bottom: 0;
}

.form-success {
  color: #558B2F;
  font-weight: 600;
  padding: 30px 0;
}

.form-group + .form-group .form-error, .form-group + .form-group .form-success {
  margin-top: -30px;
}

h1 + form, h2 + form, h3 + form, h4 + form, h5 + form, h6 + form {
  margin-top: 15px;
}

form fieldset {
  padding: 0;
  border: none;
}

form select.form-control,
form textarea.form-control,
form .google-autocomplete.form-control,
form input[type=password].form-control,
form input[type=email].form-control,
form input[type=search].form-control,
form input[type=number].form-control,
form input[type=tel].form-control,
form input[type=text].form-control {
  border-radius: 10px;
  border: 2px solid #37474F;
  padding-left: 15px;
  padding-right: 15px;
}

form select.form-control:active, form select.form-control:focus,
form textarea.form-control:active,
form textarea.form-control:focus,
form .google-autocomplete.form-control:active,
form .google-autocomplete.form-control:focus,
form input[type=password].form-control:active,
form input[type=password].form-control:focus,
form input[type=email].form-control:active,
form input[type=email].form-control:focus,
form input[type=search].form-control:active,
form input[type=search].form-control:focus,
form input[type=number].form-control:active,
form input[type=number].form-control:focus,
form input[type=tel].form-control:active,
form input[type=tel].form-control:focus,
form input[type=text].form-control:active,
form input[type=text].form-control:focus {
  outline: none;
  border-color: #56707c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(97, 125, 139, 0.6);
}

form .form-group {
  margin-bottom: 30px;
}

form .form-group label {
  margin-bottom: 10px;
}

form .radio-group.inline:before, form .radio-group.inline:after {
  content: " ";
  display: table;
}

form .radio-group.inline:after {
  clear: both;
}

form .radio-group.inline .radio-wrapper {
  float: left;
  margin-right: 15px;
  margin-bottom: 15px;
}

form .radio-group.inline .radio-wrapper:last-of-type {
  margin-right: 0;
}

form .radio-wrapper {
  color: #C6CAD2;
  display: block;
  position: relative;
}

form .radio-wrapper input[type=radio] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 40px;
  height: 35px;
  z-index: 15;
  cursor: pointer;
}

form .radio-wrapper label {
  display: block;
  position: relative;
  z-index: 9;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 5px 7px 50px;
}

form .radio-wrapper:hover label {
  color: #000000;
}

form .radio-wrapper .check {
  display: block;
  position: absolute;
  border: 4px solid #C6CAD2;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  top: 0;
  left: 0;
  z-index: 5;
  transition: border 120ms linear;
}

form .radio-wrapper:hover .check {
  border: 5px solid #000000;
}

form .radio-wrapper .check::before {
  display: block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
  left: 5px;
  margin: auto;
  transition: background 120ms linear;
}

form .radio-wrapper input[type=radio]:checked ~ .check {
  border: 5px solid #37474F;
}

form .radio-wrapper input[type=radio]:checked ~ .check::before {
  background: #37474F;
}

form .radio-wrapper input[type=radio]:checked ~ label {
  color: #000000;
}

form .checkbox-group .checkbox-wrapper {
  display: block;
}

form .checkbox-wrapper {
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
}

form .checkbox-wrapper input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 25px;
  height: 25px;
  z-index: 15;
  cursor: pointer;
}

form .checkbox-wrapper label {
  padding-left: 35px;
  font-weight: 600;
  cursor: pointer;
}

form .checkbox-wrapper .check {
  display: block;
  position: absolute;
  background-color: #37474F;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  top: 12px;
  left: 0;
  transform: translateY(-50%);
  z-index: 5;
  text-align: center;
}

form .checkbox-wrapper input[type=checkbox]:checked ~ .check::before {
  display: block;
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  left: 0;
  margin: auto;
  transition: background 120ms linear;
  content: '\2713';
  font-size: 14px;
  font-weight: bold;
  background-color: transparent;
  color: #FFFFFF;
}

form .checkbox-wrapper.inverted .check {
  background-color: #FFFFFF;
}

form .checkbox-wrapper.inverted input[type=checkbox]:checked ~ .check::before {
  color: #37474F;
}

form .checkbox-wrapper.large input[type=checkbox] {
  width: 40px;
  height: 40px;
  margin-top: -10px;
}

form .checkbox-wrapper.large label {
  padding-left: 50px;
}

form .checkbox-wrapper.large .check {
  height: 35px;
  width: 35px;
}

form .checkbox-wrapper.large input[type=checkbox]:checked ~ .check::before {
  height: 35px;
  width: 35px;
  font-size: 26px;
  top: -1px;
}

form .radio-group.inverted .radio-wrapper {
  color: #FFFFFF;
  display: block;
  position: relative;
}

form .radio-group.inverted .radio-wrapper:hover label {
  color: #FFFFFF;
}

form .radio-group.inverted .radio-wrapper:hover input[type=radio] ~ .check::before {
  background: #FFFFFF;
}

form .radio-group.inverted .radio-wrapper:hover input[type=radio] ~ .check {
  border: 5px solid #FFFFFF;
}

form .radio-group.inverted .radio-wrapper .check {
  border: 4px solid #FFFFFF;
}

form .radio-group.inverted .radio-wrapper input[type=radio]:checked ~ .check {
  border: 5px solid #FFFFFF;
}

form .radio-group.inverted .radio-wrapper input[type=radio]:checked ~ .check::before {
  background: #FFFFFF;
}

form .radio-group.inverted .radio-wrapper input[type=radio]:checked ~ label {
  color: #FFFFFF;
}

.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  text-align: center;
  padding: 7px 30px 5px 30px;
  border: 2px solid #37474F;
  margin: 0 5px 0 0;
  cursor: pointer;
}

.btn:not(.no-style) {
  color: #37474F;
}

.btn.uppercase {
  text-transform: uppercase !important;
}

.btn.spacing {
  letter-spacing: 1px !important;
}

.btn.bigger {
  padding: 12px 30px 10px 30px;
}

.btn.wider {
  min-width: 240px;
}

.btn.full-width {
  display: block;
  min-width: 0;
}

.btn:not(.disabled):not(.loading):hover {
  transition: all 120ms linear;
  text-decoration: none;
}

.btn:not(.fill):not(.disabled):not(.loading):hover {
  background-color: #37474F;
  color: #FFFFFF !important;
}

.btn.fill {
  background-color: #37474F;
  color: #FFFFFF !important;
}

.btn.fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #37474F !important;
}

.btn.inverted {
  border: 2px solid #FFFFFF;
  color: #FFFFFF !important;
}

.btn.inverted:not(.fill):not(.disabled):not(.loading):hover {
  background-color: #FFFFFF;
  color: #37474F !important;
}

.btn.inverted.fill {
  background-color: #FFFFFF;
  color: #37474F !important;
}

.btn.inverted.fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #FFFFFF !important;
}

.btn.disabled {
  cursor: not-allowed;
}

.btn.disabled.fill {
  background-color: #C6CAD2;
  border-color: #C6CAD2;
}

.btn .icon-wrapper {
  position: relative;
  display: inline-block;
}

.btn .icon-wrapper i {
  position: absolute;
  top: 50%;
  left: -35px;
  margin-top: -12px;
  font-size: 20px;
  font-size: 1.25rem;
}

.btn.loader {
  position: relative;
}

.btn.loader.loading {
  padding-left: 55px;
  padding-right: 55px;
}

.btn.loader.loading::before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  font-size: 28px;
  font-size: 1.75rem;
  content: '\f3ec';
  font-family: Material-Design-Iconic-Font, sans-serif;
  display: inline-block;
  text-rendering: auto;
  animation: zmdi-spin 2.25s infinite linear;
}

.btn.toggle .icon-wrapper .active {
  display: none;
}

.btn.toggle.active .icon-wrapper .deactivated {
  display: none;
}

.btn.toggle.active .icon-wrapper .active {
  display: block;
}

.btn.toggle.active .icon-wrapper span.active {
  display: inline-block;
}

.btn.primary:not(.no-style) {
  color: #37474F;
}

.btn.primary:not(.fill):not(.disabled):not(.loading):hover {
  background-color: #37474F;
}

.btn.primary.fill {
  background-color: #37474F;
}

.btn.primary.fill:not(.disabled):not(.loading):hover {
  color: #37474F !important;
}

.btn.primary.inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #37474F !important;
}

.btn.primary.inverted.fill {
  color: #37474F !important;
}

@media only screen and (max-width: 767px) {
  .btn {
    min-width: 0 !important;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.btn.state-placeholder:not(.disabled),
.state-placeholder .btn.inherit-state:not(.disabled) {
  border-color: #DDDDDD;
}

.btn.state-placeholder:not(.disabled):not(.fill),
.state-placeholder .btn.inherit-state:not(.disabled):not(.fill) {
  color: #DDDDDD;
}

.btn.state-placeholder:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-placeholder .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #DDDDDD;
}

.btn.state-placeholder:not(.disabled).fill,
.state-placeholder .btn.inherit-state:not(.disabled).fill {
  background-color: #DDDDDD;
}

.btn.state-placeholder:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-placeholder .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #DDDDDD !important;
}

.btn.state-placeholder:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-placeholder .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #DDDDDD !important;
}

.btn.state-placeholder:not(.disabled).inverted.fill,
.state-placeholder .btn.inherit-state:not(.disabled).inverted.fill {
  color: #DDDDDD !important;
}

.btn.state-new:not(.disabled),
.state-new .btn.inherit-state:not(.disabled) {
  border-color: #558B2F;
}

.btn.state-new:not(.disabled):not(.fill),
.state-new .btn.inherit-state:not(.disabled):not(.fill) {
  color: #558B2F;
}

.btn.state-new:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-new .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #558B2F;
}

.btn.state-new:not(.disabled).fill,
.state-new .btn.inherit-state:not(.disabled).fill {
  background-color: #558B2F;
}

.btn.state-new:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-new .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #558B2F !important;
}

.btn.state-new:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-new .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #558B2F !important;
}

.btn.state-new:not(.disabled).inverted.fill,
.state-new .btn.inherit-state:not(.disabled).inverted.fill {
  color: #558B2F !important;
}

.btn.state-process:not(.disabled),
.state-process .btn.inherit-state:not(.disabled) {
  border-color: #FBC02D;
}

.btn.state-process:not(.disabled):not(.fill),
.state-process .btn.inherit-state:not(.disabled):not(.fill) {
  color: #FBC02D;
}

.btn.state-process:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-process .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #FBC02D;
}

.btn.state-process:not(.disabled).fill,
.state-process .btn.inherit-state:not(.disabled).fill {
  background-color: #FBC02D;
}

.btn.state-process:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-process .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #FBC02D !important;
}

.btn.state-process:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-process .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #FBC02D !important;
}

.btn.state-process:not(.disabled).inverted.fill,
.state-process .btn.inherit-state:not(.disabled).inverted.fill {
  color: #FBC02D !important;
}

.btn.state-finished:not(.disabled),
.state-finished .btn.inherit-state:not(.disabled) {
  border-color: #000;
}

.btn.state-finished:not(.disabled):not(.fill),
.state-finished .btn.inherit-state:not(.disabled):not(.fill) {
  color: #000;
}

.btn.state-finished:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-finished .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #000;
}

.btn.state-finished:not(.disabled).fill,
.state-finished .btn.inherit-state:not(.disabled).fill {
  background-color: #000;
}

.btn.state-finished:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-finished .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #000 !important;
}

.btn.state-finished:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-finished .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #000 !important;
}

.btn.state-finished:not(.disabled).inverted.fill,
.state-finished .btn.inherit-state:not(.disabled).inverted.fill {
  color: #000 !important;
}

.btn.state-deleted:not(.disabled),
.state-deleted .btn.inherit-state:not(.disabled) {
  border-color: #AF0000;
}

.btn.state-deleted:not(.disabled):not(.fill),
.state-deleted .btn.inherit-state:not(.disabled):not(.fill) {
  color: #AF0000;
}

.btn.state-deleted:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-deleted .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #AF0000;
}

.btn.state-deleted:not(.disabled).fill,
.state-deleted .btn.inherit-state:not(.disabled).fill {
  background-color: #AF0000;
}

.btn.state-deleted:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-deleted .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #AF0000 !important;
}

.btn.state-deleted:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-deleted .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #AF0000 !important;
}

.btn.state-deleted:not(.disabled).inverted.fill,
.state-deleted .btn.inherit-state:not(.disabled).inverted.fill {
  color: #AF0000 !important;
}

.btn.state-review:not(.disabled),
.state-review .btn.inherit-state:not(.disabled) {
  border-color: #989898;
}

.btn.state-review:not(.disabled):not(.fill),
.state-review .btn.inherit-state:not(.disabled):not(.fill) {
  color: #989898;
}

.btn.state-review:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-review .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #989898;
}

.btn.state-review:not(.disabled).fill,
.state-review .btn.inherit-state:not(.disabled).fill {
  background-color: #989898;
}

.btn.state-review:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-review .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #989898 !important;
}

.btn.state-review:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-review .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #989898 !important;
}

.btn.state-review:not(.disabled).inverted.fill,
.state-review .btn.inherit-state:not(.disabled).inverted.fill {
  color: #989898 !important;
}

.btn.state-expired:not(.disabled),
.state-expired .btn.inherit-state:not(.disabled) {
  border-color: #d0d3db;
}

.btn.state-expired:not(.disabled):not(.fill),
.state-expired .btn.inherit-state:not(.disabled):not(.fill) {
  color: #d0d3db;
}

.btn.state-expired:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-expired .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #d0d3db;
}

.btn.state-expired:not(.disabled).fill,
.state-expired .btn.inherit-state:not(.disabled).fill {
  background-color: #d0d3db;
}

.btn.state-expired:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-expired .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #d0d3db !important;
}

.btn.state-expired:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-expired .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #d0d3db !important;
}

.btn.state-expired:not(.disabled).inverted.fill,
.state-expired .btn.inherit-state:not(.disabled).inverted.fill {
  color: #d0d3db !important;
}

.btn.state-failed:not(.disabled),
.state-failed .btn.inherit-state:not(.disabled) {
  border-color: #AF0000;
}

.btn.state-failed:not(.disabled):not(.fill),
.state-failed .btn.inherit-state:not(.disabled):not(.fill) {
  color: #AF0000;
}

.btn.state-failed:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-failed .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #AF0000;
}

.btn.state-failed:not(.disabled).fill,
.state-failed .btn.inherit-state:not(.disabled).fill {
  background-color: #AF0000;
}

.btn.state-failed:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-failed .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #AF0000 !important;
}

.btn.state-failed:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-failed .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #AF0000 !important;
}

.btn.state-failed:not(.disabled).inverted.fill,
.state-failed .btn.inherit-state:not(.disabled).inverted.fill {
  color: #AF0000 !important;
}

.btn.state-success:not(.disabled),
.state-success .btn.inherit-state:not(.disabled) {
  border-color: #558B2F;
}

.btn.state-success:not(.disabled):not(.fill),
.state-success .btn.inherit-state:not(.disabled):not(.fill) {
  color: #558B2F;
}

.btn.state-success:not(.disabled):not(.fill):not(.disabled):not(.loading):hover,
.state-success .btn.inherit-state:not(.disabled):not(.fill):not(.disabled):not(.loading):hover {
  background-color: #558B2F;
}

.btn.state-success:not(.disabled).fill,
.state-success .btn.inherit-state:not(.disabled).fill {
  background-color: #558B2F;
}

.btn.state-success:not(.disabled).fill:not(.disabled):not(.loading):hover,
.state-success .btn.inherit-state:not(.disabled).fill:not(.disabled):not(.loading):hover {
  background-color: transparent;
  color: #558B2F !important;
}

.btn.state-success:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover,
.state-success .btn.inherit-state:not(.disabled).inverted:not(.fill):not(.disabled):not(.loading):hover {
  color: #558B2F !important;
}

.btn.state-success:not(.disabled).inverted.fill,
.state-success .btn.inherit-state:not(.disabled).inverted.fill {
  color: #558B2F !important;
}

.modal {
  z-index: 9999999;
  max-width: 767px;
  width: 100%;
  padding: 0;
  border-radius: 15px;
  box-shadow: 0 0 10px #000;
}

.jquery-modal.blocker {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 30px 0;
  z-index: 999999;
  overflow-y: scroll;
}

@media only screen and (max-width: 767px) {
  .jquery-modal.blocker {
    padding-bottom: 60px;
  }
}

.modal {
  border-radius: 15px;
}

.modal .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99999;
  color: #FFFFFF;
  text-align: center;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .overlay .overlay-content {
  color: #FFFFFF;
}

.modal .overlay .overlay-text {
  font-size: 24px;
  font-size: 1.5rem;
}

.modal .overlay .overlay-loading {
  margin-top: 30px;
}

.modal > .header, .modal > .body {
  padding: 15px 60px;
}

.modal > .header {
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: #FFFFFF;
}

.modal > .header h3 {
  font-size: 24px;
  font-size: 1.5rem;
  color: #FFFFFF;
  line-height: normal;
  padding-right: 50px;
}

.modal > .header .sub {
  margin: 15px 0 30px 0;
  padding-right: 50px;
  max-width: 90%;
}

.modal > .header .close {
  position: absolute;
  top: 0;
  right: 25px;
  color: #FFFFFF;
  font-size: 40px;
  font-size: 2.5rem;
}

.modal > .header .close::after {
  font-family: 'Material-Design-Iconic-Font', sans-serif;
  content: '\f134';
}

.modal > .body {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media only screen and (max-width: 767px) {
  .modal > .header, .modal > .body {
    padding: 15px 15px;
  }
  .modal > .header .close {
    right: 15px;
  }
  .modal > .header h3 {
    padding-right: 50px;
  }
  .modal > .header .sub {
    max-width: 100%;
  }
}

.form-modal .close-modal {
  display: none;
}

.form-modal > .header {
  background-color: #37474F;
}

.form-modal .google-map {
  display: none;
  height: 250px;
  max-height: 250px;
  max-width: 100%;
  width: 100%;
}

.form-modal > .body form .geo-locate {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 15px;
}

.form-modal > .body form .checkbox-wrapper {
  margin: 15px 0;
}

.form-modal > .body form .checkbox-wrapper label {
  font-weight: normal;
}

.form-modal > .body form .checkbox-wrapper a {
  text-decoration: underline;
}

#error-modal .close-modal {
  display: none;
}

#error-modal > .header {
  background-color: #af0000;
}

#error-modal > .header h3 {
  letter-spacing: 2px;
}

#error-modal > .body {
  padding: 30px 60px;
  font-size: 20px;
  font-size: 1.25rem;
}

.sk-fading-circle {
  margin: 0 auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #FFFFFF;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

#overlay-content {
  color: #FFFFFF;
}

#overlay-text {
  font-size: 24px;
  font-size: 1.5rem;
}

#overlay-loading {
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #form-register .btn,
  #form-login .btn {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 1200px), only screen and (min-width: 768px) and (max-width: 991px) {
  #form-register a.btn,
  #form-login a.btn {
    border: none;
  }
  #form-register a.btn:hover,
  #form-login a.btn:hover {
    background: none;
    color: #FBC02D !important;
  }
}

.statistic {
  list-style: none;
  margin: 0;
  padding: 0;
}

.statistic:before, .statistic:after {
  content: " ";
  display: table;
}

.statistic:after {
  clear: both;
}

.statistic li {
  position: relative;
  float: left;
  border-right: 1px solid #37474F;
  padding: 10px 15px;
}

.statistic li:first-of-type i {
  left: 0;
}

.statistic li:first-of-type .text {
  padding-left: 20px;
}

.statistic li:last-of-type {
  border-right: 0;
}

.statistic li i {
  position: absolute;
  top: 50%;
  left: 15px;
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: -12px;
}

.statistic li .text {
  padding-left: 35px;
}

@media only screen and (max-width: 1199px) {
  .statistic li {
    float: none;
    border-right: none;
    text-align: center;
    margin-bottom: 15px;
  }
  .statistic li i {
    position: static;
    font-size: 36px;
    font-size: 2.25rem;
  }
  .statistic li .text {
    padding-left: 0;
    margin-top: 5px;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.select2 {
  transition: all 50ms linear;
  font-size: 14px;
}

.select2 .select2-selection {
  border: 2px solid #37474F !important;
  background-color: transparent;
  padding: 15px 15px;
  border-radius: 10px;
  height: auto;
}

.select2 .select2-selection.select2-selection--multiple {
  padding-top: 12px;
  padding-bottom: 12px;
}

.select2 .select2-selection:active, .select2 .select2-selection:focus {
  outline: none;
  border-color: #56707c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(97, 125, 139, 0.6);
}

.select2 .select2-selection .select2-selection__rendered {
  color: #37474F;
  line-height: inherit;
  padding: 0 60px 0 0;
}

.select2 .select2-selection .select2-selection__arrow {
  top: 0;
  right: 0;
  height: 100%;
  width: auto;
  padding: 20px 25px;
}

.select2.select2-container--open .select2-selection .select2-selection__arrow b::after {
  transform: rotate(180deg);
}

.select2.select2-container--open, .select2:focus, .select2:active, .select2:hover {
  outline: none;
}

.select2.select2-container--open .select2-selection, .select2:focus .select2-selection, .select2:active .select2-selection, .select2:hover .select2-selection {
  border-color: #56707c !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(97, 125, 139, 0.6);
}

.select2-container--default {
  font-size: 14px;
}

.select2-container--default .select2-dropdown {
  border-radius: 0;
  border: 2px solid #FFFFFF;
  border-top: none;
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.75);
}

.select2-container--default .select2-results li.select2-results__option {
  padding: 10px;
  border-bottom: 1px solid #b2b7c2;
}

.select2-container--default .select2-results li:last-of-type {
  border-bottom: none;
}

.select2-container--default .select2-results .select2-results__option--highlighted[aria-selected] {
  background-color: #37474F;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #37474F;
  color: #FFFFFF;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: #FFFFFF;
  margin-right: 5px;
}

html:not(.wf-materialdesigniconicfont-n4-active) .select2 .select2-selection .select2-selection__arrow b {
  border-color: #FFFFFF transparent transparent transparent;
  border-width: 10px 8px 0 8px;
  margin-left: -8px;
  margin-top: -4px;
}

html:not(.wf-materialdesigniconicfont-n4-active) .select2.select2-container--open .select2-selection .select2-selection__arrow b {
  border-color: transparent transparent #FFFFFF transparent;
  border-width: 0 8px 10px 8px;
}

html.wf-materialdesigniconicfont-n4-active .select2 .select2-selection .select2-selection__arrow b {
  border: none;
  margin: 0;
  position: static;
}

html.wf-materialdesigniconicfont-n4-active .select2 .select2-selection .select2-selection__arrow b::after {
  font-family: Material-Design-Iconic-Font, serif;
  content: "\f2f9";
  color: #37474F;
  transition: all 50ms linear;
  margin-top: 5px;
}

html.wf-materialdesigniconicfont-n4-active .select2 .select2-selection.select2-selection--multiple::after {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 30px;
  font-size: 1.875rem;
  font-family: Material-Design-Iconic-Font, serif;
  content: "\f2f9";
  color: #37474F;
  transition: all 50ms linear;
}

.intro .intro-text {
  font-weight: 600;
}

.content-filters {
  margin: 15px 0 45px 0;
}

.content-filters .radio-wrapper label {
  font-size: 14px;
  font-size: 0.875rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px), only screen and (min-width: 700px) and (max-width: 860px) {
  .content-filters {
    margin-bottom: 15px;
  }
  .content-filters .radio-group.inline .radio-wrapper label {
    font-size: 13px;
    font-size: 0.8125rem;
    padding-left: 30px;
  }
  .content-filters .radio-group.inline .radio-wrapper .check {
    width: 20px;
    height: 20px;
    border-width: 3px;
    top: 7px;
  }
  .content-filters .radio-group.inline .radio-wrapper .check::before {
    height: 10px;
    width: 10px;
    left: 2px;
    top: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px), only screen and (max-width: 700px) {
  .content-filters .radio-group.inline .radio-wrapper {
    float: none;
  }
}

.content-search {
  margin: 15px 0 45px 0;
  position: relative;
}

.content-search:before, .content-search:after {
  content: " ";
  display: table;
}

.content-search:after {
  clear: both;
}

.content-search .form-group {
  margin-bottom: 0;
}

.content-search .form-group.input {
  margin-right: 10px;
}

.content-search .form-group.input input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0  !important;
  border-right: none !important;
  height: 54px;
}

.content-search .form-group.button {
  position: absolute;
  top: 0;
  right: 0;
}

.content-search .form-group.button button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  height: 54px;
  line-height: 54px;
  padding: 0;
  margin: 0;
  font-size: 34px;
  font-size: 2.125rem;
  width: 54px;
  text-align: center;
}

.content-filters + .content-search {
  margin-top: -30px !important;
}

.image-upload-container .images-preview .file-preview-thumbnails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
}

.image-upload-container .images-preview .file-preview-thumbnails:before, .image-upload-container .images-preview .file-preview-thumbnails:after {
  content: " ";
  display: table;
}

.image-upload-container .images-preview .file-preview-thumbnails:after {
  clear: both;
}

.image-upload-container .images-preview .file-preview-frame {
  margin-top: 15px;
}

.image-upload-container .images-preview .file-preview-frame .kv-file-content {
  max-width: 150px;
  max-height: 100px;
  position: relative;
  border: 1px solid #C6CAD2;
  padding: 3px;
}

.image-upload-container .images-preview .kv-file-content img {
  max-width: 142px !important;
  max-height: 92px !important;
}

.image-upload-container .images-preview .file-thumbnail-footer {
  display: none;
}

@media only screen and (max-width: 767px) {
  .image-upload-container .images-preview .file-preview-thumbnails {
    display: block;
  }
  .image-upload-container .images-preview .file-preview-frame {
    text-align: center;
    margin-bottom: 15px;
  }
  .image-upload-container .images-preview .file-preview-frame .kv-file-content {
    display: inline-block;
  }
}

.image-upload-container .kv-fileinput-error {
  margin-top: 15px;
  text-align: center;
  color: #af0000;
}

.image-upload-container .kv-fileinput-error button {
  display: none;
}

.image-upload-container .fake-file-upload {
  position: relative;
}

.image-upload-container .fake-file-upload:before, .image-upload-container .fake-file-upload:after {
  content: " ";
  display: table;
}

.image-upload-container .fake-file-upload:after {
  clear: both;
}

.image-upload-container .fake-file-upload .fake-text {
  background: none;
  height: 54px;
  line-height: 54px;
  padding: 0 15px;
  width: 100%;
  border: 2px solid #37474F;
  border-radius: 10px;
}

.image-upload-container .fake-file-upload .fake-button {
  margin: 0;
  z-index: 1;
  height: 54px;
  line-height: 54px;
  padding: 0 15px 0 55px;
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #37474F;
  background-color: #37474F;
  color: #FFFFFF;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
  transition: all 120ms linear;
}

.image-upload-container .fake-file-upload .fake-button i {
  z-index: 2;
  font-size: 30px;
  font-size: 1.875rem;
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -15px;
}

.image-upload-container .fake-file-upload .fake-button:hover {
  background-color: transparent;
  color: #37474F !important;
  cursor: pointer;
}

.image-upload-container .fake-file-upload input[type=file] {
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  height: 54px;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.image-upload-container .fake-file-upload input[type=file]:hover {
  cursor: pointer;
}

@media only screen and (max-width: 460px) {
  .image-upload-container .fake-file-upload .fake-text {
    display: none;
  }
  .image-upload-container .fake-file-upload .fake-button {
    position: static;
    width: 100%;
    border-radius: 10px;
  }
}

.upload-copyright-info {
  text-align: center;
  max-width: 400px;
  margin: 15px auto;
  font-size: 14px;
  font-size: 0.875rem;
}

.content-title .info,
.content-title .sub {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
}

.content-title .links-states {
  margin: 50px 0;
}

.content-title .links-states:before, .content-title .links-states:after {
  content: " ";
  display: table;
}

.content-title .links-states:after {
  clear: both;
}

.content-title .links-states .links {
  float: left;
}

.content-title .links-states .states {
  margin-top: 15px;
  float: right;
}

.content-title .links-states .states ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content-title .links-states .states ul:before, .content-title .links-states .states ul:after {
  content: " ";
  display: table;
}

.content-title .links-states .states ul:after {
  clear: both;
}

.content-title .links-states .states ul li {
  float: left;
  margin-right: 20px;
  font-weight: bold;
  font-size: 13px;
  font-size: 0.8125rem;
  display: flex;
  align-items: center;
}

.content-title .links-states .states ul li i {
  margin-right: 8px;
  font-size: 20px;
  font-size: 1.25rem;
}

.content-title .links-states .states ul li:last-of-type {
  margin-right: 0;
}

@media only screen and (max-width: 1199px) {
  .content-title .links-states .links, .content-title .links-states .states {
    width: 100%;
  }
  .content-title .links-states .states ul li {
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
  .content-title .links-states .states ul li:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .content-title .links-states .links .btn {
    width: 100%;
  }
}

.image-modal {
  width: auto;
  max-width: 90%;
}

.image-modal .close-modal {
  display: none;
}

.image-modal > .header {
  background-color: #37474F;
  min-height: 50px;
}

.image-modal > .header h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  text-overflow: ellipsis;
}

.image-modal > .body {
  text-align: center;
  padding: 0;
  position: relative;
  min-height: 100px;
  min-width: 250px;
}

.image-modal > .body::before {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  content: 'Bild wird geladen';
  padding: 15px 15px 0 15px;
  font-size: 20px;
  font-size: 1.25rem;
}

.image-modal > .body img {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: auto;
  height: auto;
  max-height: 100vh;
}

@media only screen and (max-width: 767px) {
  .image-modal {
    max-width: 95%;
  }
  .image-modal > .body {
    padding: 0;
  }
}

.comments-alternate .inner,
.comments-alternate .comment {
  margin-bottom: 30px;
}

.comments-alternate .inner .header,
.comments-alternate .comment .header {
  display: flex;
  align-items: center;
}

.comments-alternate .inner .header .image img,
.comments-alternate .comment .header .image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.comments-alternate .inner .header .info,
.comments-alternate .comment .header .info {
  margin-left: 15px;
  font-weight: bold;
}

.comments-alternate .inner .header .info time,
.comments-alternate .comment .header .info time {
  color: #C6CAD2;
  margin-left: 5px;
}

.comments-alternate .inner .message,
.comments-alternate .comment .message {
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .comments-alternate .inner .header .image img,
  .comments-alternate .comment .header .image img {
    width: 40px;
    height: 40px;
  }
  .comments-alternate .inner .header .info time,
  .comments-alternate .comment .header .info time {
    margin-left: 0;
    display: block;
  }
  .comments-alternate .inner .header .info time::before,
  .comments-alternate .comment .header .info time::before {
    content: none;
  }
}

.load-more-reports {
  text-align: center;
  margin-top: 15px;
}

.content-list + .text-center {
  margin-top: 30px;
}

#reports-map {
  display: flex;
  flex-wrap: wrap;
}

#reports-map:before, #reports-map:after {
  content: " ";
  display: table;
}

#reports-map:after {
  clear: both;
}

#reports-map .reports, #reports-map .map {
  display: flex;
  flex-direction: column;
  width: 50%;
  min-height: calc(100vh - 55px);
}

#reports-map .reports {
  padding: 75px;
  order: 1;
}

#reports-map .reports form {
  margin: 15px 0 45px 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px), only screen and (min-width: 700px) and (max-width: 860px) {
  #reports-map .reports form {
    margin-bottom: 15px;
  }
  #reports-map .reports form .radio-group.inline .radio-wrapper label {
    font-size: 13px;
    font-size: 0.8125rem;
    padding-left: 30px;
  }
  #reports-map .reports form .radio-group.inline .radio-wrapper .check {
    width: 20px;
    height: 20px;
    border-width: 3px;
    top: 7px;
  }
  #reports-map .reports form .radio-group.inline .radio-wrapper .check::before {
    height: 10px;
    width: 10px;
    left: 2px;
    top: 2px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px), only screen and (max-width: 700px) {
  #reports-map .reports form .radio-group.inline .radio-wrapper {
    float: none;
  }
}

@media only screen and (max-width: 1800px) {
  #reports-map .reports {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (max-width: 1600px) {
  #reports-map .reports {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#reports-map .map {
  order: 2;
  position: relative;
}

#reports-map .map .google-map {
  height: calc(100vh - 55px);
}

#reports-map .map .google-map.sticky {
  top: 55px;
  right: 0;
  width: 50%;
  position: fixed !important;
}

#reports-map .map .google-map.sticky.bottom {
  position: absolute !important;
  right: 0;
  bottom: 0;
  top: auto;
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  #reports-map .reports, #reports-map .map {
    display: block;
    width: 100%;
    min-height: 0;
  }
  #reports-map .reports {
    order: 2;
    padding: 0 15px;
    margin: 30px 0;
  }
  #reports-map .map {
    order: 1;
  }
  #reports-map .map .google-map {
    min-height: 75vh;
    max-height: 500px;
  }
  #reports-map .map .google-map.sticky, #reports-map .map .google-map.sticky.bottom {
    position: relative !important;
    width: 100% !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
  }
}

.report-detail-modal .social-share {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
  color: #37474F !important;
}

.report-detail-modal .social-share a {
  margin-right: 10px;
}

.report-detail-modal .social-share a:last-of-type {
  margin-right: 0;
}

.report-detail-modal .social-share a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .report-detail-modal .social-share {
    font-size: 28px;
    font-size: 1.75rem;
    right: auto;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .report-detail-modal .social-share a {
    margin-right: 5px;
  }
  .report-detail-modal .social-share a:last-of-type {
    margin-right: 0;
  }
}

.report-detail-modal > .body {
  padding-bottom: 30px;
}

.report-detail-modal > .body > div {
  margin-top: 15px;
}

.report-detail-modal > .body > div:first-of-type {
  margin-top: 0;
}

.report-detail-modal > .body .buttons {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.report-detail-modal > .body .buttons .btn {
  flex-grow: 1;
}

.report-detail-modal > .body .info:before, .report-detail-modal > .body .info:after {
  content: " ";
  display: table;
}

.report-detail-modal > .body .info:after {
  clear: both;
}

.report-detail-modal > .body .info .left {
  float: left;
}

.report-detail-modal > .body .info .right {
  float: right;
}

.report-detail-modal > .body .info .prefix {
  font-weight: bold;
}

.report-detail-modal > .body .info ul {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.report-detail-modal > .body .info ul:before, .report-detail-modal > .body .info ul:after {
  content: " ";
  display: table;
}

.report-detail-modal > .body .info ul:after {
  clear: both;
}

.report-detail-modal > .body .info ul li {
  display: flex;
  align-items: center;
  float: left;
  border-right: 1px solid #c2c5d0;
  padding: 10px 15px 10px 0;
  margin-left: 15px;
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
}

.report-detail-modal > .body .info ul li i {
  font-size: 18px;
  font-size: 1.125rem;
  margin-right: 10px;
}

.report-detail-modal > .body .info ul li:last-of-type {
  border-right: 0;
  padding-right: 0;
}

.report-detail-modal > .body .slick-slider {
  height: 400px;
}

.report-detail-modal > .body .slick-slider .slick-slide a {
  display: block;
}

.report-detail-modal > .body .slick-slider .slick-slide img {
  height: 400px;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}

.report-detail-modal > .body .downloads {
  margin: 15px 0 30px 0;
}

.report-detail-modal > .body .downloads h4 {
  font-size: 20px;
  font-size: 1.25rem;
}

.report-detail-modal > .body .downloads ul {
  margin: 5px 0 0 0;
}

.report-detail-modal > .body .downloads ul li {
  margin-bottom: 5px;
}

.report-detail-modal > .body .downloads ul li:last-of-type {
  margin-bottom: 0;
}

.report-detail-modal > .body .comments {
  padding-left: 50px;
}

.report-detail-modal > .body .comments .message {
  padding-left: 65px;
}

.report-detail-modal > .body .message .inner,
.report-detail-modal > .body .message .comment,
.report-detail-modal > .body .comments .inner,
.report-detail-modal > .body .comments .comment {
  margin-bottom: 30px;
}

.report-detail-modal > .body .message .inner .header,
.report-detail-modal > .body .message .comment .header,
.report-detail-modal > .body .comments .inner .header,
.report-detail-modal > .body .comments .comment .header {
  display: flex;
  align-items: center;
}

.report-detail-modal > .body .message .inner .header .image img,
.report-detail-modal > .body .message .comment .header .image img,
.report-detail-modal > .body .comments .inner .header .image img,
.report-detail-modal > .body .comments .comment .header .image img {
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.report-detail-modal > .body .message .inner .header .info,
.report-detail-modal > .body .message .comment .header .info,
.report-detail-modal > .body .comments .inner .header .info,
.report-detail-modal > .body .comments .comment .header .info {
  margin-left: 15px;
  font-weight: bold;
}

.report-detail-modal > .body .message .inner .header .info time,
.report-detail-modal > .body .message .comment .header .info time,
.report-detail-modal > .body .comments .inner .header .info time,
.report-detail-modal > .body .comments .comment .header .info time {
  color: #C6CAD2;
  margin-left: 5px;
}

.report-detail-modal > .body .message .inner .message,
.report-detail-modal > .body .message .comment .message,
.report-detail-modal > .body .comments .inner .message,
.report-detail-modal > .body .comments .comment .message {
  margin-top: 10px;
}

.report-detail-modal.state-placeholder > .header {
  background-color: #DDDDDD !important;
}

.report-detail-modal.state-new > .header {
  background-color: #558B2F !important;
}

.report-detail-modal.state-process > .header {
  background-color: #FBC02D !important;
}

.report-detail-modal.state-finished > .header {
  background-color: #000 !important;
}

.report-detail-modal.state-deleted > .header {
  background-color: #AF0000 !important;
}

.report-detail-modal.state-review > .header {
  background-color: #989898 !important;
}

.report-detail-modal.state-expired > .header {
  background-color: #d0d3db !important;
}

.report-detail-modal.state-failed > .header {
  background-color: #AF0000 !important;
}

.report-detail-modal.state-success > .header {
  background-color: #558B2F !important;
}

@media only screen and (max-width: 767px) {
  .report-detail-modal .slick-slider {
    height: 250px !important;
  }
  .report-detail-modal .slick-slider .slick-slide img {
    height: 250px !important;
  }
  .report-detail-modal > .body .info .left {
    float: none;
  }
  .report-detail-modal > .body .info .right {
    float: none;
    margin-top: 15px;
  }
  .report-detail-modal > .body .info .right ul li:first-of-type {
    margin-left: 0;
  }
  .report-detail-modal > .body .buttons {
    display: block;
  }
  .report-detail-modal > .body .buttons .btn {
    display: block;
  }
  .report-detail-modal > .body .comments {
    padding-left: 30px;
  }
  .report-detail-modal > .body .comments .message {
    padding-left: 0;
  }
  .report-detail-modal > .body .message .inner .header .image img,
  .report-detail-modal > .body .message .comment .header .image img,
  .report-detail-modal > .body .comments .inner .header .image img,
  .report-detail-modal > .body .comments .comment .header .image img {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
  }
  .report-detail-modal > .body .message .inner .header .info time,
  .report-detail-modal > .body .message .comment .header .info time,
  .report-detail-modal > .body .comments .inner .header .info time,
  .report-detail-modal > .body .comments .comment .header .info time {
    margin-left: 0;
    display: block;
  }
  .report-detail-modal > .body .message .inner .header .info time::before,
  .report-detail-modal > .body .message .comment .header .info time::before,
  .report-detail-modal > .body .comments .inner .header .info time::before,
  .report-detail-modal > .body .comments .comment .header .info time::before {
    content: none;
  }
}

@media only screen and (max-width: 360px) {
  .report-detail-modal > .body .comments {
    padding-left: 0;
  }
}

html.no-flexbox #reports-map,
html.no-flexwrap #reports-map {
  display: table;
  width: 100%;
}

html.no-flexbox #reports-map:before, html.no-flexbox #reports-map:after,
html.no-flexwrap #reports-map:before,
html.no-flexwrap #reports-map:after {
  content: " ";
  display: table;
}

html.no-flexbox #reports-map:after,
html.no-flexwrap #reports-map:after {
  clear: both;
}

html.no-flexbox #reports-map .reports, html.no-flexbox #reports-map .map,
html.no-flexwrap #reports-map .reports,
html.no-flexwrap #reports-map .map {
  display: table-cell;
  vertical-align: top;
}

html.no-flexbox #reports-map .map .google-map.sticky,
html.no-flexwrap #reports-map .map .google-map.sticky {
  left: 0;
}

html.no-flexbox #reports-map .map .google-map.sticky.bottom,
html.no-flexwrap #reports-map .map .google-map.sticky.bottom {
  left: 0;
}

@media only screen and (max-width: 1199px) {
  html.no-flexbox #reports-map .reports, html.no-flexbox #reports-map .map,
  html.no-flexwrap #reports-map .reports,
  html.no-flexwrap #reports-map .map {
    display: block;
  }
  html.no-flexbox #reports-map .map .google-map.sticky, html.no-flexbox #reports-map .map .google-map.sticky.bottom,
  html.no-flexwrap #reports-map .map .google-map.sticky,
  html.no-flexwrap #reports-map .map .google-map.sticky.bottom {
    left: auto !important;
  }
}

#topic h1, #topic h2, #topic h3, #topic h4, #topic h5, #topic h6 {
  margin-bottom: 10px !important;
}

#topic ul {
  margin-bottom: 15px;
  padding-left: 35px;
}

#topic ul li {
  margin-bottom: 5px;
}

#topic ul li ul {
  padding-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#topic p {
  margin-bottom: 15px;
}

#topic a {
  text-decoration: underline;
}

#topic a:hover {
  color: #d79b04;
}

.mind-maker-detail-container .comments-container {
  margin-top: -45px;
}

@media only screen and (min-width: 768px) {
  .mind-maker-result {
    margin-top: -30px;
  }
}

.mind-maker-result .result-title,
.mind-maker-result .result-entry {
  margin-left: -45px;
  margin-right: -45px;
  padding: 30px 45px;
  border-bottom: 2px solid #37474F;
}

.mind-maker-result .result-title:last-of-type,
.mind-maker-result .result-entry:last-of-type {
  border-bottom: none;
  padding-bottom: 0;
}

.mind-maker-result .result-title .result-entry-title,
.mind-maker-result .result-entry .result-entry-title {
  margin-bottom: 30px;
}

.mind-maker-result .result-title .result-option-title,
.mind-maker-result .result-entry .result-option-title {
  font-weight: 600;
  margin-bottom: 15px;
  text-align: center;
}

.mind-maker-result .result-title .chart,
.mind-maker-result .result-entry .chart {
  margin-bottom: 45px;
}

.mind-maker-result .result-title .chart:last-of-type,
.mind-maker-result .result-entry .chart:last-of-type {
  margin-bottom: 0;
}

.mind-maker-result .result-title {
  padding-top: 0;
  font-size: 22px;
  font-size: 1.375rem;
}

.mind-maker-result .chart {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mind-maker-result .chart li {
  margin-bottom: 15px;
}

.mind-maker-result .chart .header {
  margin-bottom: 5px;
  font-weight: bold;
  position: relative;
}

.mind-maker-result .chart .header:before, .mind-maker-result .chart .header:after {
  content: " ";
  display: table;
}

.mind-maker-result .chart .header:after {
  clear: both;
}

.mind-maker-result .chart .header .left {
  float: left;
}

.mind-maker-result .chart .header .right {
  float: right;
}

@media only screen and (max-width: 991px) {
  .mind-maker-result .chart .header .left, .mind-maker-result .chart .header .right {
    float: none;
  }
  .mind-maker-result .chart .header .right {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 7px;
    color: #FFFFFF;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
}

.mind-maker-result .chart .progress-bar {
  background-color: #d0d3db;
  margin-bottom: 15px;
  height: 25px;
}

.mind-maker-result .chart .progress-bar .progress {
  min-width: 20px !important;
  height: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: #37474F;
}

.poll .poll-title {
  border-bottom: 2px solid #FFFFFF;
  margin: 0 -45px;
  padding: 0 45px 45px 45px;
  font-size: 22px;
  font-size: 1.375rem;
}

.poll .poll-entry-list {
  margin-top: 30px;
}

.poll .poll-entry-list .poll-entry {
  margin: 0 -45px 30px -45px;
  padding: 15px 45px;
  border-bottom: 2px solid #FFFFFF;
}

.poll .poll-entry-list .poll-entry .poll-option .checkbox-wrapper {
  margin-bottom: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-single_choice .poll-option {
  margin-bottom: 15px;
}

.poll .poll-entry-list .poll-entry.poll-type-single_choice .poll-option .poll-option-text {
  font-weight: 600;
  margin-bottom: 15px;
}

.poll .poll-entry-list .poll-entry.poll-type-single_choice .poll-option:last-of-type {
  margin-bottom: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-multiple_choice .poll-option {
  margin-bottom: 15px;
}

.poll .poll-entry-list .poll-entry.poll-type-multiple_choice .form-group {
  margin: 30px 0 15px 0 !important;
}

.poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group {
  list-style: none;
  padding: 0;
  margin: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group label {
  padding: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group .radio-wrapper {
  min-height: 40px;
}

@media only screen and (max-width: 991px) {
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs label.sr-only, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs label.screen-reader-text, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group label {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 7px 5px 7px 50px;
    overflow: visible;
    clip: initial;
    border: 0;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs li:first-of-type:before, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs li:first-of-type:after {
    content: " ";
    display: table;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs li:first-of-type:after {
    clear: both;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs li:first-of-type span {
    display: inline-block !important;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option {
    margin-bottom: 30px;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option .radio-group li {
    width: 100%;
    margin-bottom: 15px;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option .radio-group li:first-of-type {
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option .radio-group li:last-of-type {
    display: none;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group li {
    width: 75px;
  }
}

@media only screen and (min-width: 992px) {
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group li {
    display: table-cell;
    vertical-align: middle;
    height: 70px;
    width: 80px;
    text-align: center;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group li:first-of-type {
    width: 250px;
    text-align: left;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group li:last-of-type {
    width: 250px;
    text-align: right;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option {
    border-bottom: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option:last-of-type {
    border-bottom: none;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option ul li:first-of-type {
    padding: 10px 10px 10px 0;
    border-right: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option ul li:last-child {
    padding: 10px 0 10px 10px;
    border-left: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .poll-option:last-of-type ul li:first-of-type {
    border-bottom: none;
  }
  .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group .radio-wrapper {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

.poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group,
.poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers {
  list-style: none;
  padding: 0;
  margin: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group label,
.poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers label {
  padding: 0;
}

.poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group .radio-wrapper {
  min-height: 40px;
}

@media only screen and (max-width: 1199px) {
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group li,
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers li {
    width: 125px;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group li:first-of-type,
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers li:first-of-type {
    width: 225px;
  }
}

@media only screen and (max-width: 991px) {
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers {
    display: none;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale label.sr-only, .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale label.screen-reader-text, .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-entry.poll-type-contrastive_pairs .radio-group label, .poll .poll-entry-list .poll-entry.poll-type-contrastive_pairs .radio-group .poll-entry.poll-type-satisfaction_scale label {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 7px 5px 7px 50px;
    overflow: visible;
    clip: initial;
    border: 0;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option {
    margin-bottom: 30px;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option .radio-group li {
    width: 100%;
    margin-bottom: 15px;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option .radio-group li:first-of-type {
    font-weight: 600;
    padding-bottom: 15px;
    border-bottom: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option:last-of-type {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 992px) {
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group li,
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers li {
    display: table-cell;
    vertical-align: middle;
    height: 70px;
    width: 150px;
    text-align: center;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group li:first-of-type,
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers li:first-of-type {
    width: 300px;
    text-align: left;
    border-right: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option ul li:first-of-type {
    border-bottom: 1px solid #FFFFFF;
    padding: 10px 10px 10px 0;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option:last-of-type ul li:first-of-type {
    border-bottom: none;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .poll-option-headers li {
    border-bottom: 1px solid #FFFFFF;
  }
  .poll .poll-entry-list .poll-entry.poll-type-satisfaction_scale .radio-group .radio-wrapper {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

.poll .poll-entry-list .poll-entry .poll-entry-title {
  margin-bottom: 30px;
}

.idea-scout-detail-container .comments-container, .mind-maker-detail-container .comments-container {
  padding: 45px;
}

.idea-scout-detail-container .comments-container h3, .mind-maker-detail-container .comments-container h3 {
  text-transform: uppercase;
  font-size: 24px;
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.idea-scout-detail-container .comments-container .comment, .mind-maker-detail-container .comments-container .comment {
  margin-left: 15px;
  margin-bottom: 45px;
}

.idea-scout-detail-container .comments-container .comment:last-of-type, .mind-maker-detail-container .comments-container .comment:last-of-type {
  margin-bottom: 0;
}

.idea-scout-detail-container .comments-container .comment .header, .mind-maker-detail-container .comments-container .comment .header {
  margin-bottom: 15px;
  position: relative;
}

.idea-scout-detail-container .comments-container .comment .header .image, .mind-maker-detail-container .comments-container .comment .header .image {
  position: absolute;
  top: 5px;
  left: 0;
}

.idea-scout-detail-container .comments-container .comment .header .info, .mind-maker-detail-container .comments-container .comment .header .info {
  padding-left: 50px;
}

.idea-scout-detail-container .comments-container .comment .message, .mind-maker-detail-container .comments-container .comment .message {
  padding-left: 65px;
}

.idea-scout-detail-container .comments-container .comment .message .title, .mind-maker-detail-container .comments-container .comment .message .title {
  font-weight: bold;
}

@media only screen and (max-width: 767px) {
  .idea-scout-detail-container .comments-container .comment, .mind-maker-detail-container .comments-container .comment {
    margin-bottom: 30px;
  }
  .idea-scout-detail-container .comments-container .comment .header .image, .mind-maker-detail-container .comments-container .comment .header .image {
    position: static;
  }
  .idea-scout-detail-container .comments-container .comment .header .info, .mind-maker-detail-container .comments-container .comment .header .info {
    padding-left: 0;
  }
  .idea-scout-detail-container .comments-container .comment .message, .mind-maker-detail-container .comments-container .comment .message {
    padding-left: 0;
  }
}

.idea-scout-detail-container .comments-container .btn, .mind-maker-detail-container .comments-container .btn {
  margin-top: 45px;
}

@media only screen and (max-width: 767px) {
  .idea-scout-detail-container .comments-container, .mind-maker-detail-container .comments-container {
    padding: 30px 15px;
  }
}

.idea-scout-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .mind-maker-detail .footer {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -15px;
  background-color: #37474F;
  color: #FFFFFF;
}

.idea-scout-detail-container .idea-scout-detail .footer a, .mind-maker-detail-container .idea-scout-detail .footer a, .mind-maker-detail-container .mind-maker-detail .footer a {
  color: #FFFFFF;
}

.idea-scout-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .mind-maker-detail .footer .form-success,
.idea-scout-detail-container .idea-scout-detail .footer form,
.mind-maker-detail-container .idea-scout-detail .footer form,
.mind-maker-detail-container .mind-maker-detail .footer form {
  padding: 45px;
}

@media only screen and (max-width: 767px) {
  .idea-scout-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .mind-maker-detail .footer .form-success,
  .idea-scout-detail-container .idea-scout-detail .footer form,
  .mind-maker-detail-container .idea-scout-detail .footer form,
  .mind-maker-detail-container .mind-maker-detail .footer form {
    padding: 0;
  }
}

.idea-scout-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .idea-scout-detail .footer .form-success, .mind-maker-detail-container .mind-maker-detail .footer .form-success {
  color: #FFFFFF !important;
}

.idea-scout-detail-container .idea-scout-detail .footer h4, .mind-maker-detail-container .idea-scout-detail .footer h4, .mind-maker-detail-container .mind-maker-detail .footer h4 {
  color: #FFFFFF;
  font-size: 24px;
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.idea-scout-detail-container .idea-scout-detail .footer h4:last-child:first-child, .mind-maker-detail-container .idea-scout-detail .footer h4:last-child:first-child, .mind-maker-detail-container .mind-maker-detail .footer h4:last-child:first-child {
  margin-bottom: 0;
  text-align: center;
}

.idea-scout-detail-container .idea-scout-detail .footer label, .mind-maker-detail-container .idea-scout-detail .footer label, .mind-maker-detail-container .mind-maker-detail .footer label {
  font-weight: 600;
}

.idea-scout-detail-container .idea-scout-detail .footer .form-group:last-of-type, .mind-maker-detail-container .idea-scout-detail .footer .form-group:last-of-type, .mind-maker-detail-container .mind-maker-detail .footer .form-group:last-of-type {
  margin-bottom: 0;
}

.idea-scout-detail-container .idea-scout-detail .footer .additional-informations, .mind-maker-detail-container .idea-scout-detail .footer .additional-informations, .mind-maker-detail-container .mind-maker-detail .footer .additional-informations {
  margin: 0 -45px 50px -45px;
  padding: 15px 50px;
  border-bottom: 2px solid #FFFFFF;
}

.idea-scout-detail-container .idea-scout-detail .footer .additional-informations .form-group:last-of-type, .mind-maker-detail-container .idea-scout-detail .footer .additional-informations .form-group:last-of-type, .mind-maker-detail-container .mind-maker-detail .footer .additional-informations .form-group:last-of-type {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .idea-scout-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .mind-maker-detail .footer {
    padding: 30px;
  }
}

@media only screen and (max-width: 767px) and (max-width: 767px) {
  .idea-scout-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .idea-scout-detail .footer, .mind-maker-detail-container .mind-maker-detail .footer {
    padding: 30px 15px;
  }
}
