.btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 5px;
  text-align: center;
  padding: 7px $grid-gutter-width 5px $grid-gutter-width;
  border: 2px solid map-get($colors, 'button');
  margin: 0 5px 0 0;
  cursor: pointer;
  &:not(.no-style) {
    color: map-get($colors, 'button');
  }
  &.uppercase {
    text-transform: uppercase !important;
  }
  &.spacing {
    letter-spacing: 1px !important;
  }
  &.bigger {
    padding: 12px $grid-gutter-width 10px $grid-gutter-width;
  }
  &.wider {
    min-width: 240px;
  }
  &.full-width {
    display: block;
    min-width: 0;
  }
  &:not(.disabled):not(.loading) {
    &:hover {
      transition: all map-get-deep($animations, 'speed', 'normal') linear;
      text-decoration: none;
    }
  }
  &:not(.fill) {
    &:not(.disabled):not(.loading) {
      &:hover {
        background-color: map-get($colors, 'button');
        color: map-get($colors, 'white') !important;
      }
    }
  }
  &.fill {
    background-color: map-get($colors, 'button');
    color: map-get($colors, 'white') !important;
    &:not(.disabled):not(.loading) {
      &:hover {
        background-color: transparent;
        color: map-get($colors, 'button') !important;
      }
    }
  }
  &.inverted {
    border: 2px solid map-get($colors, 'white');
    color: map-get($colors, 'white') !important;
    &:not(.fill) {
      &:not(.disabled):not(.loading) {
        &:hover {
          background-color: map-get($colors, 'white');
          color: map-get($colors, 'button') !important;
        }
      }
    }
    &.fill {
      background-color: map-get($colors, 'white');
      color: map-get($colors, 'button') !important;
      &:not(.disabled):not(.loading) {
        &:hover {
          background-color: transparent;
          color: map-get($colors, 'white') !important;
        }
      }
    }
  }
  &.disabled {
    cursor: not-allowed;
    &.fill {
      background-color: map-get($colors, 'gray');
      border-color: map-get($colors, 'gray');
    }
  }
  .icon-wrapper {
    position: relative;
    display: inline-block;
    i {
      position: absolute;
      top: 50%;
      left: -35px;
      margin-top: -12px;
      @include font-size(20px);
    }
  }
  &.loader {
    position: relative;
    &.loading {
      padding-left: 40px + $grid-gutter-width-half;
      padding-right: 40px + $grid-gutter-width-half;
      &::before {
        position: absolute;
        top: 50%;
        left: $grid-gutter-width-half;
        margin-top: -14px;
        @include font-size(28px);
        content: '\f3ec';
        font-family: Material-Design-Iconic-Font, sans-serif;
        display: inline-block;
        text-rendering: auto;
        animation: zmdi-spin 2.25s infinite linear;
      }
    }
  }

  &.toggle {
    .icon-wrapper {
      .active {
        display: none;
      }
    }
    &.active {
      .icon-wrapper {
        .deactivated {
          display: none;
        }
        .active {
          display: block;
        }
        span.active {
          display: inline-block;
        }
      }
    }
  }

  &.primary {
    &:not(.no-style) {
      color: map-get($colors, 'primary');
    }
    &:not(.fill) {
      &:not(.disabled):not(.loading) {
        &:hover {
          background-color: map-get($colors, 'primary');
        }
      }
    }
    &.fill {
      background-color: map-get($colors, 'primary');
      &:not(.disabled):not(.loading) {
        &:hover {
          color: map-get($colors, 'primary') !important;
        }
      }
    }
    &.inverted {
      &:not(.fill) {
        &:not(.disabled):not(.loading) {
          &:hover {
            color: map-get($colors, 'primary') !important;
          }
        }
      }
      &.fill {
        color: map-get($colors, 'primary') !important;
      }
    }
  }

  @include bp-max-xs {
    min-width: 0 !important;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: $grid-gutter-width-half;
  }
}

@each $key, $value in $states {
  .btn.state-#{$key},
  .state-#{$key} .btn.inherit-state {
    &:not(.disabled) {
      border-color: $value;
      &:not(.fill) {
        color: $value;
        &:not(.disabled):not(.loading) {
          &:hover {
            background-color: $value;
          }
        }
      }
      &.fill {
        background-color: $value;
        &:not(.disabled):not(.loading) {
          &:hover {
            background-color: transparent;
            color: $value !important;
          }
        }
      }
      &.inverted {
        &:not(.fill) {
          &:not(.disabled):not(.loading) {
            &:hover {
              color: $value !important;
            }
          }
        }
        &.fill {
          color: $value !important;
        }
      }
    }
  }
}
