#topic {
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: $grid-gutter-width-half - 5px!important;
  }
  ul {
    margin-bottom: $grid-gutter-width-half;
    padding-left: 20px + $grid-gutter-width-half;
    li {
      margin-bottom: 5px;
      ul {
        padding-left: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  p {
    margin-bottom: $grid-gutter-width-half;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: darken(map-get($colors, 'secondary'), 15);
    }
  }
}
