@include bp-min-sm {
  .box-grid {
    display: flex;
    flex-wrap: wrap;
    > [class^="col-"],
    > [class*=" col-"] {
      display: flex;
      flex-direction: column;
    }
    .box {
      height: 100%;
      display: flex;
      flex-direction: column;
      .footer {
        margin-top: auto;
      }

      .mind-maker-list,
      .mind-maker-detail,
      .idea-scout-list,
      .idea-scout-detail {
        .content, .header {
          margin-bottom: auto;
        }
      }
    }
  }
}

.box, .box-alternate {
  @include clearfix;
  display: block;
  margin-bottom: $grid-gutter-width-half;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
  padding: $grid-gutter-width-half;
  background: map-get($colors, 'white');
  @include bp-max-xs {
    margin-bottom: $grid-gutter-width;
  }
  .header, .content, .footer {
    .image {
      margin-left: -($grid-gutter-width-half);
      margin-right: -($grid-gutter-width-half);
      margin-top: $grid-gutter-width-half;
      img {
        width: 100%;
        max-width: 100%;
        height: 100px;
        object-fit: cover;
      }
      @include bp-max-xs {
        img {
          height: 165px;
        }
      }
    }
    h3 {
      color: map-get($colors, 'black');
      margin: $grid-gutter-width-half 0;
      @include font-size(16px);
      font-weight: bold;
    }
  }
  > .progress-bar {
    background-color: darken(map-get($colors, 'odd-background'), 10);
    margin: -$grid-gutter-width-half;
    margin-bottom: $grid-gutter-width-half;
    height: 15px;
    .progress {
      min-width: 20px;
      height: inherit;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      background-color: map-get($colors, 'primary');
    }
  }
  &:not(.no-hover) {
    &:hover {
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
    }
  }
}

.box {
  border-radius: 10px;
  &.no-radius {
    border-radius: 0;
  }
  .header {
    .top {
      @include clearfix;
      .icon {
        float: left;
        margin-right: 5px;
        img {
          width: 30px;
          height: 30px;
          max-width: 30px;
          object-fit: cover;
          border-radius: 50%;
        }
        i {
          @include font-size(30px);
        }
      }
      .text {
        float: left;
        font-weight: bold;
        @include font-size(12px);
      }
    }
  }
  .content {
    @include font-size(14px);
    .btn {
      margin: $grid-gutter-width 0;
    }
  }

  .footer {
    .top {
      margin-top: $grid-gutter-width-half;
    }
    .bottom {
      margin: -$grid-gutter-width-half;
      margin-top: $grid-gutter-width-half;
      padding: $grid-gutter-width $grid-gutter-width-half;
      text-align: center;
      background-color: lighten(map-get($colors, 'odd-background'), 3);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &.report {
    @include clearfix;
    min-height: 110px;
    border-left: 15px solid transparent;
    display: flex;
    align-items: center;
    flex-direction: row;
    .header {
      min-width: 80px;
      margin-right: $grid-gutter-width;
      img {
        width: 80px;
        height: 80px;
        max-width: 80px;
        object-fit: cover;
      }
    }
    .content {
      @include font-size(14px);
      .category, .prefix {
        font-weight: bold;
      }
      .category {
        margin-bottom: 5px;
      }
    }
    .footer {
      @include clearfix;
      margin-left: auto;
      min-width: 285px;
      ul {
        float: right;
        @include clearfix;
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          float: left;
          border-right: 1px solid darken(map-get($colors, 'odd-background'), 15);
          padding: 10px $grid-gutter-width-half 10px 0;
          margin-left: $grid-gutter-width-half;
          font-weight: 600;
          @include font-size(12px);
          i {
            @include font-size(18px);
            margin-right: 10px;
          }
          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            border-right: 0;
            padding-right: 0;
          }
        }
      }
    }
    .arrow {
      margin-left: 50px;
      float: right;
      @include font-size(45px);
      color: darken(map-get($colors, 'odd-background'), 15);
    }
    &.half-break {
      @media only screen and (min-width: 1281px) and (max-width: 1600px) {
        .footer {
          min-width: 70px;
          ul {
            li{
              display: block;
              float: none;
              border-right: none;
              padding: 0;
              margin-left: 0;
            }
          }
        }
      }
      @media only screen and (min-width: $screen-md-max + 1) and (max-width: 1280px) {
        border-top: 15px solid transparent;
        border-left: none;
        display: block;
        .header {
          margin: -$grid-gutter-width-half;
          margin-bottom: $grid-gutter-width-half;
          img {
            width: 100%;
            max-width: 100%;
            height: 165px;
          }
        }
        .content {
          margin: 0;
          .category,
          .date,
          .location {
            margin-bottom: 5px;
          }
        }
        .footer {
          margin: -$grid-gutter-width-half;
          margin-top: $grid-gutter-width-half;
          padding: $grid-gutter-width-half;
          background-color: lighten(map-get($colors, 'odd-background'), 3);
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          ul {
            float: left;
            li{
              padding-top: 5px;
              padding-bottom: 5px;
              @include font-size(10px);
              i {
                @include font-size(16px);
              }
            }
          }
        }
        .arrow {
          display: none;
        }
      }
    }
    @media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      .footer {
        min-width: 70px;
        ul {
          li {
            float: none;
            border: none;
            margin: 0 0 10px 0;
            padding: 0;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    @include bp-max-xs {
      border-top: 15px solid transparent;
      border-left: none;
      display: block;
      .header {
        margin: -$grid-gutter-width-half;
        margin-bottom: $grid-gutter-width-half;
        img {
          width: 100%;
          max-width: 100%;
          height: 165px;
        }
      }
      .content {
        margin: 0;
        .category,
        .date,
        .location {
          margin-bottom: 5px;
        }
      }
      .footer {
        margin: -$grid-gutter-width-half;
        margin-top: $grid-gutter-width-half;
        padding: $grid-gutter-width-half;
        background-color: lighten(map-get($colors, 'odd-background'), 3);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        ul {
          float: left;
          li{
            padding-top: 5px;
            padding-bottom: 5px;
            @include font-size(10px);
            i {
              @include font-size(16px);
            }
          }
        }
      }
      .arrow {
        display: none;
      }
    }
    @media only screen and (max-width: 320px) {
      .footer {
        min-width: 0;
        ul {
          float: none;
          li{
            width: 100%;
            display: block;
            float: none;
            border: none;
            margin: 0;
          }
        }
      }
    }
  }
  &.mind-maker {
    .header {
      .date {
        margin-top: 7px;
      }
    }
    .progress-bar {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .progress {
        min-width: 20px;
      }
    }
    .footer {
     .bottom {
       @include clearfix;
       text-align: center;
       @include font-size(12px);
       font-weight: bold;
       .type {
         padding: 5px 10px 5px 0;
         border-right: 1px solid darken(map-get($colors, 'odd-background'), 15);
       }
       .participants {
         padding: 5px 0 5px 5px;
       }
     }
    }
    @media only screen and (max-width: 300px) {
      .footer {
        .bottom {
          .type {
            display: block;
            border: none;
          }
          .type, .participants {
            padding: 5px 10px;
          }
        }
      }
    }
  }
  &.idea-scout {
    .header {
      .top {
        .text {
          .date {
            font-weight: normal;
            @include font-size(10px);
            color: darken(map-get($colors, 'gray'), 15);
          }
        }
      }
    }
    .footer {
      .top {
        @include clearfix;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            float: left;
            background: lighten(map-get($colors, 'odd-background'), 3);
            margin-right: 3px;
            margin-bottom: 3px;
            padding: 1px 3px;
            @include font-size(10px);
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
    .progress-bar {
      width: 85%;
      margin: $grid-gutter-width-half auto $grid-gutter-width auto;
      background-color: lighten(map-get($colors, 'black'), 40);
      border-radius: 10px;
      height: 10px;
      .progress {
        min-width: 20px;
        height: inherit;
        border-radius: inherit;
        background-color: map-get($colors, 'primary');
      }
    }
    .remaining-time {
      @include font-size(10px);
    }
    .participants {
      color: map-get($colors, 'primary');
      i {
        @include font-size(35px);
        margin-right: 5px;
      }
      .count {
        @include font-size(35px);
        font-weight: bold;
      }
    }
    .required-participants {
      @include font-size(12px);
      font-weight: bold;
      margin-top: 10px;
    }
  }

  @each $key, $value in $states {
    &.state-#{$key} {
      border-color: $value !important;
      .progress-bar {
        .progress {
          background-color: $value  !important;
        }
      }
      .icon {
        i {
          color: $value !important;
        }
      }
      &.idea-scout {
        .participants {
          i {
            color: $value !important;
          }
        }
      }
    }
  }
}

.box-alternate {
  margin-bottom: $grid-gutter-width * 2;
  .progress-bar {
    height: 8px;
  }
  .header {
    @include clearfix();
    @include font-size(13x);
    margin-bottom: $grid-gutter-width;
    font-weight: 700;
    padding-top: 5px;
    .created {
      font-weight: normal;
      @include font-size(12px);
      color: darken(map-get($colors, 'gray'), 15);
    }
    .top {
      @include clearfix();
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          margin-right: $grid-gutter-width-half;
          position: relative;
          padding-left: 30px;
          i {
            position: absolute;
            top: -4px;
            left: 0;
          }
        }
      }
      .left {
        float: left;
      }
      .right {
        float: right;
        i {
          display: none;
        }
      }
    }
    .bottom {
      margin-top: $grid-gutter-width-half;
      .categories {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            float: left;
            background: lighten(map-get($colors, 'odd-background'), 3);
            margin-right: 3px;
            margin-bottom: 3px;
            padding: 1px 3px;
            font-weight: 600;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }

    i {
      @include font-size(26px);
    }
    .prefix, i, .highlight {
      color: map-get($colors, 'primary');
    }

    @include bp-max-md {
      .top {
        ul {
          li {
            display: block;
            margin-bottom: $grid-gutter-width-half;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    @include bp-max-xs {
      .top {
        .left {
          float: none;
        }
        .right {
          float: none;
          margin-bottom: $grid-gutter-width-half;
          i {
            display: block;
          }
        }
      }
    }
  }
  .content {
    h3 {
      margin-bottom: $grid-gutter-width;
      @include font-size(18px);
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  .footer {
    margin: $grid-gutter-width + $grid-gutter-width-half 0 $grid-gutter-width 0;
  }

  @each $key, $value in $states {
    &.state-#{$key} {
      .progress-bar {
        .progress {
          background-color: $value;
        }
      }
      .header {
        .prefix, i, .highlight {
          color: $value;
        }
      }
    }
  }

  &.mind-maker-list,
  &.mind-maker-detail,
  &.idea-scout-list,
  &.idea-scout-detail {
    .gallery {
      @include clearfix();
      margin-top: $grid-gutter-width;
      a {
        display: block;
        float: left;
        max-width: 165px;
        max-height: 200px;
        margin-right: $grid-gutter-width-half;
        &:last-of-type {
          margin-right: 0;
        }
      }
      img {
        width: auto;
        max-width: 100%;
        max-height: 200px;
      }
      @include bp-max-xs {
        a {
          max-width: none;
          float: none;
          margin-bottom: $grid-gutter-width-half;
          text-align: center;
          margin-right: 0;
        }
      }
    }
    .downloads {
      margin-top: $grid-gutter-width;
      h4 {
        @include font-size(20px);
      }
      ul {
        margin: 5px 0 0 0;
        padding: 0 0 0 20px;
        li {
          margin-bottom: 5px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
    .content {
      position: relative;
      .social-share {
        @include font-size(24px);
        position: absolute;
        bottom: -($grid-gutter-width + $grid-gutter-width-half);
        right: 0;
        color: map-get($colors, 'button') !important;
        a {
          margin-right: 10px;
          &:last-of-type {
            margin-right: 0;
          }
          &:hover {
            text-decoration: none;
          }
        }
        @include bp-max-xs {
          @include font-size(28px);
          right: auto;
          left: 0;
          width: 100%;
          text-align: center;
          a {
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  @include bp-min-sm {
    &.idea-scout-list,
    &.mind-maker-list {
      .content {
        @include clearfix;
        .image {
          margin: 0 $grid-gutter-width 0 0;
          float: left;
          img {
            width: 200px;
            height: 200px;
            max-width: 200px;
            object-fit: cover;
          }
        }
        .text {
          float: left;
          width: 100%;
          margin-left: -230px;
          padding-left: 230px;
          h3 {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.content-list {
  [class^="col-"],
  [class*=" col-"] {
    &:last-of-type {
      .box,
      .box-alternate {
        margin-bottom: $grid-gutter-width-half;
      }
    }
  }
}

html.no-flexwrap {
  .box {
    &.report {
      display: block;
      .header {
        float: left;
      }
      .content {
        float: left;
      }
      .footer {
       float: left;
        margin-top: 18px;
        margin-left: $grid-gutter-width;
      }
    }
  }
}
