/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}

.aligncenter {
  display: block;
  margin: $grid-gutter-width-half auto;
  height: auto;
}

.alignleft,
.alignright {
  margin-bottom: $grid-gutter-width-half;
  height: auto;
}

@include bp-max-sm {
  .alignleft {
    float: left;
    margin-right: $grid-gutter-width-half;
  }

  .alignright {
    float: right;
    margin-left: $grid-gutter-width-half;
  }
}

.wp-caption {
  display: inline-block;
}

.wp-caption img {
  margin-bottom: $grid-gutter-width-half;
  line-height: 1;
  max-width: 100%;
  height: auto;
}

.wp-caption-text {
}

.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
