.select2 {
  transition: all map-get-deep($animations, 'speed', 'fast') linear;
  font-size: $font-size-base;
  &.select2-container {
  }
  .select2-selection {
    border: 2px solid map-get($colors, 'primary') !important;
    background-color: transparent;
    padding: 15px 15px;
    border-radius: 10px;
    height: auto;
    &.select2-selection--multiple {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &:active,
    &:focus {
      outline: none;
      border-color: lighten(map-get($colors, 'primary'), 15);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(lighten(map-get($colors, 'primary'), 20), 0.6);
    }
    .select2-selection__rendered {
      color: map-get($colors, 'primary');
      line-height: inherit;
      padding: 0 60px 0 0;
    }
    .select2-selection__arrow {
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      padding: 20px 25px;
    }
  }
  &.select2-container--open {
    .select2-selection {
      .select2-selection__arrow {
        b {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  &.select2-container--open,
  &:focus,
  &:active,
  &:hover {
    outline: none;
    .select2-selection {
      border-color: lighten(map-get($colors, 'primary'), 15) !important;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(lighten(map-get($colors, 'primary'), 20), 0.6);
    }
  }
}

.select2-container--default {
  font-size: $font-size-base;
  .select2-dropdown {
    border-radius: 0;
    border: 2px solid map-get($colors, 'white');
    border-top: none;
    box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.75);
  }
  .select2-results {
    li.select2-results__option {
      padding: 10px;
      border-bottom: 1px solid darken(map-get($colors, 'gray'), 7);
    }
    li:last-of-type {
      border-bottom: none;
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: map-get($colors, 'primary');
    }
  }
  .select2-selection--multiple {
    .select2-selection__choice {
      background-color: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      border: none;
      border-radius: 5px;
      margin-right: 5px;
      margin-top: 5px;
      padding: 5px 10px;
      .select2-selection__choice__remove {
        color: map-get($colors, 'white');
        margin-right: 5px;
      }
    }
  }
}

html {
  &:not(.wf-materialdesigniconicfont-n4-#{map-get($wf, 'active')}) {
    .select2 {
      .select2-selection {
        .select2-selection__arrow {
          b {
            border-color: map-get($colors, 'white') transparent transparent transparent;
            border-width: 10px 8px 0 8px;
            margin-left: -8px;
            margin-top: -4px;
          }
        }
      }
      &.select2-container--open {
        .select2-selection {
          .select2-selection__arrow {
            b {
              border-color: transparent transparent map-get($colors, 'white') transparent;
              border-width: 0 8px 10px 8px;
            }
          }
        }
      }
    }
  }
  &.wf-materialdesigniconicfont-n4-#{map-get($wf, 'active')} {
    .select2 {
      .select2-selection {
        .select2-selection__arrow {
          b {
            &::after {
              font-family: Material-Design-Iconic-Font, serif;
              content: "\f2f9";
              color: map-get($colors, 'primary');
              transition: all map-get-deep($animations, 'speed', 'fast') linear;
              margin-top: 5px;
            }
            border: none;
            margin: 0;
            position: static;
          }
        }
        &.select2-selection--multiple {
          &::after {
            position: absolute;
            top: 50%;
            right: $grid-gutter-width-half;
            transform: translateY(-50%);
            @include font-size(30px);
            font-family: Material-Design-Iconic-Font, serif;
            content: "\f2f9";
            color: map-get($colors, 'primary');
            transition: all map-get-deep($animations, 'speed', 'fast') linear;
          }
        }
      }
    }
  }
}
