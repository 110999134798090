@mixin font-face($font-path, $font-name, $file-name, $local, $local2, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    font-style: $style;
    font-weight: $weight;
    src: url($font-path + $file-name + '.eot');
    src: local(quote($local)), local(quote($local2)), /* IE9 Compat Modes */
    url($font-path + $file-name + '.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url($font-path + $file-name + '.woff2') format('woff2'), /* Super Modern Browsers */
    url($font-path + $file-name + '.woff') format('woff'), /* Modern Browsers */
    url($font-path + $file-name + '.ttf') format('truetype'), /* Safari, Android, iOS */
    url($font-path + $file-name + '.svg#' + str-replace($font-name, ' ', '')) format('svg'); /* Legacy iOS */
    font-display: swap;
  }
}

@mixin replace-with-space {
  content: ' ';
  &::after {
    content: ' ';
  }
}

@mixin font-size($size) {
  @if unitless($size) {
    $size: $size * 1px;
  }
  font-size: $size;
  font-size: rem-calc($size, $base-font-size);
}

@mixin font-kerning() {
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

@mixin text-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin text-truncate() {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin line-height($height-value: 14) {
  line-height: $height-value * 1px;
  line-height: (1 / ($base-font-size / ($base-font-size * 0 + 1)) * $height-value * 1rem);
}

@mixin non-selectable() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin filter-grayscale($value) {
  -webkit-filter: grayscale($value);
  -moz-filter: grayscale($value);
  -ms-filter: grayscale($value);
  -o-filter: grayscale($value);
  filter: grayscale($value);
}

@mixin filter-blur($amount) {
  @if unitless($amount) {
    $amount: $amount * 1px;
  }
  -webkit-filter: blur($amount);
  -moz-filter: blur($amount);
  -o-filter: blur($amount);
  -ms-filter: blur($amount);
  filter: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='blur'%3E%3CfeGaussianBlur stdDeviation='#{strip-units($amount)}' /%3E%3C/filter%3E%3C/svg%3E%23blur");
  filter: blur($amount);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{strip-units($amount)}');
}

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin triangle($direction, $size, $color, $center: false, $element: after) {
  &:#{$element} {
    @if not $center {
      position: absolute;
    }
    content: '';
    @include square(0);
    -webkit-transform: rotate(360deg);
    border-style: solid;
    @if $direction == up {
      border-width: 0 $size ($size*1.625) $size;
      border-color: transparent transparent $color transparent;
      @if $center {
        @include absolute(null, null, 100%, 50%);
        transform: translateX(-$size);
      }
    } @else if $direction == up-right {
      border-width: 0 $size $size 0;
      border-color: transparent $color transparent transparent;
      @if $center {
        @include absolute(0, 0);
      }
    } @else if $direction == right {
      border-width: $size 0 $size ($size*1.625);
      border-color: transparent transparent transparent $color;
      @if $center {
        @include absolute(50%, null, null, 100%);
        transform: translateY(-$size);
      }
    } @else if $direction == down-right {
      border-width: 0 0 $size $size;
      border-color: transparent transparent $color transparent;
      @if $center {
        @include absolute(null, 0, 0);
      }
    } @else if $direction == down {
      border-width: ($size*1.625) $size 0 $size;
      border-color: $color transparent transparent transparent;
      @if $center {
        @include absolute(100%, null, null, 50%);
        transform: translateX(-$size);
      }
    } @else if $direction == down-left {
      border-width: $size 0 0 $size;
      border-color: transparent transparent transparent $color;
      @if $center {
        @include absolute(null, null, 0, 0);
      }
    } @else if $direction == left {
      border-width: $size ($size*1.625) $size 0;
      border-color: transparent $color transparent transparent;
      @if $center {
        @include absolute(50%, 100%);
        transform: translateY(-$size);
      }
    } @else if $direction == up-left {
      border-width: $size $size 0 0;
      border-color: $color transparent transparent transparent;
      @if $center {
        @include absolute(0, null, null, 0);
      }
    } @else {
      @warn "Available directions: up, up-right, right, down-right, down, down-left, left, up-left.";
    }
    @content;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

@mixin circle($size, $color, $border-width,	$border-color, $display: inline-block) {
  display: $display;
  border-radius: 50%;
  @if $border-width {
    @include square($size);
    background-color: $color;
    border: $border-width solid $border-color;
  } @else {
    @if $color == inherit {
      border-width: $size/2;
      border-style: solid;
      @include square(0);
    } @else {
      @include square($size);
      background-color: $color;
    }
  }
}

@mixin gradient-left-to-right($color1, $color2) {
  background: $color2;
  background: -moz-linear-gradient(top, $color1 0%, $color2 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, $color1), color-stop(100%, $color2));
  background: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
  background: -o-linear-gradient(top, $color1 0%, $color2 100%);
  background: -ms-linear-gradient(top, $color1 0%, $color2 100%);
  background: linear-gradient(to bottom, $color1 0%, $color2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color2}', endColorstr='#{$color1}', GradientType=0);
}

@mixin gradient-top-to-bottom($color1, $color2) {
  background: $color2;
  background: -moz-linear-gradient(top, $color1 0%, $color2 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, $color1), color-stop(100%, $color2));
  background: -webkit-linear-gradient(top, $color1 0%, $color2 100%);
  background: -o-linear-gradient(top, $color1 0%, $color2 100%);
  background: -ms-linear-gradient(top, $color1 0%, $color2 100%);
  background: linear-gradient(to bottom, $color1 0%, $color2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color2}', endColorstr='#{$color1}', GradientType=0);
}

@mixin bp-min-xl {
  @media only screen and (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin bp-max-md {
  @media only screen and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin bp-min-md {
  @media only screen and (min-width: $screen-md-min) {
    @content;
  }
}

@mixin bp-max-sm {
  @media only screen and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin bp-min-sm {
  @media only screen and (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin bp-max-xs {
  @media only screen and (max-width: $screen-xs-max) {
    @content;
  }
}

@mixin bp-min-xs {
  @media only screen and (min-width: $screen-xs-min) {
    @content;
  }
}
