#footer {
  color: map-get($colors, 'gray');
  .footer-top {
    background-color: map-get($colors, 'primary');
  }
  .footer-header {
    @include clearfix;
    display: flex;
    align-items: center;
    .logo {
      float: left;
      svg {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }
    .title {
      float: left;
      margin-left: $grid-gutter-width-half;
      color: map-get($colors, 'white');
      h2, h3 {
        color: map-get($colors, 'white');
        @include font-size(40px);
      }
    }
    @media only screen and (max-width: 580px) {
      text-align: center;
      display: block;
      .logo {
        float: none;
        margin-bottom: $grid-gutter-width-half;
      }
      .title {
        margin: 0;
        float: none;
        h2, h3 {
          @include font-size(26px);
        }
      }
    }
  }
  .footer-bottom {
    @include font-size(14px);
    padding: $grid-gutter-width-half 0;
    background-color: darken(map-get($colors, 'primary'), 10);
    .inner {
      max-width: 1920px;
      position: relative;
    }
    a {
      color: map-get($colors, 'secondary');
      font-weight: 600;
    }
    .content {
      display: flex;
      align-items: center;
      .logo {
        display: inline-block;
        margin-right: $grid-gutter-width-half;
        svg {
          width: 25px;
          height: 25px;
          object-fit: contain;
        }
      }
    }

    .scroll-up {
      display: block;
      background-color: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      bottom: -6px;
      right: $grid-gutter-width-half;
      text-align: center;
      line-height: 32px;
      @include font-size(30px);
      @include size(32px);
      cursor: pointer;
      transform: translateY(0px);
      i {
        margin-left: 1px;
        animation-duration: 1500ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-name: bounce;
      }
    }
    @keyframes bounce {
      0%, 100% {
        transform: translateY(2px);
      }
      50% {
        transform: translateY(-1px);
      }
    }
    @include bp-max-sm {
      .content {
        padding-right: 32px + $grid-gutter-width-half;
      }
      .scroll-up {
        right: 0;
        bottom: 50%;
        margin-bottom: -16px;
      }
    }

  }
  .footer-widgets {
    margin-bottom: -$grid-gutter-width;
    .footer-widget {
      margin-bottom: $grid-gutter-width;
    }
    .widget-title {
      color: map-get($colors, 'secondary');
      border-bottom: 5px solid darken(map-get($colors, 'primary'), 10);
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: $grid-gutter-width-half;
    }
    .widget-content {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          margin-bottom: $grid-gutter-width-half;
          .count {
            color: map-get($colors, 'secondary');
            font-weight: bold;
            margin: 0 3px 0 5px;
          }
          i {
            @include font-size(22px);
          }
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .images {
        margin-top: $grid-gutter-width-half;
        a {
          display: block;
        }
      }
    }
  }
}
