#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .75);
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $grid-gutter-width-half;
}

#overlay-content {
  color: map-get($colors, 'white');
}

#overlay-text {
  @include font-size(24px);
}

#overlay-loading {
  margin-top: $grid-gutter-width;
}
