.container-fluid,
.container {
  &.no-padding {
    padding-left: 0;
    padding-right: 0;
    > .row {
      margin-left: 0;
      margin-right: 0;
    }
  }
  &.full-height {
    height: 100%;
    div.row,
    [class^="col-"],
    [class*=" col-"] {
      height: 100%;
    }
  }
}

[class^="col-"],
[class*=" col-"] {
  &.no-padding {
    padding-left: 0;
    padding-right: 0;
  }
}

.row {
  &.no-margin {
    margin-left: 0;
    margin-right: 0;
  }
}

.no-spacing {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  [class^="col-"],
  [class*=" col-"] {
    padding-left: 0;
    padding-right: 0;
  }
}

.flex {
  display: flex;
}

.vertical-align {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.center-align {
  display: flex;
  flex-direction: row;
  height: 100%;
  > [class^="col-"],
  > [class*=" col-"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.row {
  &.equal-height {
    display: flex;
    flex-wrap: wrap;
    > [class^="col-"],
    > [class*=" col-"] {
      display: flex;
      flex-direction: column;
    }
  }
  &.half-gutter {
    margin-left: -($grid-gutter-width-half / 2);
    margin-right: -($grid-gutter-width-half / 2);
    > [class^="col-"],
    > [class*=" col-"] {
      padding-left: $grid-gutter-width-half / 2;
      padding-right: $grid-gutter-width-half / 2;
    }
  }
}

.full-width {
  width: 100%;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
  > [class^="col-"],
  > [class*=" col-"] {
    display: flex;
    flex-direction: column;
  }
}

.no-break {
  white-space: nowrap;
}
