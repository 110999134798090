#form-register,
#form-login {
  @media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .btn {
      display: block;
      width: 100%;
      margin-bottom: $grid-gutter-width-half;
    }
  }
  @media only screen and (min-width: $screen-lg-min), only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    a.btn {
      border: none;
      &:hover {
        background: none;
        color: map-get($colors, 'secondary') !important;
      }
    }
  }
}
