/*
  Bootstrap Variables
*/
$icon-font-path: '../fonts/';

$input-height-base: 55px;
$grid-gutter-width: 30px;
$grid-gutter-width-half: ($grid-gutter-width / 2);

/*
  Slick Carousel
 */
$slick-font-family: 'Material-Design-Iconic-Font';
$slick-loader-path: '../images/slick/';
