.pac-container {
  z-index: 999999 !important;
}

.form-error {
  color: map-get($colors, 'error');
  font-weight: 600;
  padding: $grid-gutter-width-half 0 $grid-gutter-width 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.form-success {
  color: map-get($colors, 'success');
  font-weight: 600;
  padding: $grid-gutter-width 0;
}

.form-group + .form-group {
  .form-error, .form-success {
    margin-top: -$grid-gutter-width;
  }
}

h1, h2, h3, h4, h5, h6 {
  + form {
    margin-top: $grid-gutter-width-half;
  }
}

form {
  fieldset {
    padding: 0;
    border: none;
  }

  select,
  textarea,
  .google-autocomplete,
  input[type=password],
  input[type=email],
  input[type=search],
  input[type=number],
  input[type=tel],
  input[type=text] {
    &.form-control {
      border-radius: 10px;
      border: 2px solid map-get($colors, 'primary');
      padding-left: 15px;
      padding-right: 15px;
      &:active,
      &:focus {
        outline: none;
        border-color: lighten(map-get($colors, 'primary'), 15);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(lighten(map-get($colors, 'primary'), 20), 0.6);
      }
    }
  }

  .google-autocomplete,
  input[type=password],
  input[type=email],
  input[type=search],
  input[type=number],
  input[type=tel],
  input[type=text] {
    &.form-control {
      // padding-top: 25px;
      // padding-bottom: 25px;
    }
  }

  .form-group {
    margin-bottom: $grid-gutter-width;
    label {
      margin-bottom: 10px;
    }
  }

  .radio-group {
    &.inline {
      @include clearfix;
      .radio-wrapper {
        float: left;
        margin-right: $grid-gutter-width-half;
        margin-bottom: $grid-gutter-width-half;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .radio-wrapper {
    color: map-get($colors, 'gray');
    display: block;
    position: relative;
  }

  .radio-wrapper input[type=radio] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 40px;
    height: 35px;
    z-index: 15;
    cursor: pointer;
  }

  .radio-wrapper label {
    display: block;
    position: relative;
    z-index: 9;
    cursor: pointer;
    font-weight: 600;
    padding: 7px 5px 7px (35px + $grid-gutter-width-half);
  }

  .radio-wrapper:hover label {
    color: map-get($colors, 'black');
  }

  .radio-wrapper .check {
    display: block;
    position: absolute;
    border: 4px solid map-get($colors, 'gray');
    border-radius: 100%;
    height: 35px;
    width: 35px;
    top: 0;
    left: 0;
    z-index: 5;
    transition: border map-get-deep($animations, 'speed', 'normal') linear;
  }

  .radio-wrapper:hover .check {
    border: 5px solid map-get($colors, 'black');
  }

  .radio-wrapper .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: background map-get-deep($animations, 'speed', 'normal') linear;
  }

  .radio-wrapper input[type=radio]:checked ~ .check {
    border: 5px solid map-get($colors, 'primary');
  }

  .radio-wrapper input[type=radio]:checked ~ .check::before {
    background: map-get($colors, 'primary');
  }

  .radio-wrapper input[type=radio]:checked ~ label {
    color: map-get($colors, 'black');
  }

  .checkbox-group {
    .checkbox-wrapper {
      display: block;
    }
  }

  .checkbox-wrapper {
    display: inline-block;
    position: relative;
    margin-bottom: $grid-gutter-width-half;
  }

  .checkbox-wrapper input[type=checkbox] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 25px;
    height: 25px;
    z-index: 15;
    cursor: pointer;
  }

  .checkbox-wrapper label {
    padding-left: 20px + $grid-gutter-width-half;
    font-weight: 600;
    cursor: pointer;
  }

  .checkbox-wrapper .check {
    display: block;
    position: absolute;
    background-color: map-get($colors, 'primary');
    height: 20px;
    width: 20px;
    border-radius: 5px;
    top: 12px;
    left: 0;
    transform: translateY(-50%);
    z-index: 5;
    text-align: center;
  }

  .checkbox-wrapper input[type=checkbox]:checked ~ .check::before {
    display: block;
    position: absolute;
    height: 20px;
    width: 20px;
    top: 0;
    left: 0;
    margin: auto;
    transition: background map-get-deep($animations, 'speed', 'normal') linear;
    content: '\2713';
    font-size: 14px;
    font-weight: bold;
    background-color: transparent;
    color: map-get($colors, 'white');
  }

  .checkbox-wrapper {
    &.inverted {
      .check {
        background-color: map-get($colors, 'white');
      }

      input[type=checkbox]:checked ~ .check::before {
        color: map-get($colors, 'primary');
      }
    }
    &.large {
      input[type=checkbox] {
        width: 40px;
        height: 40px;
        margin-top: -10px;
      }

      label {
        padding-left: 35px + $grid-gutter-width-half;
      }

      .check {
        height: 35px;
        width: 35px;
      }

      input[type=checkbox]:checked ~ .check::before {
        height: 35px;
        width: 35px;
        font-size: 26px;
        top: -1px;
      }
    }
  }

  .radio-group.inverted {
    .radio-wrapper {
      color: map-get($colors, 'white');
      display: block;
      position: relative;
    }

    .radio-wrapper:hover label {
      color: map-get($colors, 'white');
    }

    .radio-wrapper:hover input[type=radio] ~ .check::before {
      background: map-get($colors, 'white');
    }
    .radio-wrapper:hover input[type=radio] ~ .check {
      border: 5px solid map-get($colors, 'white');
    }

    .radio-wrapper .check {
      border: 4px solid map-get($colors, 'white');
    }

    .radio-wrapper input[type=radio]:checked ~ .check {
      border: 5px solid map-get($colors, 'white');
    }

    .radio-wrapper input[type=radio]:checked ~ .check::before {
      background: map-get($colors, 'white');
    }

    .radio-wrapper input[type=radio]:checked ~ label {
      color: map-get($colors, 'white');
    }
  }
}
