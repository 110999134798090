::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

// Stop IE scrollbar overlay
@-ms-viewport {
  width: auto;
}

img:not([src]) {
  visibility: hidden;
}

::selection {
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

svg {
  fill: currentColor;
}

button,
input,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

textarea {
  overflow: auto;
  resize: vertical;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[aria-busy="true"] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[aria-disabled] {
  cursor: default;
}

[draggable] {
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}

.sr-only-important {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0,0,0,0) !important;
  border: 0 !important;
}
