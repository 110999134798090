.statistic {
  @include clearfix();
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    position: relative;
    float: left;
    border-right: 1px solid map-get($colors, 'primary');
    padding: 10px $grid-gutter-width-half;
    &:first-of-type {
      i {
        left: 0;
      }
      .text {
        padding-left: 20px;
      }
    }
    &:last-of-type {
      border-right: 0;
    }
    i {
      position: absolute;
      top: 50%;
      left: $grid-gutter-width-half;
      @include font-size(24px);
      margin-top: -12px;
    }
    .text {
      padding-left: 35px;
    }
  }
  @include bp-max-md {
    li {
      float: none;
      border-right: none;
      text-align: center;
      margin-bottom: $grid-gutter-width-half;
      i {
        position: static;
        @include font-size(36px);
      }
      .text {
        padding-left: 0;
        margin-top: 5px;
        @include font-size(18px);
      }
    }
  }
}
