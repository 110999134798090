.idea-scout-detail-container {
  .comments-container {
    padding: $grid-gutter-width + $grid-gutter-width-half;
    h3 {
      text-transform: uppercase;
      @include font-size(24px);
      margin-bottom: $grid-gutter-width;
    }
    .comment {
      margin-left: $grid-gutter-width-half;
      margin-bottom: $grid-gutter-width + $grid-gutter-width-half;
      &:last-of-type {
        margin-bottom: 0;
      }
      .header {
        margin-bottom: $grid-gutter-width-half;
        position: relative;
        .image {
          position: absolute;
          top: 5px;
          left: 0;
        }
        .info {
          padding-left: 50px;
        }
      }
      .message {
        .title {
          font-weight: bold;
        }
        padding-left: $grid-gutter-width * 2 + 5px;
      }
      @include bp-max-xs {
        margin-bottom: $grid-gutter-width;
        .header {
          .image {
            position: static;
          }
          .info {
            padding-left: 0;
          }
        }
        .message {
          padding-left: 0;
        }
      }
    }
    .btn {
      margin-top: $grid-gutter-width + $grid-gutter-width-half;
    }
    @include bp-max-xs {
      padding: $grid-gutter-width $grid-gutter-width-half;
    }
  }

  .idea-scout-detail {
    .footer {
      margin-left: -$grid-gutter-width-half;
      margin-right: -$grid-gutter-width-half;
      margin-bottom: -$grid-gutter-width-half;
      background-color: map-get($colors, 'primary');
      color: map-get($colors, 'white');
      a {
        color: map-get($colors, 'white');
      }
      .form-success,
      form {
        padding: $grid-gutter-width + $grid-gutter-width-half;
        @include bp-max-xs {
          padding: 0;
        }
      }
      .form-success {
        color: map-get($colors, 'white') !important;
      }
      h4 {
        color: map-get($colors, 'white');
        @include font-size(24px);
        text-transform: uppercase;
        margin-bottom: $grid-gutter-width;
        &:last-child:first-child {
          margin-bottom: 0;
          text-align: center;
        }
      }
      label {
        font-weight: 600;
      }
      .form-group:last-of-type {
        margin-bottom: 0;
      }
      .additional-informations {
        margin: 0 (-($grid-gutter-width + $grid-gutter-width-half)) 50px (-($grid-gutter-width + $grid-gutter-width-half));
        padding: $grid-gutter-width-half 50px;
        border-bottom: 2px solid map-get($colors, 'white');
        .form-group:last-of-type {
          margin-bottom: $grid-gutter-width;
        }
      }
      @include bp-max-xs {
        padding: $grid-gutter-width;
        @include bp-max-xs {
          padding: $grid-gutter-width $grid-gutter-width-half;
        }
      }
    }
  }
}
