#cookie-consent {
  position: fixed;
  z-index: 10;
  bottom: 10px;
  left: 10px;
  width: 400px;
  padding: $grid-gutter-width-half;
  background-color: darken(map-get($colors, 'primary'), 10);
  border: 3px solid darken(map-get($colors, 'primary'), 15);
  color: map-get($colors, 'white');
  > .message {
    margin-bottom: $grid-gutter-width-half;
  }
  > .buttons {
    text-align: center;
    > .more {
      color: map-get($colors, 'white');
      display: block;
      text-decoration: underline;
      margin-bottom: $grid-gutter-width-half;
      &:hover {
        color: map-get($colors, 'secondary') !important;
      }
    }
    > .accept {
      @include button-variant(map-get($colors, 'primary'), map-get($colors, 'white'), transparent);
      display: inline-block;
      vertical-align: middle;
      border-radius: 5px;
      text-align: center;
      padding: 5px $grid-gutter-width;
      min-width: 200px;
      max-width: 300px;
      margin: 0;
      cursor: pointer;
      &:hover {
        background-color: map-get($colors, 'secondary') !important;
        color: map-get($colors, 'primary') !important;
      }
    }
  }
  @include bp-max-xs {
    background-color: rgba(darken(map-get($colors, 'primary'), 10), 0.95);
    border-color: rgba(darken(map-get($colors, 'primary'), 15), 0.90);
    position: absolute;
    border-left-width: 0;
    border-right-width: 0;
    width: 100%;
    left: 0;
    margin-top: 3px;
    top: $header-md-height;
    bottom: auto;
    transform: translateY(-100%);
    > .message {
      text-align: center;
    }
    > .buttons {
      > .accept {
        width: 100%;
        min-width: 0;
      }
    }

    @media only screen and (max-height: $header-md-height - 1px) {
      top: 100vh;
    }

    @media only screen and (max-height: 530px) {
      top: 530px !important;
    }
  }
}
