@import "../components/hamburger";

.hamburger-menu {
  background-color: map-get($colors, 'primary');
}

#main-nav {
  background-color: map-get($colors, 'gray');
  margin-top: 2px;
  .hamburger-menu {
    display: none;
  }
  #main-menu {
    @include clearfix;
    max-width: 1920px;
    margin: 0 auto;
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    li {
      width: 100%;
      border: 0 solid map-get($colors, 'white');
      border-right-width: 2px;
      &:first-of-type {
        border-left-width: 0;
      }
      &:last-of-type {
        border-right-width: 0;
      }
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        transition: all map-get-deep($animations, 'speed', 'normal') linear;
        width: 100%;
        background-color: map-get($colors, 'gray');
        color: map-get($colors, 'primary');
        &::before {
          content: 'DuBist ';
          white-space: pre;
          color: map-get($colors, 'white');
        }
        &.active,
        &:focus,
        &:active,
        &:hover {
          text-decoration: none;
          background-color: map-get($colors, 'primary');
          color: map-get($colors, 'white');
        }
      }
      &.home {
        @include font-size(32px);
        min-width: 82px;
        width: 82px;
        max-width: 82px;
        transition: all map-get-deep($animations, 'speed', 'normal') linear;
        a {
          transition: all map-get-deep($animations, 'speed', 'normal') linear;
          &::before {
            content: none;
          }
          &.active,
          &:focus,
          &:active,
          &:hover {
            text-decoration: none;
            background-color: map-get($colors, 'primary');
            color: map-get($colors, 'white');
          }
        }
      }
    }
  }
  &.sticky {
    box-shadow: -1px 4px 5px 0 rgba(0, 0, 0, 0.45);
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    #main-menu {
      li {
        padding-top: 0;
        a {
          height: $sticky-menu-height;
          @include font-size(21px);
        }
        &.home {
          min-width: $sticky-menu-height + 2px;
          width: $sticky-menu-height + 2px;
          max-width: $sticky-menu-height + 2px;
          a {
            @include font-size(28px);
          }
        }
      }
    }
  }
  @include bp-max-md {
    display: none;
  }
}

#main-nav-mobile {
  display: none;
}

@include bp-max-md {
  #main-nav-mobile {
    visibility: hidden;
    height: 100%;
    min-height: 100%;
    padding: $grid-gutter-width-half;
    background-color: map-get($colors, 'gray');
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: all map-get-deep($animations, 'speed', 'slow') linear;
    width: 550px;
    #main-menu-mobile-wrapper {
      .header {
        margin-bottom: $grid-gutter-width;
        display: flex;
        align-items: center;
        a {
          display: block;
        }
        .logo {
          svg {
            object-fit: contain;
            width: 50px;
            height: 50px;
          }
        }
        .text {
          margin-left: $grid-gutter-width-half;
          @include font-size(28px);
          line-height: normal;
        }
      }
    }
    #main-menu-mobile {
      display: block;
      transition: all map-get-deep($animations, 'speed', 'slow') linear;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        a {
          z-index: 21;
          padding: 10px 10px 10px 0;
          display: block;
          border-bottom: 3px solid map-get($colors, 'primary');
          &::after {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-family: Material-Design-Iconic-Font, serif;
            content: "\f2fb";
            @include font-size(26px);
          }
          &.active,
          &:focus,
          &:active,
          &:hover {
            text-decoration: none;
            color: map-get($colors, 'white');
          }
        }
        &:first-of-type {
          border-top: 3px solid map-get($colors, 'primary');
        }
      }
    }
    #top-menu-mobile {
      margin: $grid-gutter-width 0 0 0;
      padding: 0;
      list-style: none;
      text-align: center;
      font-weight: bold;
      li {
        a {
          display: block;
          padding: 5px;
          text-transform: uppercase;
          @include font-size(14px);
          &.active,
          &:focus,
          &:active,
          &:hover {
            text-decoration: none;
            color: map-get($colors, 'white');
          }
        }
      }
    }
    .hotline {
      background-color: map-get($colors, 'white');
      color: map-get($colors, 'primary');
      text-align: center;
      font-weight: bold;
      border-radius: 5px;
      padding: 10px;
      max-width: 400px;
      margin: $grid-gutter-width-half auto;
    }
  }

  #wrapper {
    position: relative;
    margin-left: 0;
    width: 100%;
    transition: all map-get-deep($animations, 'speed', 'slow') linear;
  }

  body {
    overflow-x: hidden;
  }

  html.mobile-menu-active {
    overflow-y: hidden;
    body {
      overflow-y: hidden;
      position: relative;
      #wrapper {
        margin-left: 550px;
      }
      #main-nav-mobile {
        visibility: visible;
        overflow-y: auto;
      }
    }
  }
}

@include bp-max-xs {
  #main-nav-mobile {
    width: calc(100% - #{$hamburger-width + $grid-gutter-width});
  }

  html.mobile-menu-active {
    body {
      #wrapper {
        margin-left: calc(100% - #{$hamburger-width + $grid-gutter-width});
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  #main-nav-mobile {
    padding: $grid-gutter-width-half;
    width: calc(100% - #{$hamburger-width});
  }

  #main-nav-mobile {
    padding-left: 10px;
    padding-right: 10px;
    #main-menu-mobile {
      li {
        a {
          @include font-size(22px);
          &::after {
            @include font-size(23px);
          }
        }
      }
    }
    #top-menu-mobile {
      li {
        a {
          padding: 5px 0;
          @include font-size(14px);
        }
      }
    }
  }

  html.mobile-menu-active {
    body {
      #wrapper {
        margin-left: calc(100% - #{$hamburger-width});
      }
      #header {
        .hamburger-menu {
          margin-left: -$grid-gutter-width-half;
        }
      }
    }
  }
}

/*
@include bp-max-md {
  #main-nav-mobile {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    transition: all 150ms linear;
    width: calc(100% - #{$hamburger-width + $grid-gutter-width});
    .hamburger-menu {
      position: absolute;
      top: $grid-gutter-width-half;
      left: $grid-gutter-width-half;
    }
    #main-menu-mobile {
      display: none;
      background-color: map-get($colors, 'gray');
    }
  }

  #wrapper {
    margin-left: 0;
    width: 100%;
    transition: all 150ms linear;
  }

  body {
    overflow-x: hidden;
  }

  html.mobile-menu-active {
    body {
      #wrapper {
        margin-left: calc(100% - #{$hamburger-width + $grid-gutter-width});
      }
      #main-nav-mobile {
        .hamburger-menu {
          left: 100%;
          margin-left: $grid-gutter-width-half;
        }
        #main-menu-mobile {
          display: block;
        }
      }
    }
  }
}
*/

html.no-flexbox,
html.no-flexwrap {
  #main-nav {
    background-color: map-get($colors, 'odd-background');
    padding-left: 40px;
    #main-menu {
      display: block;
      text-align: center;
      li {
        width: 31%;
        float: left;
        line-height: 80px;
        text-align: center;
        a {
          display: block;
        }
        &.home {
          min-width: 82px;
          max-width: 82px;
          width: 82px;
          a {
            background-color: map-get($colors, 'primary');
            color: map-get($colors, 'white');
            &::before {
              content: none;
            }
          }
        }
      }
    }
    &.sticky {
      padding-left: 20px;
      #main-menu {
        li {
          line-height: $sticky-menu-height;
          width: 32%;
          &.home {
            min-width: $sticky-menu-height;
            max-width: $sticky-menu-height;
            width: $sticky-menu-height;
          }
        }
      }
    }
  }
}

#top-nav {
  float: left;
  margin-right: $grid-gutter-width * 2;
  margin-top: 5px;
  #top-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    @include clearfix;
    li {
      float: left;
      margin-right: $grid-gutter-width;
      margin-bottom: 5px;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        @include font-size(13px);
        color: map-get($colors, 'white');
      }
    }
  }
}

#user-nav {
  display: none;
  min-width: 100%;
  background-color: map-get($colors, 'white');
  position: absolute;
  margin-top: 15px;
  top: 100%;
  right: 0;
  border-radius: 5px;
  padding: 10px;
  @include triangle(up, 10px, map-get($colors, 'white'), true);
  &::after {
    margin-top: 1px;
    left: 100%;
    margin-left: -(10px * 2);
    border-width: 0 10px 12px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 5px;
      a {
        display: block;
        width: 100%;
        white-space: nowrap;
        text-align: right;
      }
    }
  }
  @include bp-max-md {
    max-width: calc(100vw - #{$grid-gutter-width});
    a {
      @include font-size(18px);
    }
  }
}

#footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  @include clearfix;
  li {
    float: left;
    margin-right: $grid-gutter-width-half;
    a {
      color: map-get($colors, 'gray');
    }
    &:last-of-type {
      margin-right: 0;
    }
    @include bp-max-xs {
      float: none;
      margin-bottom: 15px;
      margin-right: 0;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
