html, body {
  height: 100%;
  margin: 0;
  font-size: $base-font-size;
}

/* Force vertical scrollbar to prevents jumps between pages */
html {
  // overflow-y: scroll;
  margin-bottom: 1px;
}

html {
  &.lazyload-available {
    .lazyload:not(.loaded):not(.google-map),
    .lazyloading:not(.loaded):not(.google-map) {
      opacity: 0;
    }
    .lazyloaded {
      opacity: 1;
      transition: opacity map-get-deep($animations, 'speed', 'normal');
    }
  }
}

.logo {
  svg {
    .logo-background {
      fill: darken(map-get($colors, 'primary'), 10);
    }

    .logo-foreground {
      fill: map-get($colors, 'secondary');
    }
  }
}

#wrapper {
  background-color: map-get($colors, 'white');
}

::selection {
  background: mix(get-color('white'), get-color('primary'), 75);
}

a {
  cursor: pointer;
  &:not(.no-style) {
    color: get-color('primary');
    transition: color map-get-deep($animations, 'speed', 'fast') linear,
    background-color map-get-deep($animations, 'speed', 'fast') linear,
    border-color map-get-deep($animations, 'speed', 'fast') linear;
    i {
      transition: none;
    }
    &::selection {
      background: mix(get-color('white'), get-color('secondary'), 75);
    }
    &:active,
    &:focus,
    &:hover {
      color: get-color('secondary');
      &::selection {
        background: mix(get-color('white'), get-color('primary'), 75);
      }
    }
  }
  &.no-style {
    color: inherit;
    text-decoration: inherit;
  }
}

.block {
  padding-top: 100px;
  padding-bottom: 100px;
  &.medium {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  &.smaller {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @include bp-max-sm {
    padding-top: 75px;
    padding-bottom: 75px;
    &.medium {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    &.smaller {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  @include bp-max-xs {
    padding-top: 50px;
    padding-bottom: 50px;
    &.medium {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &.smaller {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.old-browser,
.no-script {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: get-color('black');
  opacity: 0.75;
  z-index: 9999999;
  color: get-color('white');
  text-align: center;
  font-size: rem-calc(16px, $base-font-size);
  padding: $grid-gutter-width-half;
  b, strong {
    font-weight: 600;
  }
  @media only screen and (max-width: 430px) {
    br {
      display: none;
    }
  }
}

.container-fluid {
  width: 100%;
}
