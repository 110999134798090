@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";

.slick-slider {
  &.slick-dotted {
    margin: 0;
    .slick-dots {
      bottom: $grid-gutter-width-half;
      li, li button, li button::before {
        @include size(20px);
      }
      li {
        @include size(20px);
        button {
          @include size(inherit);
          &::before {
            @include size(inherit);
          }
        }
      }
      @include bp-max-xs {
        li:at-least(8) {
          @include size(15px);
          font-size: 15px;
          line-height: 15px;
        }
      }
      li {
        margin-left: 0;
        margin-right: 5px;
        font-size: 14px;
        line-height: 14px;
        transition: all map-get-deep($animations, 'speed', 'normal') ease-out;
        &:last-of-type {
          margin-right: 0;
        }
        button {
          padding: 0;
          font-size: inherit;
          line-height: inherit;
          &::before {
            line-height: inherit;
            content: '\f26d';
            color: get-color('white');
            opacity: 0.5;
            font-size: inherit;
          }
          &:hover {
            &::before {
              opacity: 1;
            }
          }
        }
        &.slick-active {
          font-size: 20px;
          line-height: 20px;
          button {
            &::before {
              color: get-color('primary');
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .slick-arrow {
    @include size(75px);
    z-index: 1;
    border: 2px solid rgba(get-color('white'), 0.6);
    background: rgba(get-color('white'), 0);
    transition: all map-get-deep($animations, 'speed', 'fast') linear;
    &::before {
      font-size: 40px;
      opacity: 0.6;
      transition: all map-get-deep($animations, 'speed', 'fast') linear;
    }
    &.slick-prev {
      left: $grid-gutter-width-half;
      &::before {
        content: '\f2fa';
        margin-left: -2px;
      }
    }
    &.slick-next {
      right: $grid-gutter-width-half;
      &::before {
        content: '\f2fb';
        margin-left: 2px;
      }
    }
    &:hover {
      background: get-color('white');
      border-color: get-color('white');
      &::before {
        color: get-color('black');
        opacity: 1;
      }
    }
    @include bp-max-xs {
      display: none !important;
    }
  }
  .slick-slide {
    img {
      user-select: none;
    }
    a, img {
      -webkit-user-drag: none;
    }
  }
}

html {
  &:not(.wf-materialdesigniconicfont-n4-#{map-get($wf, 'active')}) {
    .slick-slider {
      &.slick-dotted {
        .slick-dots {
          visibility: hidden;
        }
      }
    }
    .slick-arrow {
      visibility: hidden;
    }
  }
}
