#header {
  font-weight: 600;
  color: map-get($colors, 'white');
  background-color: map-get($colors, 'primary');
  > .inner {
    padding: $grid-gutter-width-half 75px;
    min-height: $header-md-height;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
  }
  &.sticky-offset {
    margin-bottom: $sticky-menu-height;
  }
  .top {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    .left {
      @include font-size(13px);
      a {
        color: map-get($colors, 'white');
        @include font-size(13px);
      }
    }
    .right {
      margin-left: auto;
      display: flex;
      align-items: center;
      .btn {
        text-transform: uppercase;
        margin-bottom: 0;
        margin-right: $grid-gutter-width-half;
        @include font-size(14px);
        &:last-of-type {
          margin-right: 0;
        }
      }
      .user {
        position: relative;
        .name {
          text-transform: none;
          display: block;
          max-width: 300px;
          white-space: nowrap;
          text-overflow: clip;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        &.active,
        &:hover {
          border-radius: 5px;
          .name {
            border-radius: 5px;
            background-color: map-get($colors, 'secondary');
            border-color: map-get($colors, 'secondary');
            color: map-get($colors, 'primary');
          }
        }
        &.active {
          #user-nav {
            display: block;
          }
        }
      }
    }
    @include bp-max-md {
      position: absolute;
      top: $grid-gutter-width-half;
      right: $grid-gutter-width-half;
      z-index: 2;
      .left {
        display: none;
      }
      .right {
        .btn {
          @include font-size(16px);
          padding: 5px 15px;
        }
        .user {
          .name {
            max-width: 400px;
          }
        }
        #top-nav {
          display: none;
        }
      }
    }
    @include bp-max-sm {
      .right {
        .user {
          .name {
            max-width: 275px;
          }
        }
      }
    }
    @include bp-max-xs {
      .right {
        .register {
          display: none !important;
        }
        .user {
          .name {
            max-width: 150px;
          }
        }
      }
    }
  }
  .bottom {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 75px;
    margin-top: -60px;
    display: flex;
    align-items: center;
    .logo {
      float: left;
      margin-right: $grid-gutter-width;
      svg {
        width: 120px;
        height: 120px;
        object-fit: contain;
      }
    }
    .text {
      float: left;
      h1 {
        color: map-get($colors, 'white');
      }
      .sub {
        @include font-size(16px);
      }
    }
    @include bp-max-md {
      display: block;
      position: relative;
      top: auto;
      left: auto;
      margin-top: 0;
      text-align: center;
      .logo {
        float: none;
        margin: 0;
      }
      .text {
        float: none;
      }
    }
  }
  #header-bg {
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -($container-lg / 2);
    background: url('../images/hero_home.png') no-repeat right bottom;
    overflow: hidden;
    &.report {
      background-image: url('../images/hero_report.png');
    }
    &.mind-maker {
      background-image: url('../images/hero_mind_maker.png');
    }
    &.idea-scout {
      background-image: url('../images/hero_idea_scout.png');
    }
    @include bp-max-md {
      margin: 0;
      left: 0;
      width: 100%;
      background-position: center bottom;
      opacity: 0.1;
    }
    /*
    @include bp-max-md {
      margin-left: -($container-md / 2);
    }
    @include bp-max-sm {
      margin-left: -($container-sm / 2);
    }
    @include bp-max-xs {
      margin-left: 0;
      left: auto;
      right: $grid-gutter-width-half;
      width: 100%;
    }
    */
  }
  @include bp-max-md {
    > .inner {
      height: $header-md-height;
      max-height: 100vh;
      padding: $grid-gutter-width-half;
    }
    .bottom {
      .logo {
        margin-bottom: $grid-gutter-width-half;
        svg {
          width: 80px;
          height: 80px;
        }
      }
      .text {
        h1 {
          @include font-size(33px);
        }
        .sub {
          @include font-size(15px);
          max-width: 235px;
          margin: 0 auto;
        }
      }
    }
  }
  @media only screen and (max-width: 350px) {
    .bottom {
      .text {
        h1 {
          @include font-size(28px);
        }
        .sub {
          @include font-size(14px);
        }
      }
    }
  }
  .scroll-down {
    display: none;
  }
  @include bp-max-md {
    .scroll-down {
      display: block;
      background-color: map-get($colors, 'white');
      color: map-get($colors, 'primary');
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      bottom: $grid-gutter-width-half;
      left: 50%;
      text-align: center;
      line-height: 40px;
      margin-left: -20px;
      @include font-size(36px);
      @include size(40px);
      cursor: pointer;
      transform: translateY(0px);
      i {
        margin-left: 1px;
        animation-duration: 1250ms;
        animation-fill-mode: both;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-name: bounce;
      }
    }
    @keyframes bounce {
      0%, 100% {
        transform: translateY(4px);
      }
      50% {
        transform: translateY(-2px);
      }
    }
  }
  .hamburger-menu {
    position: relative;
    z-index: 10;
    display: none;
    margin-bottom: $grid-gutter-width;
    @include bp-max-md {
      display: block;
    }
  }
}

html.no-flexwrap {
  #header {
    .top {
      display: block;
      .left {
        float: left;
        margin-top: 10px;
      }
      .right {
        float: right;
        display: block;
        #top-nav {
          margin-top: 10px;
        }
      }
    }
  }
}

