.modal {
  z-index: 9999999;
  max-width: $screen-xs-max;
  width: 100%;
  padding: 0;
  border-radius: 15px;
  box-shadow: 0 0 10px #000;
}

.jquery-modal {
  &.blocker {
    background-color: rgba(0, 0, 0, .75);
    padding: $grid-gutter-width 0;
    z-index: 999999;
    overflow-y: scroll;
    @include bp-max-xs {
      padding-bottom: $grid-gutter-width * 2;
    }
  }
}

.modal {
  border-radius: 15px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 99999;
    color: map-get($colors, 'white');
    text-align: center;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    .overlay-content {
      color: map-get($colors, 'white');
    }
    .overlay-text {
      @include font-size(24px);
    }
    .overlay-loading {
      margin-top: $grid-gutter-width;
    }
  }

  > .header, > .body {
    padding: $grid-gutter-width-half $grid-gutter-width * 2;
  }
  > .header {
    position: relative;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: map-get($colors, 'white');
    h3 {
      @include font-size(24px);
      color: map-get($colors, 'white');
      line-height: normal;
      padding-right: 35px + $grid-gutter-width-half;
    }
    .sub {
      margin: $grid-gutter-width-half 0 $grid-gutter-width 0;
      padding-right: 35px + $grid-gutter-width-half;
      max-width: 90%;
    }
    .close {
      position: absolute;
      top: 0;
      right: 25px;
      color: map-get($colors, 'white');
      @include font-size(40px);
      &::after {
        font-family: 'Material-Design-Iconic-Font', sans-serif;
        content: '\f134';
      }
    }
  }
  > .body {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  @include bp-max-xs {
    > .header, > .body {
      padding: $grid-gutter-width-half $grid-gutter-width-half;
    }
    > .header {
      .close {
        right: $grid-gutter-width-half;
      }
      h3 {
        padding-right: 35px + $grid-gutter-width-half;
      }
      .sub {
        max-width: 100%;
      }
    }
  }
}

.form-modal {
  .close-modal {
    display: none;
  }
  > .header {
    background-color: map-get($colors, 'primary');
  }
  .google-map {
    display: none;
    height: 250px;
    max-height: 250px;
    max-width: 100%;
    width: 100%;
  }
  > .body {
    form {
      .geo-locate {
        text-decoration: underline;
        font-weight: bold;
        margin-left: $grid-gutter-width-half;
      }
      .checkbox-wrapper {
        margin: $grid-gutter-width-half 0;
        label {
          font-weight: normal;
        }
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

#error-modal {
  .close-modal {
    display: none;
  }
  > .header {
    background-color: map-get($colors, 'error');
    h3 {
      letter-spacing: 2px;
    }
  }
  > .body {
    padding: $grid-gutter-width $grid-gutter-width * 2;
    @include font-size(20px);
  }
}
