@function get-color($name, $house: null) {
  $tmp: map-get($colors, $name);
  @if ($house) {
    $house-map: map-get($colors-house, $house);
    @if (map-has-key($house-map, $name)) {
      $tmp: map-get($house-map, $name);
    }
  }
  @return $tmp;
}

@function stringify-array($array, $prefix: '', $suffix: '', $separator: '') {
  $tmp: '';
  $length: length($array);
  @each $key in $array {
    $tmp: $tmp + $prefix + $key + $suffix;
    @if index($array, $key) < $length {
      $tmp: $tmp + $separator;
    }
  }
  @return $tmp;
}

@function map-get-deep($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

@function str-reverse($string) {
  $reversed-string: '';
  @for $i from 1 through str-length($string) {
    $char: str-slice($string, $i, $i);
    $reversed-string: "#{$char}#{$reversed-string}";
  }
  @return #{$reversed-string};
}

@function random-color($min: 0, $max: 255, $alpha: 1) {
  @if $min < 0 {
    $min: -1;
  } @else {
    $min: $min - 1;
  }
  @if $max > 255 {
    $max: 256;
  } @else {
    $max: $max + 1;
  }
  $red: random($max) + $min;
  $green: random($max) + $min;
  $blue: random($max) + $min;
  @return rgba($red, $green, $blue, $alpha);
}

@function strip-units($value) {
  @return $value / ($value * 0 + 1);
}

@function rem-calc($px, $base-val) {
  @if not unitless($px) {
    $px: strip-units($px);
  }
  @if not unitless($base-val) {
    $base-val: strip-units($base-val);
  }
  @return ($px / $base-val) * 1rem;
}

@function em-calc($px, $base-val) {
  @if not unitless($px) {
    $px: strip-units($px);
  }
  @if not unitless($base-val) {
    $base-val: strip-units($base-val);
  }
  @return ($px / $base-val) * 1em;
}

@function tint($color, $percent: 50) {
  @return mix(white, $color, $percent);
}

@function shade($color, $percent: 50) {
  @return mix(black, $color, $percent);
}

@function svg-url($svg) {
  @if not str-index($svg,xmlns) {
    $svg: str-replace($svg, '<svg', '<svg xmlns="http://www.w3.org/2000/svg"');
  }

  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', '\'');
    $chunk: str-replace($chunk, '%', '%25');
    $chunk: str-replace($chunk, '&', '%26');
    $chunk: str-replace($chunk, '#', '%23');
    $chunk: str-replace($chunk, '{', '%7B');
    $chunk: str-replace($chunk, '}', '%7D');
    $chunk: str-replace($chunk, '<', '%3C');
    $chunk: str-replace($chunk, '>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}
